export const enum EInputStatusEnum {
  Empty,
  Valid,
  Invalid,
}

export const enum e_userStatus {
  Loading,
  LoggedIn,
  LoggedOut,
}

export const enum e_requestStatus {
  onGoing,
  success,
  fail,
}
