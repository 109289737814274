import React from 'react';
import { NftCard } from 'interfaces/gallery_card.interface';
import './NftCardImage.scss';

interface Props {
  card: NftCard;
}

export default function NftCardImage({ card }: Props) {
  return (
    <div className="gallery_card_image">
      <img crossOrigin="" src={card.imgUrl} alt={card.name} />
    </div>
  );
}
