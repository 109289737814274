import './ReskueLoader.scss';
import React from 'react';

interface Props {
  text?: string;
}

export default function ReskueLoader({ text }: Props) {
  return (
    <div className="loader_wrapper">
      <svg className="checkmark"></svg>
      {text && <h3>{text}</h3>}
    </div>
  );
}
