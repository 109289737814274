import { EInputStatusEnum } from 'helpers/status';
import React from 'react';

interface iPassword {
  main: string;
  confirm: string;
  error: string;
  status: EInputStatusEnum;
}

interface iProps {
  event: React.ChangeEvent<HTMLInputElement>;
  password: iPassword;
  setPassword: React.Dispatch<React.SetStateAction<iPassword>>;
}

function passwordOnChange({ event, password, setPassword }: iProps) {
  let main: string = password.main;
  let confirm: string = password.confirm;
  let status: EInputStatusEnum = password.status;
  let error: string = password.error;

  // eslint-disable-next-line
  const passwordRegex = new RegExp(
    /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
  );

  if (event.target.name == 'password') {
    main = event.target.value;
  } else if (event.target.name == 'password_confirm') {
    confirm = event.target.value;
  }

  if (!passwordRegex.test(main) || main.length < 10) {
    error =
      'Password is too weak. between 10 characters, at least 1 uppercase, 1 number, 1 lowercase';
    status = EInputStatusEnum.Invalid;
  } else if (main.length > 30) {
    error = 'Password is too long, max 30 characters';
    status = EInputStatusEnum.Invalid;
  } else {
    error = '';
    status = EInputStatusEnum.Valid;
  }
  if (status === EInputStatusEnum.Valid) {
    if (confirm === password.main) {
      error = '';
      status = EInputStatusEnum.Valid;
    } else {
      error += " passwords don't match";
      status = EInputStatusEnum.Invalid;
    }
  }

  setPassword({ main, confirm, error, status });
}

export default passwordOnChange;
