import default_avatar from 'images/ui/default_avatar.png';
import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { updateSession } from 'data/session.slice';
import Session from 'interfaces/session.interface';
import default_cover from 'images/design/background2.svg';
import { api } from '../app/api/reskueApi';

interface Props {
  session: Session;
  dispatch: Dispatch<AnyAction>;
}

/**
 * This function fetches and sets the session section of the global data
 * @param props.session - the global session object
 * @param props.dispatch - result of useDispatch from redux
 */
export async function setUser({ session, dispatch }: Props) {
  try {
    // if (!session.exists)
    //   return
    const user = await api.get('/user');
    const avatar = user.data.avatar
      ? user.data.avatar?.url_medium
      : default_avatar;
    const cover = user.data.cover ? user.data.cover.url : default_cover;

    dispatch(
      updateSession({
        ...session,
        username: user.data.username,
        avatar: avatar,
        exists: true,
        cover: cover,
        wallet: user.data.wallet.address,
        roles: user.data.roles,
        description: user.data.description,
        instagram: user.data?.instagram,
        facebook: user.data?.facebook,
        twitter: user.data?.twitter,
        website: user.data?.website,
      }),
    );
  } catch (err: any) {
    return false;
  }
}
