import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import './ReskueTextInput.scss';

interface Props {
  text: string;
  setText: Dispatch<SetStateAction<string>>;
  type?: string;
  name?: string;
  id?: string;
  placeholder?: string;
}

/**
 * ReskueTextInput is a simple utils component made for text input.
 * It take a value and a setter obtainable from useState<string>();
 * ReskueTextInput handles the input changes and the display of the input element.
 * It comes with its own css file and properties.
 *
 * @param props.text - value of the input text
 * @param props.setText - setter of props.text
 * @returns an input element of type text
 */
export default function ReskueTextInput({
  text,
  setText,
  type = 'text',
  name = '',
  id = '',
  placeholder = '',
}: Props) {
  function textOnChange(event: ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    setText(event.target.value);
  }

  return (
    <div id={id} className="reskue_text_input_container">
      <input name={name} type={type} value={text} onChange={textOnChange} />
      <label className="placeholder_text" htmlFor={name}>
        <p>{placeholder}</p>
      </label>
      <div className="error_text"></div>
    </div>
  );
}
