import { useMediaQuery } from 'react-responsive';

export function useIsDesktop() {
  return useMediaQuery({ query: '(min-width: 992px)' });
}

export function useIsTablet() {
  return useMediaQuery({ query: '(max-width: 992px) and (min-width: 768px)' });
}

export function useIsMobile() {
  return useMediaQuery({ query: '(max-width: 768px)' });
}

export function useIsTouchScreen() {
  return 'ontouchstart' in window;
}
