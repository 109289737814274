import './footer.scss';
import logo from 'images/logo/RVB_WHITE.png';
import {
  FaTwitter,
  FaInstagram,
  FaDiscord,
  FaLinkedinIn,
} from 'react-icons/fa';
import { Link } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';

function Footer() {
  const { t } = useTranslation('common');

  return (
    <footer>
      <div className="row">
        <div className="col1">
          <div className="social_media_icons">
            <a target="blank" href="/">
              <img src={logo} alt="Reskue logo" width={20} height={20} />
            </a>
            <a target="blank" href="https://twitter.com/ReskueNFT">
              <FaTwitter className="primary_icon" />
            </a>
            <a target="blank" href="https://www.instagram.com/reskue.art/">
              <FaInstagram className="primary_icon" />
            </a>
            <a target="blank" href="https://discord.com/invite/bsQrnAJqDq">
              <FaDiscord className="primary_icon" />
            </a>
            <a target="blank" href="https://www.linkedin.com/company/reskue/">
              <FaLinkedinIn className="primary_icon" />
            </a>
          </div>
          <div className="banner text-gradient">{t('footer.quote')}</div>
          <p className="madeBy">
            Made by{' '}
            <a href="https://www.reskue.tech/" target="_blanck">
              Reskue.tech
            </a>
          </p>
        </div>
        <div className="col_other">
          <div className="title">{t('footer.navigate.title')}</div>
          <div>
            <Link to="/collections">{t('footer.navigate.collections')}</Link>
          </div>

          <div>
            <Link to="/ngos">{t('footer.navigate.ngoPartners')}</Link>
          </div>
          <div>
            <Link to="/leaderboard">{t('footer.navigate.leaderboard')}</Link>
          </div>
          <div>
            <a
              target="blank"
              href="https://docs.google.com/forms/d/e/1FAIpQLScyAQ-RIBpq3S-XPS3wpVyCxZU9J6yV4hdiH2NLuQS3_BH_cg/viewform?usp=sf_link"
            >
              {t('footer.navigate.submitArtist')}
            </a>
          </div>
        </div>
        <div className="col_other">
          <div className="title">{t('footer.company.title')}</div>

          <div>
            <a target="blank" href="https://whitepaper.reskue.art/">
              {t('footer.company.withepaper')}
            </a>
          </div>
          {/* <div>
            <Link to="/about">{t("footer.company.aboutUs")}</Link>
          </div> */}
          <div>
            <a target="blank" href="https://reskue.medium.com/">
              {t('footer.company.news')}
            </a>
          </div>
        </div>
        <div className="col_other">
          <div className="title">{t('footer.support.title')}</div>
          <div>
            <Link to="/FAQ">{t('footer.support.faq')}</Link>
          </div>
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://whitepaper.reskue.art/legal/politique-de-confidentialite-des-donnees"
            >
              {t('footer.support.privacyPolicy')}
            </a>
          </div>
          <div>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://whitepaper.reskue.art/legal/conditions-generales-dutilisation"
            >
              {t('footer.support.terms')}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
