import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import axios from 'axios';
import './user_confirm.scss';
import { Link } from 'react-router-dom';
import useSetCurrentSession from 'helpers/auth/set_current_session';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'data/store';
import Session from 'interfaces/session.interface';
import saveTokens from 'services/tokens.service';
import { CircularProgress } from '@mui/material';

const enum e_emailConfirmationStatus {
  OnGoing,
  Success,
  Fail,
}

export default function UserConfirm() {
  /** Global data */
  const session: Session = useSelector((store: Store) => store.session);

  /** Tools */
  const [searchParams] = useSearchParams();
  const naviguate = useNavigate();
  const dispatch = useDispatch();

  /** Variables */
  const [confirmed, setConfirmed] = useState(e_emailConfirmationStatus.OnGoing);

  const token = searchParams.get('token');

  function redirectTo(url: string, timeout: number) {
    setTimeout(() => {
      naviguate(url);
    }, timeout);
  }

  useEffect(() => {
    if (!token) {
      redirectTo('/', 0);
      return;
    }

    if (process.env.REACT_APP_BACKEND_URL) {
      axios
        .post(process.env.REACT_APP_BACKEND_URL + '/auth/user/confirm/mail', {
          token,
        })
        .then((response: any) => {
          saveTokens(response.data);
          setConfirmed(e_emailConfirmationStatus.Success);
          useSetCurrentSession({ session, dispatch });
          naviguate('/user');
        })
        .catch(error => {
          if (error.message) {
            console.error(error.message);
          }
          if (error.method) {
            console.error(error.method);
          }
          if (error.request) {
            console.error(error.request);
          }
          setConfirmed(e_emailConfirmationStatus.Fail);
        });
    }
  }, []);

  return (
    <>
      <section id="email_confirmation">
        <div id="confirmation_status">
          {confirmed === e_emailConfirmationStatus.OnGoing && (
            <CircularProgress />
          )}

          {confirmed === e_emailConfirmationStatus.Fail && (
            <>
              <p> Confirmation failed</p>
              <Link className="black_link" to="/user/register">
                create an account
              </Link>
            </>
          )}
        </div>
      </section>
    </>
  );
}
