import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useWindowDimensions from 'utils/ScreenDimensions';
import './ngo_button.scss';
import NgoInfoBubble from './ngo_info_bubble';

interface ngo {
  // readonly name: string,
  // readonly tagline: string,
  logo: {
    url: string;
  };
}

export default function NgoButton(props: any) {
  const [isShown, setIsShown] = useState(false);
  const { height } = useWindowDimensions();
  const [whereIs, setWhereIs] = useState<string>('');
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  function handleClick(e: any) {
    const y = e.nativeEvent.clientY;
    if (y < height / 2) setWhereIs('above');
    else setWhereIs('below');
    setIsShown(true);
    e.stopPropagation();
  }

  function handleNav(e: any) {
    navigate('/ngos/' + props?.ngo?.ngo_name);
    e.stopPropagation();
  }

  if (props?.ngo != null) {
    return (
      <div
        className="button_wrapper"
        onMouseEnter={e => handleClick(e)}
        onMouseLeave={() => setIsShown(false)}
        onClick={handleNav}
      >
        {isShown && <NgoInfoBubble ngo={props?.ngo} whereIs={whereIs} />}
        <img src={props?.ngo?.logo?.url_small} />
        {props?.name}
      </div>
    );
  } else {
    return <div className="descr">{t('collection.generic')}</div>;
  }
}
