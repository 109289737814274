interface iProps {
  address: string;
}

const mailRegex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$');

function checkMail({ address }: iProps): boolean {
  return mailRegex.test(address) || address === '';
}

export default checkMail;
