import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LazyLoad from 'react-lazyload';
import './collection_card.scss';
import ArtworkCard from '../cards/artwork_card/artwork_card';
import { ImgLoad } from 'utils/ImgLoader/img_loader';
import axios from 'axios';
import NgoButton from '../buttons/ngo_button';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

interface ICollec {
  alt?: string;
  selected?: boolean;
  collection_name: string;
  size: number;
  released: boolean;
  description: string;
  image_url: string;
  height: number;
  not?: string;
  ngo: any;
  artist: any;
  rate: any;
}

export default function CollectionCard({
  alt,
  size,
  description,
  collection_name,
  image_url,
  ngo,
  not,
  artist,
  rate,
}: ICollec) {
  /** Tools */
  const isSmallScreen = useMediaQuery({ maxWidth: 1450 });
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const [data, setData] = useState([]);

  useEffect(() => {
    if (process.env.REACT_APP_BACKEND_URL) {
      axios
        .get(
          process.env.REACT_APP_BACKEND_URL + '/gallery/artwork/many/random',
          {
            params: {
              limit: 3,
              relations: ['collection.ngo', 'listing', 'owner'],
              collection: collection_name,
              not: not,
            },
          },
        )
        .then((res: any) => {
          setData(Array.from(res.data));
        })
        .catch(err => {
          console.error(err);
        });
    }
  }, [not]);

  function handleClick(e: any) {
    navigate('../collections/' + collection_name);
    e.stopPropagation();
  }

  /** Short description */
  let tiny_descr = collection_name;
  if (tiny_descr && tiny_descr.length > 39)
    tiny_descr = tiny_descr.substring(0, 28) + '...';

  return (
    <LazyLoad height={200}>
      <div onClick={handleClick} className={'collection_card'} id="collec">
        <div className="column2_collec">
          <div className="column1">
            <ImgLoad
              src={image_url}
              width={150}
              height={150}
              alt={alt}
              className="collection-img"
            />
            <h2>{tiny_descr}</h2>
            <div className="descr">
              {size} {t('collection.artworks')}
            </div>
            <h3>{t('collection.description')}</h3>
            <div id="elems">
              <div className="descr">{description}</div>
              <h3>{t('collection.supporting')}</h3>
              <NgoButton ngo={ngo} />
              {/* <div className="notif">
              <div className="el"><FaRegHeart /></div> <div className="el"><strong>100%</strong> of the primary sales  will go to nonprofit partners.</div>
            </div> */}
            </div>
          </div>
          {data.slice(0, isSmallScreen ? 2 : data.length).map(data => (
            <div className="column" key={data.id}>
              <ArtworkCard
                key={data.id}
                height={600}
                image={data.image?.url_medium}
                data_name={data.artwork_name}
                name={data.artwork_name}
                released={data.released}
                size={data.size}
                listing={data?.listing}
                cashbacks={data?.cashbacks}
                type={data.sold}
                collection={data.collection}
                collection_name={data.collection.collection_name}
                id={data?.id}
                ngo={data?.collection?.ngo}
                artist={artist}
                owner={data.owner}
                rate={rate}
              />
            </div>
          ))}
        </div>
      </div>
    </LazyLoad>
  );
}
