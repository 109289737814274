import { Link } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';

export interface ListPageOptions {
  onItemClick?: React.MouseEventHandler<HTMLLIElement>;
}

export function ListPages(props: ListPageOptions) {
  const { t } = useTranslation('common');

  return (
    <>
      <div className="but">
        <li onClick={props.onItemClick}>
          {/* <Link to="/about">{t("header.about_us")}</Link> */}
          <Link to="/collections">Collections</Link>
        </li>
      </div>

      <div className="but">
        <li onClick={props.onItemClick}>
          <Link to="/ngos">{t('header.ngos')}</Link>
        </li>
      </div>
      <div className="but">
        <li onClick={props.onItemClick}>
          <Link to="/leaderboard">{t('header.leaderboard')}</Link>
        </li>
      </div>
      {/* <div className="but">
      <li onClick={props.onItemClick}>
        <Link to="/faq">{t("header.faq")}</Link>
      </li>
      </div> */}
    </>
  );
}

export default ListPages;
