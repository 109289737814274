import './ReskueFailed.scss';
import React from 'react';

interface Props {
  text: string;
  crossLength: number;
}

export default function ReskueFailed({ text, crossLength }: Props) {
  const path = `M 26,26 l ${crossLength},${crossLength} M 26,26 l -${crossLength},${crossLength} M 26,26 l ${crossLength},-${crossLength} M 26,26 l -${crossLength},-${crossLength}`;

  return (
    <div className="reskue_failed_wrapper">
      <svg
        className="checkmark"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 52 52"
      >
        <circle
          className="checkmark__circle"
          cx="26"
          cy="26"
          r="25"
          fill="none"
        />
        {/* <path className="checkmark__check" fill="none" d="M 10,10 l 90,90 M 100,10 l -90,90" /> */}
        <path className="checkmark__check" fill="none" d={path} />
      </svg>
      <h3>{text}</h3>
    </div>
  );
}
