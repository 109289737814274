import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react';
import ContactForm from './contact_form';
import './contact_section.scss';
import { MailFail, MailSuccess, MailLoading } from './form_status';

enum _mailStatus {
  OnGoing,
  Loading,
  Fail,
  Success,
}

function ContactSection(props: any) {
  const [mailStatus, setMailStatus] = useState(_mailStatus.OnGoing);

  switch (mailStatus) {
    case _mailStatus.Success:
      return <MailSuccess />;
      break;
    case _mailStatus.Fail:
      return <MailFail />;
      break;
    case _mailStatus.Loading:
      return <MailLoading />;
      break;
    default:
      return (
        <section id="contact_section">
          <h3>{props?.title || 'Contact Us'}</h3>
          <ContactForm setMailStatus={setMailStatus} />
        </section>
      );
  }
}

export default ContactSection;
