import React, { useState } from 'react';
import './artist_button.scss';
import useWindowDimensions from 'utils/ScreenDimensions';
import default_profile from 'images/ui/default_avatar.png';
import { useNavigate } from 'react-router-dom';
import UserInfoBubble from './user_info_bubble';
import { ImgLoad } from 'utils/ImgLoader/img_loader';
import { useTranslation } from 'react-i18next';

export default function ArtistButton(props: any) {
  const [isShown, setIsShown] = useState(false);
  const { height } = useWindowDimensions();
  const [whereIs, setWhereIs] = useState<string>('');
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  function handleClick(e: any) {
    const y = e.nativeEvent.clientY;
    if (y < height / 2) setWhereIs('above');
    else setWhereIs('below');
    setIsShown(true);
    e.stopPropagation();
  }

  function handleNav(e: any) {
    navigate('/user/' + props.artist?.username);
    e.stopPropagation();
  }

  if (props.type === 'card') {
    return (
      <div
        className="but_container"
        onMouseEnter={e => handleClick(e)}
        onMouseLeave={() => setIsShown(false)}
        onClick={handleNav}
      >
        {isShown && <UserInfoBubble user={props.artist} whereIs={whereIs} />}
        <div className="avatar">
          <ImgLoad
            width={40}
            height={40}
            src={props.artist?.avatar?.url_small || default_profile}
            circle={true}
            className={'avatar_img'}
          />
        </div>
        <div className="wrapper">
          <div className="title">{t('artworks.creator')}</div>
          <div className="artist_name">
            @
            {props.artist?.username.length > 7
              ? props.artist?.username.substring(0, 7) + '...'
              : props.artist?.username}{' '}
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="but_container">
        <div className="avatar">
          <img src={props.artist?.avatar?.url} />
        </div>
        <div>@{props.artist?.username} </div>
      </div>
    );
  }
}
