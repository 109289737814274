import React from 'react';
import { Highlight } from 'react-instantsearch-hooks-web';
import { useNavigate } from 'react-router-dom';
import ArtistButton from '../../../components/buttons/artist_button';
import PayOwnerButton from '../../../components/buttons/pay_owner_button';
import { ImgLoad } from 'utils/ImgLoader/img_loader';
import './collection_items.scss';
import { useHits } from 'react-instantsearch-hooks-web';
import ArtworkCard from '../artwork_card/artwork_card';
import LazyLoad from 'react-lazyload';

export function CustomHits(props) {
  const { hits, results, sendEvent } = useHits(props);

  return (
    <LazyLoad height={200}>
      <div className="artwork_list">
        {hits.map((hit: any) => (
          <ArtworkCard
            key={hit.id}
            height={600}
            image={hit.image}
            data_name={hit.name}
            name={hit.name}
            released={hit.released}
            price={hit.price}
            type={hit.sold}
            collection={props.collection}
            collection_name={props.collection?.collection_name}
            id={hit.objectID}
            ngo={props.collection?.ngo}
            artist={props.collection.artist}
            owner={hit?.owner}
            ownerAvatar={hit?.ownerAvatar}
            listing={hit.listing}
            cashbacks={hit?.cashbacks}
            rate={props.rate}
          />
        ))}
      </div>
    </LazyLoad>
  );
}

const Hit = ({ hit }: any) => {
  // const CustomHits = connectHits(({hit, data}) => {
  const navigate = useNavigate();
  function handleClick(event: any) {
    navigate('../collections/' + hit.collection + '/' + hit.name);
    event.stopPropagation();
  }

  return (
    <div onClick={handleClick}>
      <article>
        <div className="card_container">
          <ImgLoad
            src={hit.image}
            width={350}
            height={300}
            alt={hit.name}
            className="theimg"
          />
          {/* <p>{hit.categories[0]}</p> */}
          <div className="Title">
            <Highlight attribute="name" hit={hit} />
          </div>
          <div className="bottom_section">
            <div className="row" id="row1">
              <div className="t1">{hit.name}</div>
              <div className="t2">Edition of 1/1</div>
            </div>
            <div className="row">
              <div className="column1">
                {/* TODO: change tom */}
                <ArtistButton type="card" name="Tom" />
              </div>
              <div className="column2">
                <PayOwnerButton
                  price={'sold' === hit.sold ? '' : hit.price[0].value}
                  image={hit.image}
                  name={hit.name}
                  type={'Sold' === hit.sold ? 'owner' : ''}
                  user_name={''}
                  id={hit.objectID}
                  collection_name={hit.collection}
                  // ngo={ngo}
                />
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

export { Hit };
// export {CustomHits}
