import { menuUnactive } from 'data/menu.slice';
import { Store } from 'data/store';
import unsetCurrentSession from 'helpers/auth/unset_current_session';
import Session from 'interfaces/session.interface';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './wallet_menu.scss';
import matic from 'images/logo/matic.png';
import InputFunds from '../buttons/input_funds';
import MetamaskPay from './metamask_pay';
import CopyToClipboard from 'utils/copy_clipboard';
import { api } from '../../app/api/reskueApi';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';
import {
  Backdrop,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  TextField,
} from '@mui/material';
import { toast } from 'react-toastify';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import EuroIcon from '@mui/icons-material/Euro';
import metamask_logo from 'images/ui/metamaskLogo.png';
import logo from 'images/logo/Reskue_Logo.png';
import useWindowDimensions from 'utils/ScreenDimensions';
import polygon_wallet from 'images/ui/poly_wallet.png';
import WertModule from '@wert-io/module-react-component';

export default function WalletMenu() {
  /** Global Data */
  const { menu } = useSelector((state: Store) => state.menu);
  const language = useSelector((state: Store) => state.language.selected);
  const session: Session = useSelector((state: Store) => state.session);
  const { value } = useSelector((state: Store) => state.menu);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const [open, setOpen] = React.useState(false);
  const [hash, setHash] = React.useState<any>('');
  const [code, setCode] = React.useState<any>('');

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
  };

  const { height } = useWindowDimensions();

  /** Hooks */
  const [balance, setBalance] = useState<string>(null);
  const [option, setOption] = useState<number>(0);
  const [equiv, setEquiv] = useState<any>('');
  const [password, setPassword] = useState<string>(null);
  const [wallet, setWallet] = useState<string>(null);

  const [rate, setRate] = useState<number>();

  const handleLeave = () => {
    setOption(0);
    dispatch(menuUnactive(null));
  };

  const walletRegex = new RegExp('^0x[a-fA-F0-9]{40}$');

  async function handleFiat() {
    const val = Math.floor(1000 + Math.random() * 9000);
    try {
      const rep = await api.post(
        process.env.REACT_APP_BACKEND_URL + '/user/wallet/sign',
        {
          toSign: 'MtPelerin-' + val.toString(),
        },
      );
      const encodedValue = encodeURIComponent(rep.data);
      setHash(encodedValue);
      setCode(val);
    } catch (err) {
      console.error(err.data);
    }
    setOption(1);
  }

  useEffect(() => {
    if (session.exists) getBalance();
  }, [menu]);

  // Toast
  const toastId = React.useRef(null);
  const notify = () =>
    (toastId.current = toast('Start retrieving funds', {
      autoClose: false,
      position: toast.POSITION.BOTTOM_LEFT,
    }));
  const updateSucc = () =>
    toast.update(toastId.current, {
      render: t('collection.create.success'),
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

  const updateFail = (err: any) =>
    toast.update(toastId.current, {
      render: err,
      type: toast.TYPE.ERROR,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

  async function handleRetrieve() {
    // check wallet
    if (password === null) {
      toast.error(`Password missing!`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return false;
    }
    if (walletRegex.test(wallet) === false) {
      toast.error(`This is not a valid wallet address.`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return false;
    }
    if (parseFloat(matic) < parseFloat(value)) {
      toast.error(
        `You cannot withdraw more than what you have in your wallet.`,
        {
          position: toast.POSITION.BOTTOM_LEFT,
        },
      );
      return false;
    }
    setOpen(true);
    notify();
    try {
      const resp = await api.post(
        process.env.REACT_APP_BACKEND_URL + '/user/wallet/withdrawal/',
        {
          amount: value,
          address: wallet,
        },
        {
          headers: {
            'X-PASSWORD': password,
          },
        },
      );
      updateSucc();
      window.location.reload();
    } catch (err: any) {
      console.error(err);
      updateFail(err?.message);
    }
    setOpen(false);
  }

  async function getBalance() {
    try {
      const balance = await api.get('/user/wallet/balance');
      setBalance(balance.data.toFixed(2));
      const resp = await api.get('/money/exchangeRate', {
        params: {
          fiat: 'EUR',
        },
      });
      setRate(resp.data);
      setEquiv((balance.data * resp.data).toFixed(2));
    } catch (err: any) {
      console.error(err.response?.data?.message);
      if (err.status === 401) {
        unsetCurrentSession({ session, dispatch });
      }
    }
  }

  if (menu === null) return <></>;
  else {
    return (
      <div className="wallet_menu_wrapper">
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <div className="wallet_menu" id="wallet_target">
          {option != 0 && (
            <div className="backButt" onClick={() => setOption(0)}>
              {' '}
              ←{' '}
            </div>
          )}
          <h1>{t('wallet.yourWallet')}</h1>
          <CopyToClipboard>
            {({ copy }) => (
              <div className="wallet_info" onClick={() => copy(session.wallet)}>
                <img src={polygon_wallet} width="20" height="20" />

                {session.wallet.substring(0, 6) +
                  '...' +
                  session.wallet.substring(38)}
              </div>
            )}
          </CopyToClipboard>
          <span className="modal_close" onClick={handleLeave}>
            &#10005;
          </span>

          {option === 0 && (
            <>
              <div className="wallet_line"></div>
              <div className="balance_display">
                <div className="col">
                  <h2>
                    {balance} Matic <img src={matic} />
                  </h2>
                  <div className="matic_equiv"> ≈ {equiv}€</div>
                </div>
              </div>
            </>
          )}
          <div className="wallet_content">
            {option === 0 && (
              <Box>
                <Tabs value={tabIndex} onChange={handleTabChange}>
                  <Tab label={t('wallet.addFunds')} />
                  <Tab label={t('wallet.retrieveFunds')} />
                </Tabs>
              </Box>
            )}
            {tabIndex === 0 && (
              <>
                {option === 0 && (
                  <Box
                    sx={{
                      width: '100%',
                      padding: '1rem',
                      marginTop: '1rem',
                      marginBottom: '1rem',
                    }}
                  >
                    <List
                      sx={{
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                        borderRadius: '10px',
                        paddingTop: '0',
                        paddingBottom: '0',
                      }}
                    >
                      <ListItem disablePadding onClick={handleFiat}>
                        {/* <ListItem disablePadding onClick={() => (window.showMtpModal({ lang: "fr", crys: "MATIC", tab: "buy", tabs: "buy", myLogo: process.env.REACT_APP_FRONTEND_URL + logo, type: "webview", primary: "%23000000", bsc: "EUR", bdc: "MATIC", net: "matic_mainnet", addr: session?.wallet }))}> */}
                        <ListItemButton sx={{ display: 'flex' }}>
                          <ListItemIcon
                            sx={{
                              width: '20%',
                              display: 'flex',
                              justifyContent: 'left',
                            }}
                          >
                            <CreditCardIcon />
                            <EuroIcon />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              language === 'fr'
                                ? 'Avec une carte de crédit ou un virement'
                                : 'With a credit card or bank transfer'
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                      <Divider />
                      <ListItem
                        sx={{ width: '100%', padding: '0' }}
                        onClick={() => setOption(2)}
                      >
                        <ListItemButton sx={{ display: 'flex' }}>
                          <ListItemIcon
                            sx={{
                              width: '20%',
                              display: 'flex',
                              justifyContent: 'left',
                            }}
                          >
                            <img
                              src={metamask_logo}
                              alt="the Metamask logo"
                              width={30}
                              height={30}
                            />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              language === 'fr'
                                ? 'En déposant des Matics via Metamask'
                                : 'By depositing Matics via Metamask'
                            }
                          />
                        </ListItemButton>
                      </ListItem>
                    </List>
                  </Box>
                )}
                {/* <button className="btn btn-gradient-border" >Mt Pelerin</button> */}
                {/* <iframe frameBorder="0" width="480" height="500" src={`https://widget.mtpelerin.com/?lang=fr&tab=buy&tabs=buy&type=web&primary=%23000000&bsc=EUR&bdc=MATIC&net=matic_mainnet&addr=${session?.wallet}`}></iframe> */}
                {option === 1 && (
                  <Box
                    sx={{
                      width: '100%',
                      height: '580px',
                    }}
                  >
                    <WertModule
                      options={{
                        partner_id: process.env.REACT_APP_WERT_PARTNER_ID_PROD,
                        container_id: 'wert-widget',
                        theme: 'white',
                        commodity: 'MATIC',
                        commodities: 'MATIC',
                        network: 'polygon',
                        lang: language,
                        height: 580,
                        address: session.wallet,
                      }}
                    />
                  </Box>
                )}
                {option === 2 && (
                  <div className="option_wrapper">
                    <InputFunds value={menu?.value} rate={rate} />
                    <MetamaskPay />
                  </div>
                )}
              </>
            )}

            {tabIndex === 1 && (
              <>
                <div className="option_wrapper">
                  <InputFunds value={menu?.value} rate={rate} />
                  <TextField
                    margin="normal"
                    fullWidth
                    id="outlined-password-input"
                    label="Wallet"
                    InputLabelProps={{ shrink: true }}
                    autoComplete="current-password"
                    onChange={e => setWallet(e.target.value)}
                  />
                  <TextField
                    margin="normal"
                    id="outlined-password-input"
                    label="Password*"
                    type="password"
                    InputLabelProps={{ shrink: true }}
                    autoComplete="current-password"
                    onChange={e => setPassword(e.target.value)}
                  />

                  <div className="wallet_ic selected" onClick={handleRetrieve}>
                    {t('wallet.retrieveButton')}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
