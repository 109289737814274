import { createSlice } from '@reduxjs/toolkit';
import Session from 'interfaces/session.interface';

export interface SessionSlice {
  session: Session;
}

const initialState: Session = {
  email: '',
  username: '',
  avatar: '',
  exists: false,
  language: 'FR',
  wallet: '',
  roles: [],
  description: '',
  hasIdentity: false,
};

interface SessionAction {
  payload: Session;
  type: string;
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateSession(state, action: SessionAction) {
      void state;
      return action.payload;
    },
  },
});

export const { updateSession } = userSlice.actions;

export default userSlice.reducer;
