import { updateSession } from 'data/session.slice';
import { Store } from 'data/store';
import unsetCurrentSession from 'helpers/auth/unset_current_session';
import Session from 'interfaces/session.interface';
import React, { ChangeEvent, useState } from 'react';
import { FaBan, FaCameraRetro } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { api } from 'api/reskueApi';
import './cover_update.scss';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/system';

export default function CoverUpdate(props: any) {
  /** Global Data */
  const session: Session = useSelector((state: Store) => state.session);

  /** Tools */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const [log, setLog] = useState('');
  const [status, setStatus] = useState<number>(0);

  const [cover, setCover] = useState<any>({
    url: session.cover,
    file: '',
  });
  const toastId = React.useRef(null);
  const notify = () =>
    (toastId.current = toast(t('user.edit.cover.download'), {
      autoClose: false,
      position: toast.POSITION.BOTTOM_LEFT,
    }));
  const update = () =>
    toast.update(toastId.current, {
      render: t('user.edit.cover.success'),
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });
  const updateFail = err =>
    toast.update(toastId.current, {
      render: err?.response?.data?.message ?? err ?? null,
      type: toast.TYPE.ERROR,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });
  const updateSucc = () =>
    toast.update(toastId.current, {
      render: t('user.edit.cover.success'),
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

  function onFileChange(event: ChangeEvent<HTMLInputElement>) {
    if (event.target.files === null || event.target.files.length <= 0) return;
    const imageType = /^image\//;
    if (!imageType.test(event.target.files[0].type)) {
      setLog(t('user.edit.cover.errors.badImage'));
      return;
    }
    setLog('');
    props.setCover({
      url: window.URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
    });
    setStatus(1);
    setCover({
      url: window.URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
    });
  }

  function cancelCover() {
    props.setCover({
      url: session?.cover,
      file: null,
    });
    setStatus(0);
  }

  async function updateCover() {
    if (cover.file) {
      notify();
      const data = new FormData();
      data.append('file', cover.file);
      try {
        const ret = await api.post('/user/cover', data);
        updateSucc();
        setStatus(0);
        dispatch(
          updateSession({
            ...session,
            cover: ret.data.cover.url,
          }),
        );
        return false;
      } catch (err: any) {
        // setLog(err.response?.data?.message)
        updateFail(err);
        if (err.status === 401) {
          unsetCurrentSession({ session, dispatch });
        }
      }
    }
    return false;
  }
  if (status === 0)
    return (
      <label className="cover_upload">
        <div id="cover_but">
          <div className="the_but">
            <FaCameraRetro /> {t('user.edit.cover.update')}
          </div>
          <input
            type="file"
            name="cover"
            id="cover_input"
            onChange={onFileChange}
          />
        </div>
      </label>
    );
  if (status === 1)
    return (
      <span id="cover_but">
        <Box sx={{ display: 'flex', gap: '1rem', alignItem: 'center' }}>
          <div className="the_but" onClick={updateCover}>
            <FaCameraRetro /> {t('user.edit.cover.confirm')}
          </div>
          <div className="the_but" onClick={cancelCover}>
            <FaBan />
            {t('user.edit.cover.cancel')}
          </div>
        </Box>
      </span>
    );
}
