import ContactSection from '../about_section/contact_section/contact_section';
import React, { useEffect } from 'react';
import { useState, useRef } from 'react';
import './faq.scss';
import Accordion from './accordion';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

function FAQ() {
  const { t } = useTranslation('common');

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  const accordion_data = [
    {
      question: t('FAQ.Q1'),
      answer: t('FAQ.A1'),
    },
    {
      question: t('FAQ.Q2'),
      answer: t('FAQ.A2'),
    },
    {
      question: t('FAQ.Q3'),
      answer: t('FAQ.A3'),
    },
    {
      question: t('FAQ.Q4'),
      answer: t('FAQ.A4'),
    },
    {
      question: t('FAQ.Q5'),
      answer: t('FAQ.A5'),
    },
    {
      question: t('FAQ.Q6'),
      answer: t('FAQ.A6'),
    },
    {
      question: t('FAQ.Q7'),
      answer: t('FAQ.A7'),
    },
    {
      question: t('FAQ.Q8'),
      answer: t('FAQ.A8'),
    },
    {
      question: t('FAQ.Q9'),
      answer: t('FAQ.A9'),
    },
    {
      question: t('FAQ.Q10'),
      answer: t('FAQ.A10'),
    },
    {
      question: t('FAQ.Q11'),
      answer: t('FAQ.A11'),
    },
    {
      question: t('FAQ.Q12'),
      answer: t('FAQ.A12'),
    },
    {
      question: t('FAQ.Q13'),
      answer: t('FAQ.A13'),
    },
    {
      question: t('FAQ.Q14'),
      answer: t('FAQ.A14'),
    },
    {
      question: t('FAQ.Q15'),
      answer: t('FAQ.A15'),
    },
  ];

  let key = 0;

  return (
    <div>
      <Helmet>
        <title>Frequently Asked Questions</title>
        <meta
          name="title"
          content="Reskue - Frequently Asked Questions"
          data-react-helmet="true"
        />
        <meta
          name="description"
          content="What is an NFT? How do you select Non-profit organization partners? How can blockchain shape the future of philanthropy? We answer to all of your questions!"
          data-react-helmet="true"
        />
      </Helmet>

      <h1> {t('FAQ.title')} </h1>
      <div className="accordion">
        {accordion_data.map(({ question, answer }) => (
          <Accordion title={question} content={answer} key={key++} />
        ))}
      </div>
      <ContactSection title="Any other questions? Contact us" />
    </div>
  );
}

export default FAQ;
