import React, { useEffect, useState } from 'react';
import DataObjectIcon from '@mui/icons-material/DataObject';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { useNavigate, useParams } from 'react-router-dom';
import SailingIcon from '@mui/icons-material/Sailing';
import ArtistButton from '../buttons/artist_button';
import './artwork.scss';
import RightSection from './right_section';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { FaExpand } from 'react-icons/fa';
import CollectionCard from '../collection_page/collection_card';
import { Box } from '@mui/system';
import { Avatar, Chip } from '@mui/material';
import RevenueSplit from '../collection_creation/revenueSplit';
import { api } from 'api/reskueApi';
import EntityButton from '../buttons/entity_button';
import { useTranslation } from 'react-i18next';
import default_profile from 'images/ui/default_avatar.png';

import { ShareButton } from '../index';

export default function Artwork() {
  const [rate, setRate] = useState<number>();
  const { t } = useTranslation('common');

  const handle = useFullScreenHandle();

  /* eslint-disable */
  let calculateAngle = function (e, item, parent) {
    let dropShadowColor = `rgba(0, 0, 0, 0.3)`;
    if (parent.getAttribute('data-filter-color') !== null) {
      dropShadowColor = parent.getAttribute('data-filter-color');
    }

    parent.classList.add('animated');
    // Get the x position of the users mouse, relative to the button itself
    let x = Math.abs(item.getBoundingClientRect().x - e.clientX);
    // Get the y position relative to the button
    let y = Math.abs(item.getBoundingClientRect().y - e.clientY);

    // Calculate half the width and height
    let halfWidth = item.getBoundingClientRect().width / 2;
    let halfHeight = item.getBoundingClientRect().height / 2;

    // Use this to create an angle. I have divided by 6 and 4 respectively so the effect looks good.
    // Changing these numbers will change the depth of the effect.
    let calcAngleX = (x - halfWidth) / 6;
    let calcAngleY = (y - halfHeight) / 14;

    let gX = (1 - x / (halfWidth * 2)) * 100;
    let gY = (1 - y / (halfHeight * 2)) * 100;

    // Add the glare at the reflection of where the user's mouse is hovering
    item.querySelector(
      '.glare',
    ).style.background = `radial-gradient(circle at ${gX}% ${gY}%, rgb(199 198 243), transparent)`;
    // And set its container's perspective.
    parent.style.perspective = `${halfWidth * 6}px`;
    item.style.perspective = `${halfWidth * 6}px`;

    // Set the items transform CSS property
    item.style.transform = `rotateY(${calcAngleX}deg) rotateX(${-calcAngleY}deg) scale(1.04)`;
    parent.querySelector(
      '.inner-card-backface',
    ).style.transform = `rotateY(${calcAngleX}deg) rotateX(${-calcAngleY}deg) scale(1.04) translateZ(-4px)`;

    if (parent.getAttribute('data-custom-perspective') !== null) {
      parent.style.perspective = `${parent.getAttribute(
        'data-custom-perspective',
      )}`;
    }

    // Reapply this to the shadow, with different dividers
    let calcShadowX = (x - halfWidth) / 3;
    let calcShadowY = (y - halfHeight) / 6;

    // Add a filter shadow - this is more performant to animate than a regular box shadow.
    item.style.filter = `drop-shadow(${-calcShadowX}px ${-calcShadowY}px 15px ${dropShadowColor})`;
  };

  document.querySelectorAll('.card').forEach(function (item) {
    // For flipping the card backwards and forwards
    if (item.querySelector('.flip') !== null) {
      item.querySelector('.flip').addEventListener('click', function () {
        item.classList.add('flipped');
      });
    }

    // For 'unflipping' the card.
    if (item.querySelector('.unflip') !== null) {
      item.querySelector('.unflip').addEventListener('click', function () {
        item.classList.remove('flipped');
      });
    }

    // For when the user's mouse 'enters' the card
    item.addEventListener('mouseenter', function (e) {
      calculateAngle(e, this.querySelector('.inner-card'), this);
    });

    // For when the users mouse moves on top of the card
    item.addEventListener('mousemove', function (e) {
      calculateAngle(e, this.querySelector('.inner-card'), this);
    });

    // For when the user's mouse leaves the card.
    item.addEventListener('mouseleave', function (e) {
      let dropShadowColor = `rgba(0, 0, 0, 0.3)`;
      if (item.getAttribute('data-filter-color') !== null) {
        dropShadowColor = item.getAttribute('data-filter-color');
      }
      item.classList.remove('animated');
      const t = item.querySelector('.inner-card') as HTMLElement | null;
      t.style.transform = `rotateY(0deg) rotateX(0deg) scale(1)`;
      const t2 = item.querySelector(
        '.inner-card-backface',
      ) as HTMLElement | null;
      t2.style.transform = `rotateY(0deg) rotateX(0deg) scale(1.01) translateZ(-4px)`;
      const t3 = item.querySelector('.inner-card') as HTMLElement | null;
      t3.style.filter = `drop-shadow(0 10px 15px ${dropShadowColor})`;
    });
  });

  function handleClick(name: string) {
    navigate('../collections/' + name);
  }

  const params = useParams();
  const navigate = useNavigate();

  const [postState, setPostState] = useState({
    data: {
      transactionHash: '',
      artwork_name: '',
      description: '',
      created_at: '',
      sold: '',
      minted: '',
      ipfsUrl: '',
      image: {
        url: '',
        url_medium: '',
        url_small: '',
      },
      owner: {
        username: '',
        avatar: {
          url: '',
          url_medium: '',
          url_small: '',
        },
      },
      cashbacks: {
        artist: {
          percentage: '',
        },
        reskue: {
          percentage: '',
        },
        ngo: {
          percentage: '',
        },
      },
      tokenId: '',
      collection: {
        id: '',
        image: { url: '', url_medium: '', url_small: '' },
        collection_name: '',
        released: true,
        size: 0,
        ngo: {
          id: '',
          ngo_name: '',
          logo: {
            url: '',
            url_medium: '',
            url_small: '',
          },
          issueTaxReceipt: '',
        },
        address: '',
        description: '',
        artist: {
          username: '',
          avatar: {
            url: '',
          },
        },
      },
      listing: {
        ngo: {
          ngo_name: '',
          logo: {
            url: '',
            url_medium: '',
            url_small: '',
          },
        },
        reserve: '',
        listingType: '',
      },
    },
    loading: false,
    error: false,
  });
  const [ratio, setRatio] = useState<string>('');
  const [mystyle, setMyStyle] = useState('');
  // const good_name = params.artwork_name.replace(/\s/g, '_')
  const URL = `/gallery/artwork/find/${params.collection_name}/${params.artwork_name}`;

  async function getRate() {
    try {
      const resp = await api.get('/money/exchangeRate', {
        params: {
          fiat: 'EUR',
        },
      });
      setRate(resp.data);
    } catch (err: any) {
      console.error(err.response?.data?.message);
    }
  }

  async function getData() {
    await axios
      .get(process.env.REACT_APP_BACKEND_URL + URL, {
        params: {
          relations: [
            'collection',
            'collection.artist',
            'collection.ngo',
            'listing',
            'owner',
            'listing.ngo.logo',
          ],
        },
      })
      .then(res => {
        if (res.data) {
          setPostState(s => ({ ...s, data: res.data }));
          const img = new Image();
          img.src = res.data.image.url;
          const ratioNb =
            45 *
            (parseInt(img.width.toString()) / parseInt(img.height.toString()));
          setRatio(ratio.toString());
          setMyStyle(ratioNb.toString());
        } else {
          setPostState(s => ({ ...s, loading: true }));
        }
      })
      .catch(error => {
        setPostState(s => ({ ...s, error: true }));
      });
  }

  useEffect(() => {
    getData();
    getRate();
  }, [params.artwork_name]);

  const { data, loading, error } = postState;

  const URLPolygonTransactionLink =
    process.env.REACT_APP_POLYGONSCAN_URL + data.transactionHash;

  if (error === true) {
    console.error(error);
    navigate('/404');
  }
  // navigate("/404")
  if (loading === true)
    return (
      // ! DOESN'T SEEM TO WORK
      <div>{t('collection.loading')}</div>
    );

  return (
    <div className="artwork_container">
      <Helmet>
        <title>{params.artwork_name} | Reskue </title>
        <meta name="title" content={`${params.artwork_name} | Reskue`} />
        <meta name="name" content={params.artwork_name} />
        <meta
          name="description"
          content={
            params.artwork_name +
            ' from ' +
            params.collection_name +
            ' collection | Reskue'
          }
        />
        <meta property="og:title" content={params.artwork_name} />
        {/* <meta property="og:url" content="https://reskue.art/collections" /> */}
        <meta
          property="og:description"
          content={
            params.artwork_name +
            ' from ' +
            params.collection_name +
            ' collection | Reskue'
          }
        />
        <meta property="og:image" content={data?.image?.url_medium} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={`${params.artwork_name} | Reskue`}
        />
        <meta name="twitter:site" content="@reskueNFT" />
        {/* <meta name="twitter:url" content="https://reskue.art/collections" /> */}
        <meta name="twitter:image" content={data.image?.url_medium} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="artwork_header">
        <div className="contain" style={{ width: `${mystyle}rem` }}>
          <div className="card blastoise" style={{ width: `${mystyle}rem` }}>
            <span
              className="inner-card-backface"
              style={{ width: `${mystyle}rem` }}
            >
              <span className="image">
                <img src={data.image?.url_medium} />
                {/* <span className="unflip">Unflip</span> */}
              </span>
            </span>
            <span className="inner-card" id="lolcat">
              {/* <span className="flip">Flip</span> */}
              <span className="glare"></span>
            </span>
          </div>
        </div>
        <div
          className="link_bef"
          onClick={() => handleClick(params.collection_name)}
        >
          {'❮'}
        </div>
        <div className="edition">1 / 1</div>
        <div className="artwork_header_ShareButton">
          <ShareButton
            artworkName={params.artwork_name}
            artisteName={data.collection.artist.username}
            percentageToNGO={data.cashbacks.ngo.percentage}
            ngoName={data.listing?.ngo?.ngo_name}
          />
        </div>
        <div onClick={handle.enter} className="full_screen">
          <FaExpand />
        </div>
        <FullScreen handle={handle}>
          <div className="full_screen_container">
            <div onClick={handle.exit} className="full_screen">
              <FaExpand />
            </div>
            <div
              className="contain"
              style={{ width: `${mystyle}rem`, height: '80vh' }}
            >
              <div
                className="card blastoise"
                style={{ width: `${mystyle}rem`, height: '80vh' }}
              >
                <span
                  className="inner-card-backface"
                  style={{ width: `${mystyle}rem`, height: '80vh' }}
                >
                  <span className="image">
                    <img
                      src={data.image.url}
                      style={{ height: '80vh', width: 'auto' }}
                    />
                    {/* <span className="unflip">Unflip</span> */}
                  </span>
                </span>
                <span className="inner-card" id="lolcat">
                  {/* <span className="flip">Flip</span> */}
                  <span className="glare"></span>
                </span>
              </div>
            </div>
          </div>
        </FullScreen>
      </div>

      <div className="artwork_details">
        <div className="row">
          <div className="col">
            <h1>{params.artwork_name}</h1>
            <a
              href={URLPolygonTransactionLink}
              rel="stylesheet"
              target="_blank"
            >
              <div className="polygon_link">
                <div className="sub">
                  {t('collection.mintedAt')}
                  {data.created_at.slice(0, 10)}
                </div>
                <OpenInNewIcon />
              </div>
            </a>
            <Box sx={{ display: 'flex', gap: '1rem' }}>
              <ArtistButton type={'card'} artist={data.collection.artist} />
              {data.owner?.username != data.collection.artist.username ? (
                <EntityButton
                  title={t('collection.owner')}
                  url={data?.owner?.avatar?.url_small || default_profile}
                  username={data?.owner?.username}
                />
              ) : null}
              {data.collection?.ngo || data.listing?.ngo ? (
                <EntityButton
                  title={t('collection.benefit')}
                  url={
                    data?.collection?.ngo?.logo?.url_small ||
                    data?.listing?.ngo?.logo?.url_small
                  }
                  username={
                    data?.collection?.ngo?.ngo_name ||
                    data?.listing?.ngo?.ngo_name
                  }
                />
              ) : null}
            </Box>
            {data?.description != null ? (
              <>
                <h2>{t('collection.description')}</h2>
                <div className="sub">{data.description}</div>
              </>
            ) : null}
            <h2>{t('collection.details')}</h2>
            <Box
              sx={{
                display: 'flex',
                alignItem: 'center',
                marginTop: '1rem',
                gap: '1rem',
              }}
            >
              <a href={data?.ipfsUrl} target="_blank">
                <Chip
                  icon={<DataObjectIcon />}
                  label={t('collection.metadatas')}
                  variant="outlined"
                  clickable
                />
              </a>
              <a
                href={
                  process.env.REACT_APP_BLOCKCHAIN_URL +
                  data?.collection?.address +
                  '/' +
                  data?.tokenId
                }
                target="_blank"
              >
                <Chip
                  icon={<SailingIcon />}
                  label="Opensea"
                  variant="outlined"
                  clickable
                />
              </a>
            </Box>
            {/* <RevenueSplit
              reskue={data.cashbacks?.reskue?.percentage}
              artist={data.cashbacks?.artist?.percentage}
              ngo={data.cashbacks?.ngo?.percentage}
            /> */}
            <h2>For nonprofit</h2>
            <Avatar sx={{ bgcolor: '#223D40', padding: '3rem' }}>
              {data.cashbacks?.ngo?.percentage}%
            </Avatar>
          </div>
          <div className="col">
            <RightSection {...data} />
          </div>
        </div>
      </div>

      <div className="collection_container">
        <h2>{t('collection.more')}</h2>
        <CollectionCard
          key={data.collection.id}
          height={600}
          image_url={data.collection.image?.url_medium}
          collection_name={data.collection.collection_name}
          description={data.collection.description}
          released={data.collection.released}
          size={data.collection.size}
          ngo={data.collection?.ngo}
          not={params.artwork_name}
          artist={data?.collection?.artist}
          rate={rate}
        />
      </div>
    </div>
  );
}
