import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import { updateSession } from 'data/session.slice';
import Session from 'interfaces/session.interface';
import { removeTokens } from 'services/tokens.service';

interface IProps {
  dispatch: Dispatch<AnyAction>;
  session: Session;
}

export default function unsetCurrentSession({ dispatch, session }: IProps) {
  removeTokens();
  dispatch(
    updateSession({
      ...session,
      username: '',
      email: '',
      exists: false,
    }),
  );
}
