import axios from 'axios';
import { Store } from 'data/store';
import useSetCurrentSession from 'helpers/auth/set_current_session';
import BlackButton from 'helpers/elements/black_button';
import FormPassword, { usePassword } from 'helpers/elements/formPassword';
import { EInputStatusEnum } from 'helpers/status';
import Session from 'interfaces/session.interface';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import saveTokens from 'services/tokens.service';
import './password_forms.scss';

export default function ResetPassword() {
  /** Global data */
  const session: Session = useSelector((store: Store) => store.session);

  /** Tools */
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /** Constants */
  const token = searchParams.get('resetToken');

  /** Variables */
  const [newPassword, setNewPassword] = usePassword();

  const [formError, setFormError] = useState<string>('');

  async function submit() {
    if (newPassword.status === EInputStatusEnum.Valid) {
      try {
        const response = await axios.post(
          process.env.REACT_APP_BACKEND_URL + '/auth/user/recover',
          {
            token: token,
            password: newPassword.content,
            passwordConfirm: newPassword.content,
          },
        );
        saveTokens(response.data);
        useSetCurrentSession({ session, dispatch });
        navigate('/');
        return false;
      } catch (error: any) {
        setFormError(error.response.data?.message);
      }
    }
    return false;
  }

  return (
    <div id="reset_password">
      <h1>Reset password</h1>
      <form>
        <FormPassword
          password={newPassword}
          setPassword={setNewPassword}
          label="New Password"
          creationForm={true}
        />
        <BlackButton content="Continue" onClick={submit} />
        <p id="password_form_error">{formError}</p>
      </form>
    </div>
  );
}
