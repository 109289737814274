import React, { ChangeEvent, useEffect, useState } from 'react';
import VerifiedIcon from '@mui/icons-material/Verified';
import './profile.scss';
import Session from 'interfaces/session.interface';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'data/store';
import { useNavigate } from 'react-router-dom';
import ArtworkCard from '../cards/artwork_card/artwork_card';
import { ImgLoad } from 'utils/ImgLoader/img_loader';
import { api } from 'api/reskueApi';
import unsetCurrentSession from 'helpers/auth/unset_current_session';
import { toast } from 'react-toastify';
import CoverUpdate from './cover_update';
import { FaUserEdit } from 'react-icons/fa';
import { Backdrop, Box, CircularProgress, Tab, Tabs } from '@mui/material';
import CopyToClipboard from 'utils/copy_clipboard';
import { Link } from 'react-router-dom';
import CollectionList from './artist/CollectionList';
import axios from 'axios';
import { updateSession } from 'data/session.slice';
import { useTranslation } from 'react-i18next';
import polygon_wallet from 'images/ui/poly_wallet.png';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={'tab_pannel'}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function Profile() {
  /** Global Data */
  const session: Session = useSelector((state: Store) => state.session);

  /** Tools */
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  /** Constants */
  // const { totalAmount, nbOfNft } = useUserStats();
  const [log, setLog] = useState('');
  const [open, setOpen] = React.useState(false);
  const [avatar, setAvatar] = useState<any>({
    url: session?.avatar,
    file: '',
  });

  const [cover, setCover] = useState<any>({
    url: session?.cover,
    file: '',
  });

  const [value, setValue] = React.useState(0);
  const [data, setData] = useState([]);

  const [rate, setRate] = useState<number>();
  const [count, setCount] = useState<number>(0);
  const [collecList, setCollecList] = useState<any>(null);

  async function getRate() {
    try {
      const resp = await api.get('/money/exchangeRate', {
        params: {
          fiat: 'EUR',
        },
      });
      setRate(resp.data);
    } catch (err: any) {
      console.error(err.response?.data?.message);
    }
  }

  async function call() {
    api
      .get(`/gallery/collection/many/artist/${session.username}`, {
        params: {
          relations: ['artist'],
        },
      })
      .then((res: any) => {
        setCollecList(Array.from(res.data.items));
        setCount(res.data.items.length);
      })
      .catch(err => {
        console.error(err);
      });
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const toastId = React.useRef(null);
  const notify = () =>
    (toastId.current = toast(t('user.edit.avatar.download'), {
      autoClose: false,
      position: toast.POSITION.BOTTOM_LEFT,
    }));
  const update = () =>
    toast.update(toastId.current, {
      render: t('user.edit.avatar.success'),
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });
  const error = () =>
    toast.update(toastId.current, {
      render: t('user.edit.avatar.errors.default'),
      type: toast.TYPE.ERROR,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

  function onFileChange(event: ChangeEvent<HTMLInputElement>) {
    setOpen(true);
    notify();
    if (event.target.files === null || event.target.files.length <= 0) return;
    const imageType = /^image\//;

    if (!imageType.test(event.target.files[0].type)) {
      setLog(t('user.edit.avatar.errors.badImage'));
      return;
    }
    setLog('');
    setAvatar({
      url: window.URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
    });
  }

  async function fetchData() {
    await axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          `/gallery/artwork/many/owner/${session.username}`,
        {
          params: {
            relations: ['collection', 'collection.artist', 'listing', 'owner'],
          },
        },
      )
      .then(res => {
        if (res.data) {
          setData(res.data.items);
        }
      })
      .catch(error => {
        console.error(error.message);
      });
  }

  useEffect(() => {
    // call artworks owner by user
    fetchData();
    call();
    getRate();
  }, []);

  useEffect(() => {
    updateAvatar();
  }, [avatar]);

  async function updateAvatar() {
    if (avatar.file) {
      const data = new FormData();
      data.append('file', avatar.file);

      try {
        const ret = await api.post('/user/avatar', data);
        update();
        console.error(ret);
        dispatch(
          updateSession({
            ...session,
            avatar: ret.data?.url_small,
          }),
        );
        setOpen(false);
        window.location.reload();
        return true;
      } catch (err: any) {
        setLog(err.response?.data?.message);
        error();
        if (err.status === 401) {
          unsetCurrentSession({ session, dispatch });
        }
      }
    }
    setOpen(false);
    return false;
  }

  return (
    <>
      <section id="profile_section">
        <Backdrop
          sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
          open={open}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <div className="profile_header">
          <ImgLoad
            className="profile_cover"
            src={cover?.url + '?auto=compress&q=80'}
            height={300}
          />
          <div className="space"></div>
          <div id="profile_perso">
            <label className="file_upload">
              <img src={avatar.url} />
              <input
                type="file"
                name="avatar"
                id="avatar_input"
                onChange={onFileChange}
              />
            </label>
            <CoverUpdate setCover={setCover} />
          </div>
          <div className="profile_menu">
            <div className="line">
              <Box
                sx={{
                  display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex' },
                  maxWidth: '100vw',
                }}
              >
                <Box sx={{ display: 'block' }}>
                  <Box sx={{ display: 'flex' }}>
                    <h1>{session.username}</h1>

                    {session.roles.includes('artist') === true ? (
                      <Box
                        sx={{
                          display: 'flex',
                          marginLeft: '1rem',
                          width: { xs: '100%' },
                          float: { xs: 'none', md: 'left', lg: 'left' },
                          alignItems: 'center',
                          gap: '0.2rem',
                          justifyContent: { xs: 'center', md: 'normal' },
                        }}
                      >
                        <button
                          id="artist_btn"
                          className="btn btn-gradient btn-glow"
                        >
                          {t('user.profile.artist')}
                          <VerifiedIcon sx={{ fontSize: 15 }} />
                        </button>
                      </Box>
                    ) : null}
                  </Box>

                  <CopyToClipboard>
                    {({ copy }) => (
                      <div
                        className="wallet_info"
                        onClick={() => copy(session.wallet)}
                      >
                        <img src={polygon_wallet} width="20" height="20" />
                        {session.wallet.substring(0, 6) +
                          '...' +
                          session.wallet.substring(38)}
                      </div>
                    )}
                  </CopyToClipboard>

                  {session?.description ? (
                    <Box pt={2} pb={1} m="auto">
                      {session.description}
                    </Box>
                  ) : null}
                </Box>
              </Box>

              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                {session.roles.includes('artist') === true ? (
                  <Link to={'/create'}>
                    <button className="btn btn-gradient-border btn-glow">
                      {t('user.edit.collections.create')}
                    </button>
                  </Link>
                ) : null}
                <div className="user_tools">
                  {/* <div className="edit_but" */}
                  <div
                    className="edit_but btn-gradient-border btn-glow"
                    onClick={() => navigate('/user/edit')}
                  >
                    <FaUserEdit />
                  </div>
                </div>
              </Box>
            </div>
            {/* TODO: add description */}
            {/* <div className=""></div> */}
          </div>

          {/* TODO: add shimmer effect circle load */}
        </div>
        <div className="tab_menu">
          <div className="menu"></div>
          <div className="line"></div>
        </div>
        <div id="profile">
          {/* <div id="user_score">
            <p>
              <span>{totalAmount}</span> RSK
            </p>
            <p>
              <span>{nbOfNft}</span> Proofs of reskue
            </p>
          </div>  */}
        </div>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={`${t('user.profile.artworks')} (${data?.length ?? 0})`}
                {...a11yProps(0)}
              />
              {session.roles.includes('artist') === true ? (
                <Tab
                  label={`${t('user.profile.collections')} (${count})`}
                  {...a11yProps(1)}
                />
              ) : null}
              {/* <Tab label="Achievements" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div id="donation_grid_container">
              <div className="grid">
                {data.map((data: any) => (
                  <ArtworkCard
                    key={data.id}
                    height={600}
                    image={data.image?.url_medium}
                    data_name={data.artwork_name}
                    name={data.artwork_name}
                    type="Sold"
                    collection={data?.collection}
                    collection_name={data?.collection.collection_name}
                    username={session?.username}
                    shareable={true}
                    owner={data?.owner}
                    artist={data.collection.artist}
                    listing={data?.listing}
                    rate={rate}
                  />
                  // add sale container
                ))}
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CollectionList
              username={session?.username}
              collecList={collecList}
            />
          </TabPanel>
          <TabPanel value={value} index={2}>
            Item Three
          </TabPanel>
        </Box>
      </section>
    </>
  );
}
