import ReskueApiResponse from 'app/interfaces/reskueApiResponse.interface';
import { api, rawApi } from './reskueApi';

/**
 * Handles reskue api error and returns it
 * @param error api return error
 * @returns reskue api response with appropriate error
 */
function handleApiError<Type>(error: any): ReskueApiResponse<Type> {
  if (error.response) return { data: null, error: error.response.data.message };
  console.error(error.response);
  return { data: null, error: 'Unexpected error' };
}

/**
 * HTTP GET with current user's authentication tokens
 * @param baseUrl url to fetch
 * @returns (data and empty error) or (null and error message)
 */
export async function ReskueGet<Type>(
  baseUrl: string,
): Promise<ReskueApiResponse<Type>> {
  try {
    const response = await api.get(baseUrl);
    return { data: response.data, error: '' };
  } catch (error: any) {
    return handleApiError<Type>(error);
  }
}

/**
 * HTTP POST with current user's authentication tokens
 * @param baseUrl url to fetch
 * @param body post body content
 * @returns (data and empty error) or (null and error message)
 */
export async function ReskuePost<ApiInput, ApiOutput>(
  baseUrl: string,
  body: ApiInput,
): Promise<ReskueApiResponse<ApiOutput>> {
  try {
    const response = await api.post(baseUrl, body);
    return { data: response.data, error: '' };
  } catch (error: any) {
    return handleApiError<ApiOutput>(error);
  }
}

/**
 * HTTP POST without current user's authentication tokens
 * @param baseUrl url to fetch
 * @param body post body content
 * @returns (data and empty error) or (null and error message)
 */
export async function ReskueRawPost<ApiInput, ApiOutput>(
  baseUrl: string,
  body: ApiInput,
): Promise<ReskueApiResponse<ApiOutput>> {
  try {
    const response = await rawApi.post(baseUrl, body);
    return { data: response.data, error: '' };
  } catch (error: any) {
    return handleApiError<ApiOutput>(error);
  }
}
