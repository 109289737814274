import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import React, { createElement, Fragment, useEffect, useRef } from 'react';
import algoliasearch from 'algoliasearch';
import { render } from 'react-dom';
import '@algolia/autocomplete-theme-classic';
import './mainSearchbar.scss';
import default_profile from 'images/ui/default_avatar.png';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export function Autocomplete(props) {
  const containerRef = useRef(null);
  const { t } = useTranslation('common');

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const search = autocomplete({
      container: containerRef.current,
      renderer: { createElement, Fragment, render },
      placeholder: t('header.searchbar'),
      ...props,
    });

    return () => {
      search.destroy();
    };
  }, [props]);

  return <div ref={containerRef} />;
}

export function MainSearchbar(props: any) {
  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_PUBLIC_KEY,
  );
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <div className="mainSearchbar">
      <div className="mainSearchbar-autocomplete">
        <Autocomplete
          detachedMediaQuery={props.detachedMediaQuery}
          panelContainer={'.mainSearchbar-panel'}
          openOnFocus={true}
          getSources={({ query }) => [
            {
              sourceId: 'Users',
              getItems() {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: 'Users',
                      query,
                      params: {
                        hitsPerPage: 5,
                      },
                    },
                  ],
                });
              },
              templates: {
                header() {
                  return t('searchbar.searchpannel.header.users');
                },
                item({ item, components }) {
                  return (
                    <UsersItem
                      hit={item}
                      components={components}
                      navigator={navigate}
                      onItemClick={props.onItemClick}
                    />
                  );
                },
              },
            },
            {
              sourceId: 'Ngos',
              getItems() {
                return getAlgoliaResults({
                  searchClient,
                  queries: [
                    {
                      indexName: 'Ngos',
                      query,
                      params: {
                        hitsPerPage: 3,
                      },
                    },
                  ],
                });
              },
              templates: {
                header() {
                  return t('searchbar.searchpannel.header.ngos');
                },
                item({ item, components }) {
                  return (
                    <NgosItem
                      hit={item}
                      components={components}
                      navigator={navigate}
                      onItemClick={props.onItemClick}
                    />
                  );
                },
              },
            },
          ]}
        />
      </div>
      <div className="mainSearchbar-panel"></div>
    </div>
  );
}

interface ItemProp {
  hit: any;
  components: any;
  navigator: NavigateFunction;
  onItemClick?: React.MouseEventHandler<HTMLDivElement>;
}

interface ItemRedirectOptions {
  path: string;
  event: React.MouseEvent<HTMLDivElement, MouseEvent>;
  navigator: NavigateFunction;
  onItemClick?: React.MouseEventHandler<HTMLDivElement>;
}

function itemRedirect(options: ItemRedirectOptions) {
  if (options.onItemClick) {
    options.onItemClick(options.event);
  }
  options.navigator(options.path);
}

function UsersItem(props: ItemProp) {
  return (
    <div
      className="aa-ItemWrapper"
      onClick={event =>
        itemRedirect({ path: '/user/' + props.hit.username, event, ...props })
      }
    >
      <div className="aa-ItemContent">
        {/* <div className="aa-ItemIcon aa-ItemIcon--alignTop"> */}
        <div className="search_img_cont">
          <img
            src={props.hit.avatar ? props.hit.avatar : default_profile}
            alt={props.hit.username}
            className="search_img"
          />
        </div>
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">{props.hit.username}</div>
          <div className="aa-ItemContentDescription">
            {props.hit.reputation.toString() + ' RSK'}
          </div>
        </div>
      </div>
    </div>
  );
}

function NgosItem(props: ItemProp) {
  return (
    <div
      className="aa-ItemWrapper"
      onClick={event =>
        itemRedirect({ path: '/ngos/' + props.hit.name, event, ...props })
      }
    >
      <div className="aa-ItemContent">
        <div className="aa-ItemIcon aa-ItemIcon--alignTop">
          <img
            src={props.hit.logo}
            alt={props.hit.name}
            className="aa-ItemImage"
            width="40"
            height="40"
          />
        </div>
        <div className="aa-ItemContentBody">
          <div className="aa-ItemContentTitle">{props.hit.name}</div>
          <div className="aa-ItemContentDescription">{props.hit.country}</div>
        </div>
      </div>
    </div>
  );
}
