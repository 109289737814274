import checkMail from 'helpers/input_helpers/mailChecker';
import { EInputStatusEnum } from 'helpers/status';
import { IEmailInput } from 'interfaces/emailInput.interface';

interface iProps {
  event: React.ChangeEvent<HTMLInputElement>;
  setEmail: React.Dispatch<React.SetStateAction<IEmailInput>>;
}

function mailOnChange({ event, setEmail }: iProps) {
  const address: string = event.target.value;
  let error = '';
  let status: EInputStatusEnum = EInputStatusEnum.Empty;

  if (checkMail({ address }) === false) {
    error = 'mail invalid';
    status = EInputStatusEnum.Invalid;
  } else if (address !== '') {
    status = EInputStatusEnum.Valid;
  }

  setEmail({ address, error, status });
}

export default mailOnChange;
