import React from 'react';
import './artist_button.scss';
import './pay_owner_button.scss';
import { useDispatch } from 'react-redux';
import { modalActive } from 'data/modal.slice';
import Session from 'interfaces/session.interface';
import { Store } from 'data/store';
import { useSelector } from 'react-redux';
import polygon from 'images/ui/polygon.webp';
import { Box } from '@mui/system';
import { useTranslation } from 'react-i18next';
import EntityButton from './entity_button';

export default function PayOwnerButton(props: any) {
  /** Contexts */
  const session: Session = useSelector((state: Store) => state.session);

  // const [isShown, setIsShown] = useState(false);
  // const { height } = useWindowDimensions()
  // const [whereIs, setWhereIs] = useState<string>("")
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const username = props?.owner?.username || props?.owner;

  function handleClick(event: any) {
    event.stopPropagation();
    dispatch(
      modalActive({
        ...props,
        price: props.listing.reserve,
        listing: props.listing,
      }),
    );
  }

  function handleDeal(event: any, type: string) {
    event.stopPropagation();
    dispatch(
      modalActive({
        ...props,
        type: type,
        listing: props.listing,
      }),
    );
  }

  if (
    props?.owner?.username === session?.username ||
    props?.owner === session?.username
  ) {
    if (props?.listing?.listingType === 'NONE') {
      return (
        <div className="but_container">
          <button onClick={e => handleDeal(e, 'sell')}>
            <span>{t('artworks.sell')}</span>
          </button>
        </div>
      );
    }
    if (props?.listing?.listingType === 'DIRECT') {
      return (
        <div className="but_container">
          {/* TODO: ADD THE PRICE */}
          <button onClick={e => handleDeal(e, 'unlist')}>
            <span>{t('artworks.cancel')}</span>
          </button>
        </div>
      );
      // ADD AUCTION
    }
  }

  // <div className="but_container"
  //     onMouseEnter={(e) => (handleClick2(e))}
  //     onMouseLeave={() => setIsShown(false)}
  // >
  //     {isShown && (
  //         // <InfoBubble img={props?.user?.avatar?.url || props?.avatar} name={props?.user?.username || props?.username} whereIs={whereIs}/>
  //         <InfoBubble artist={props?.artist} whereIs={whereIs} />
  //     )}
  //     <div className="avatar">
  //         <img src={user?.avatar?.url || props?.avatar}
  //         />
  //     </div>
  //     <div className="wrapper">
  //         <div className="title">Owner</div>
  //         <div className="artist_name">@{user?.username || props?.username || "me"} </div>
  //     </div>
  // </div>

  if (props?.listing?.listingType === 'NONE') {
    return (
      <EntityButton
        title={t('artworks.owner')}
        url={props?.owner?.avatar?.url || props?.ownerAvatar}
        username={
          username?.length > 7 ? username?.substring(0, 7) + '...' : username
        }
      />
    );
  } else {
    return (
      <div className="but_container">
        <button onClick={props.noclickable ? null : handleClick}>
          <span className="but_card">
            <Box
              sx={{
                display: 'flex',
                alignItem: 'center',
                justifyContent: 'center',
              }}
            >
              {props.listing?.reserve}
              <img src={polygon} className="polygon_token" />
            </Box>
            <div className="eqv">
              {' '}
              ≈
              {Math.round(
                parseFloat(props.listing?.reserve) * props?.rate * 10,
              ) / 10 || 0}
              €
            </div>{' '}
          </span>
        </button>
      </div>
    );
  }
}
