import { configureStore } from '@reduxjs/toolkit';
import sessionReducer from './session.slice';
import donationReducer from './donation.slice';
import ngoListReducer from './ngoList.slice';
import languageReducer, { LanguageSlice } from './language.slice';
import modalReducer from './modal.slice';
import menuReducer from './menu.slice';

export interface Store {
  session: any;
  donation: any;
  ngoList: any;
  language: LanguageSlice;
  modal: any;
  menu: any;
}

export default configureStore({
  reducer: {
    session: sessionReducer,
    donation: donationReducer,
    ngoList: ngoListReducer,
    language: languageReducer,
    modal: modalReducer,
    menu: menuReducer,
  },
});
