import React, { useState } from 'react';
import TwitterIcon from '@mui/icons-material/Twitter';
import InstagramIcon from '@mui/icons-material/Instagram';
import LanguageIcon from '@mui/icons-material/Language';
import FacebookIcon from '@mui/icons-material/Facebook';
import VerifiedIcon from '@mui/icons-material/Verified';
import Session from 'interfaces/session.interface';
import { Store } from 'data/store';
import { useNavigate, useParams } from 'react-router-dom';
import UserEntity from 'interfaces/UserEntity.interface';
import getPublicUser from 'services/user.service';
import { useSelector } from 'react-redux';
import default_profile from 'images/ui/default_avatar.png';
import ArtworkCard from '../cards/artwork_card/artwork_card';
import default_cover from 'images/design/background2.svg';
import { ImgLoad } from 'utils/ImgLoader/img_loader';
import { Helmet } from 'react-helmet-async';
import { Box, IconButton, Tab, Tabs } from '@mui/material';
import CopyToClipboard from 'utils/copy_clipboard';
import CollectionList from '../user/artist/CollectionList';
import axios from 'axios';
import { api } from 'api/reskueApi';
import { useTranslation } from 'react-i18next';
import polygon_wallet from 'images/ui/poly_wallet.png';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      className={'tab_pannel'}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const PublicUserProfile: React.FunctionComponent = () => {
  /** Global Data */
  const session: Session = useSelector((state: Store) => state.session);

  /** Tools */
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  /** Constants */
  const { username } = useParams();

  /** Variables */
  const [user, setUser] = useState<UserEntity | null>(null);
  const [value, setValue] = useState(0);
  const [data, setData] = useState<any>([]);
  const [rate, setRate] = useState<number>();
  const [count, setCount] = useState<number>(0);
  const [collecList, setCollecList] = useState<any>(null);

  async function getRate() {
    try {
      const resp = await api.get('/money/exchangeRate', {
        params: {
          fiat: 'EUR',
        },
      });
      setRate(resp.data);
    } catch (err: any) {
      console.error(err.response?.data?.message);
    }
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  async function fetchData() {
    await axios
      .get(
        process.env.REACT_APP_BACKEND_URL +
          `/gallery/artwork/many/owner/${username}`,
        {
          params: {
            relations: ['collection', 'collection.artist', 'listing', 'owner'],
          },
        },
      )
      .then(res => {
        if (res.data) {
          setData(res.data.items);
        }
      })
      .catch(error => {
        console.error(error.message);
      });
  }

  async function call() {
    api
      .get(`/gallery/collection/many/artist/${username}`, {
        params: {
          relations: ['artist'],
        },
      })
      .then((res: any) => {
        setCollecList(Array.from(res.data.items));
        setCount(res.data.items.length);
      })
      .catch(err => {
        console.error(err);
      });
  }

  React.useEffect(() => {
    if (!username) {
      navigate('/');
      return;
    }
    getRate();
    call();

    const fetchUser = async () => {
      // const ret = await getPublicUser(username, ["donations", "donations.artwork", "donations.ngo", "donations.artwork.collection"]);
      const ret = await getPublicUser(username, []);
      if (ret === undefined) navigate('/404');
      if (ret.username === session.username) navigate('/user');
      setUser(ret);
    };
    fetchUser();
    fetchData();
  }, [username]);

  if (!user) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <>
      <section id="profile_section">
        <Helmet>
          <title>{user.username} | Reskue</title>
          <meta name="name" content={user.username} />
          <meta
            name="description"
            content={
              user?.description || user.username + ' profile page on Reskue'
            }
          />
          <meta name="title" content={`${user.username} | Reskue`} />
          <meta property="og:title" content={`${user.username} | reskue`} />
          {/* <meta property="og:url" content="https://reskue.art/collections" /> */}
          <meta
            property="og:description"
            content={
              user?.description || user.username + ' profile page on Reskue'
            }
          />
          <meta
            property="og:image"
            content={user?.avatar?.url ?? default_profile}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`${user.username} | reskue`} />
          <meta name="twitter:site" content="@reskueNFT" />
          {/* <meta name="twitter:url" content="https://reskue.art/collections" /> */}
          <meta
            name="twitter:image"
            content={user.avatar ? user.avatar.url : default_profile}
          />
          <meta property="og:type" content="website" />
        </Helmet>
        <div className="profile_header">
          <ImgLoad
            className="profile_cover"
            src={user?.cover?.url ? user.cover.url : default_cover}
            // src={data.cover?.url}
            height={300}
            // alt={data.collection_name}
          />

          <div className="space"></div>
          <div id="profile_perso">
            <img
              src={
                user?.avatar?.url_medium
                  ? user?.avatar?.url_medium
                  : default_profile
              }
            />
          </div>

          <Box sx={{ position: 'absolute', right: '1%', display: 'flex' }}>
            {user?.twitter ? (
              <IconButton size="small" target="_blank" href={user?.twitter}>
                <TwitterIcon />
              </IconButton>
            ) : null}
            {user?.instagram ? (
              <IconButton
                size="small"
                aria-label="delete"
                href={user?.instagram}
                target="_blank"
              >
                <InstagramIcon />
              </IconButton>
            ) : null}
            {user?.facebook ? (
              <IconButton
                size="small"
                aria-label="delete"
                href={user?.facebook}
                target="_blank"
              >
                <FacebookIcon />
              </IconButton>
            ) : null}
            {user?.website ? (
              <IconButton
                size="small"
                aria-label="delete"
                href={user?.website}
                target="_blank"
              >
                <LanguageIcon />
              </IconButton>
            ) : null}
          </Box>
          <div className="profile_menu">
            <div className="line">
              <Box
                sx={{
                  display: { xs: 'block', sm: 'block', md: 'flex', lg: 'flex' },
                  maxWidth: '100vw',
                }}
              >
                <Box sx={{ display: 'block' }}>
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}
                  >
                    <h1>{user.username}</h1>
                    {user.roles.includes('artist') === true ? (
                      <button
                        id="artist_btn"
                        className="btn btn-gradient btn-glow"
                      >
                        {t('user.public.artist')}
                        <VerifiedIcon sx={{ fontSize: 15 }} />
                      </button>
                    ) : null}
                    {user?.titles[0] ? (
                      <div className="text-gradient rsk_title">
                        {user?.titles[0]}
                      </div>
                    ) : null}
                  </Box>
                  <CopyToClipboard>
                    {({ copy }) => (
                      <div
                        className="wallet_info"
                        onClick={() => copy(user?.wallet.address)}
                      >
                        <img src={polygon_wallet} width="20" height="20" />
                        {user?.wallet.address.substring(0, 6) +
                          '...' +
                          user?.wallet.address.substring(38)}
                      </div>
                    )}
                  </CopyToClipboard>
                  {user?.description ? (
                    <Box pt={2} pb={1} m="auto">
                      {user.description}
                    </Box>
                  ) : null}
                </Box>
              </Box>
              <div className="user_tools"></div>
            </div>
          </div>
        </div>
        <div id="profile"></div>
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab
                label={`${t('user.public.artworks')} (${data.length})`}
                {...a11yProps(0)}
              />
              {user.roles.includes('artist') === true ? (
                <Tab
                  label={`${t('user.public.collections')} (${count})`}
                  {...a11yProps(1)}
                />
              ) : null}
              {/* <Tab label="Achievements" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={value} index={0}>
            <div id="donation_grid_container">
              <div className="grid">
                {data.map((data: any) => (
                  <ArtworkCard
                    key={data.id}
                    height={600}
                    image={data.image?.url_medium}
                    data_name={data.artwork_name}
                    name={data.artwork_name}
                    type="Sold"
                    collection={data?.collection}
                    collection_name={data?.collection.collection_name}
                    username={username}
                    user={data?.user}
                    shareable={true}
                    artist={data.collection.artist}
                    listing={data.listing}
                    cashbacks={data?.cashbacks}
                    owner={data.owner}
                    rate={rate}
                  />
                ))}
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <CollectionList username={user?.username} collecList={collecList} />
          </TabPanel>
          {/* <TabPanel value={value} index={2}>
            Item Three
          </TabPanel> */}
        </Box>
      </section>
    </>
  );
};

export default PublicUserProfile;
