import React, { useEffect, useState } from 'react';
import { CircularProgress, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { useDispatch } from 'react-redux';
import { api } from 'api/reskueApi';
import CollectionStamp from '../../cards/collection_stamp/CollectionStamp';

export default function CollectionList(props: any) {
  const dispatch = useDispatch();

  const [collecList, setCollecList] = useState<any>(props?.collecList ?? null);
  const [spinner, setSpinner] = useState(false);

  async function call() {
    api
      .get(`/gallery/collection/many/artist/${props.username}`, {
        params: {
          relations: ['artist'],
        },
      })
      .then((res: any) => {
        setCollecList(Array.from(res.data.items));
      })
      .catch(err => {
        console.error(err);
      });
  }

  useEffect(() => {
    setSpinner(true);
    if (collecList === null) call();
    setSpinner(false);
  }, []);

  return (
    <>
      {spinner ? (
        <CircularProgress />
      ) : (
        <Box mb="1rem">
          <Stack
            sx={{ marginTop: '1rem', flexWrap: 'nowrap' }}
            direction={{ sm: 'row', xs: 'column' }}
            spacing={3}
            m={2}
          >
            {collecList?.map(props => (
              <CollectionStamp key={props.id} {...props} />
            ))}
          </Stack>
        </Box>
      )}
    </>
  );
}
