import * as React from 'react';
import { useTranslation } from 'react-i18next';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { MenuItem } from '@mui/material';
import './CopyToClipboard.scss';
const CopyToClipboard = () => {
  const { t } = useTranslation('common');

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href);
  };

  return (
    <div className="copyToClipboard_container" onClick={handleCopy}>
      <ContentCopyIcon />
      {t('CopyToClipboard.copy')}
    </div>
  );
};

export default CopyToClipboard;
