import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import { useDispatch, useSelector } from 'react-redux';
import GetNgos from 'helpers/api_calls/getNgos';
import { GetNgoDto } from 'interfaces/GetNgoDto';
import { Store } from 'data/store';
import { useTranslation } from 'react-i18next';

export default function SelectNgo(props: any) {
  const [currency, setCurrency] = React.useState('');

  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrency(event.target.value);
    //   props.ngo = currency;
    props.onClick(event.target.value);
  };

  useEffect(() => {
    GetNgos({ dispatch });
  }, []);

  const ngos: GetNgoDto[] = useSelector((store: Store) => store.ngoList);

  return (
    <TextField
      fullWidth
      required
      id="outlined-select-currency"
      select
      label={t('ngo.select')}
      value={currency}
      onChange={handleChange}
      defaultValue={'Generic'}
    >
      {ngos.map(option => (
        <MenuItem key={option.id} value={option.id}>
          {option.ngo_name}
        </MenuItem>
      ))}
      <MenuItem value={'Generic'}>{t('ngo.selectGeneric')}</MenuItem>
    </TextField>
  );
}
