import React, { useEffect } from 'react';
import 'style/global.scss';
import 'style/text_input.scss';
import 'style/text_area.scss';
import 'style/button.scss';
import 'style/link.scss';
import './components/navbar/navbar_mobile.scss';
import Routing from './components/routing/routing';
import { useDispatch, useSelector } from 'react-redux';
import GetNgos from 'helpers/api_calls/getNgos';
import { Store } from 'data/store';
import { AuthTokens } from 'interfaces/AuthTokens.interface';
import { getTokens } from 'services/tokens.service';
import { setUser } from 'services/data.service';
import common_en from 'translations/en/common.json';
import common_fr from 'translations/fr/common.json';
import i18next from 'i18next';
import { I18nextProvider } from 'react-i18next';
import { languageToEnglish, languageToFrench } from 'data/language.slice';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { appTheme } from './themes/theme';

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'en',
  resources: {
    en: {
      common: common_en,
    },
    fr: {
      common: common_fr,
    },
  },
});

const App = () => {
  const { session } = useSelector((state: Store) => state);
  const dispatch = useDispatch();

  useEffect(() => {
    /** Set up user session */
    const tokens: AuthTokens | null = getTokens();
    if (tokens !== null) {
      setUser({ session, dispatch });
    }

    const language = localStorage.getItem('reskue_language');
    if (language) {
      if (language === 'US') {
        i18next.changeLanguage('en');
        dispatch(languageToEnglish());
      }
      if (language === 'FR') {
        i18next.changeLanguage('fr');
        dispatch(languageToFrench());
      }
    }

    /** Fetch NGOs data */
    GetNgos({ dispatch });
  }, []);

  return (
    <ThemeProvider theme={appTheme}>
      <CssBaseline enableColorScheme />
      <I18nextProvider i18n={i18next}>
        <Routing />
      </I18nextProvider>
    </ThemeProvider>
  );
};
export default App;
