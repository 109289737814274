import React, { useState } from 'react';
import mailOnChange from 'helpers/input_helpers/mailOnChange';
import textOnChange from 'helpers/input_helpers/textOnChange';
import passwordOnChange from 'helpers/input_helpers/passwordOnChange';
import { EInputStatusEnum } from 'helpers/status';
import axios from 'axios';
import './user_forms.scss';
import { Navigate } from 'react-router-dom';
import useMail from 'helpers/customHooks/useMail';
import BlackButton from 'helpers/elements/black_button';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import Session from 'interfaces/session.interface';
import { Store } from 'data/store';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import OauthButton from '../authentification/ReskueLogin/oauth_button';
import { toast } from 'react-toastify';

export default function UserRegistration() {
  /** Contexts */
  const session: Session = useSelector((state: Store) => state.session);

  const { t } = useTranslation('common');

  /** Variables */
  const [email, setEmail] = useMail();
  const [password, setPassword] = useState({
    main: '',
    confirm: '',
    error: '',
    status: EInputStatusEnum.Empty,
  });

  const [username, setUsername] = useState('');
  const [userNameError, setUserNameError] = useState('');
  const [formError, setFormError] = useState('');
  const [sent, setSent] = useState(false);

  async function registerUser(): Promise<boolean> {
    if (
      password.status !== EInputStatusEnum.Valid &&
      email.status !== EInputStatusEnum.Valid
    ) {
      return false;
    }

    if (username.length < 5) {
      setUserNameError('username too short');
      return false;
    }

    if (process.env.REACT_APP_BACKEND_URL) {
      try {
        const ret = await axios.post(
          process.env.REACT_APP_BACKEND_URL + '/user',
          {
            mail: email.address,
            username: username,
            password: password.main,
            passwordConfirm: password.confirm,
          },
        );
        setSent(true);
        return true;
      } catch (error: any) {
        console.error(error.response);
        setFormError(error.response.data.message);
      }
    }
    return false;
  }

  if (sent === true) {
    toast.info(
      `Thanks for your subscription, a confirmation email has been sent to your address.`,
      {
        position: toast.POSITION.BOTTOM_LEFT,
      },
    );
    return (
      <section id="registration">
        <h3>Check your mailbox</h3>
        <p>
          Thanks for your subscription, a confirmation email has been sent to
          you. Please verify your email address to access to your account.
        </p>
        <Link className="black_link" to="/">
          return home
        </Link>
      </section>
    );
  }

  return (
    <>
      {session.exists === true && <Navigate to="/user" />}
      {session.exists === false && (
        <section id="registration">
          <h3>{t('createAccount.title')}</h3>
          <OauthButton />
          <form id="register_form">
            <div className="input_container">
              <label htmlFor="contact_user_mail">
                {t('createAccount.mail')}
              </label>
              <input
                type="text"
                id="create_user_mail"
                name="mail"
                placeholder="User@gmail.com"
                onChange={event => mailOnChange({ event, setEmail })}
                value={email.address}
              />
              <div className="error_text">{email.error}</div>
            </div>
            <div className="input_container">
              <label htmlFor="contact_username">
                {t('createAccount.username')}
              </label>
              <input
                type="text"
                id="create_username"
                name="username"
                placeholder="Username"
                onChange={event => textOnChange({ event, setter: setUsername })}
                value={username}
                autoComplete="off"
              />
              <div className="error_text">{userNameError}</div>
            </div>
            <div className="input_container">
              <label htmlFor="password">{t('createAccount.password')}</label>
              <input
                type="password"
                id="create_password"
                name="password"
                placeholder="Password"
                onChange={event =>
                  passwordOnChange({ event, password, setPassword })
                }
                value={password.main}
                autoComplete="off"
              />
              <div className="error_text"></div>
            </div>
            <div className="input_container">
              <label htmlFor="password">
                {t('createAccount.passwordConf')}
              </label>
              <input
                type="password"
                id="create_password_confirm"
                name="password_confirm"
                placeholder="Password"
                onChange={event =>
                  passwordOnChange({ event, password, setPassword })
                }
                value={password.confirm}
                autoComplete="off"
              />
              <div className="error_text">{password.error}</div>
            </div>

            <BlackButton onClick={registerUser} content="create an account" />
            <p className="register_error_text">{formError}</p>
          </form>
          <Link className="black_anchor" to="/user/login">
            {t('createAccount.already')}
          </Link>
        </section>
      )}
    </>
  );
}
