import React, { ChangeEvent, useState } from 'react';
import {
  Alert,
  Backdrop,
  CircularProgress,
  Grid,
  Paper,
  TextField,
} from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Box } from '@mui/system';
import { Store } from 'data/store';
import Session from 'interfaces/session.interface';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import './collectionCreation.scss';
import BlackButton from 'helpers/elements/black_button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { toast } from 'react-toastify';
import art from 'images/art/abstract.webp';
import SelectNgo from './selectNgo';
import { api } from 'api/reskueApi';
import { useTranslation } from 'react-i18next';
import { time } from 'console';

export default function CollectionCreation() {
  /** Collection Object */
  const [collecObject, setCollecObject] = useState({
    collection_name: null,
    description: null,
    symbol: null,
    link: null,
    ngo_id: null,
  });

  /** Contexts */
  const session: Session = useSelector((state: Store) => state.session);

  const [open, setOpen] = React.useState(false);
  /** Hooks */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { t } = useTranslation('common');

  // Toast
  const toastId = React.useRef(null);
  const notify = () =>
    (toastId.current = toast(t('collection.create.infos.upload'), {
      autoClose: false,
      position: toast.POSITION.BOTTOM_LEFT,
    }));
  const updateSucc = () =>
    toast.update(toastId.current, {
      render: t('collection.create.success'),
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });
  const update = () =>
    toast.update(toastId.current, {
      render: t('collection.create.infos.upload2'),
      position: toast.POSITION.BOTTOM_LEFT,
    });
  const updateFail = (err: any) =>
    toast.update(toastId.current, {
      render: err,
      type: toast.TYPE.ERROR,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

  const [log, setLog] = useState('');
  const [ngo, setNgo] = useState(null);

  const callback = (name: string | undefined) => {
    setNgo(name);
    setCollecObject({ ...collecObject, ngo_id: name });
  };

  const [avatar, setAvatar] = useState<any>({
    url: session?.avatar,
    file: '',
  });

  const [cover, setCover] = useState<any>({
    url: art,
    file: '',
  });

  async function createCollection() {
    if (
      collecObject.collection_name === null ||
      collecObject.collection_name === '' ||
      collecObject.symbol === null
    ) {
      toast.error(t('collection.create.errors.missingInfos'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return false;
    }
    notify();
    setOpen(true);
    if (collecObject.ngo_id === null || collecObject.ngo_id === 'Generic') {
      delete collecObject.ngo_id;
    }
    try {
      const ret = await api.post('/gallery/collection', collecObject);
      update();
      const data = new FormData();
      data.append('file', avatar.file);
      await api.post(`/gallery/collection/image/${ret.data.id}`, data);
      const data2 = new FormData();
      data2.append('file', cover.file);
      await api.post(`/gallery/collection/cover/${ret.data.id}`, data2);
      updateSucc();
      setOpen(false);
      navigate('/user/edit/' + collecObject.collection_name);
    } catch (err: any) {
      updateFail(err?.response?.data?.message);
      console.error(err.response.data.message);
      setOpen(false);
      return false;
    }

    return false;
  }

  function onFileChange(event: ChangeEvent<HTMLInputElement>, str: string) {
    if (event.target.files === null || event.target.files.length <= 0) return;
    const imageType = /^image\//;

    if (!imageType.test(event.target.files[0].type)) {
      setLog(t('collection.create.errors.badImage'));
      return;
    }
    setLog('');
    if (str === 'avatar') {
      setAvatar({
        url: window.URL.createObjectURL(event.target.files[0]),
        file: event.target.files[0],
      });
    }
    if (str === 'cover') {
      setCover({
        url: window.URL.createObjectURL(event.target.files[0]),
        file: event.target.files[0],
      });
    }
  }

  if (session.roles.includes('artist') === false) navigate('/');
  return (
    <section className="create">
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2} ml={2} mr={2}>
        <Grid xs={6}>
          {/* <input id="input" type="text" value={input} onChange={e => setInput(e.target.value)} /> */}
          <Box>
            <Box p="3rem" sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography component={'span'}>
                <h1>{t('collection.create.title')}</h1>

                <TextField
                  fullWidth
                  margin="normal"
                  id="outlined-required"
                  label={t('collection.create.name')}
                  onChange={e =>
                    setCollecObject({
                      ...collecObject,
                      collection_name: e.target.value,
                    })
                  }
                  InputLabelProps={{ shrink: true }}
                  required
                  // inputRef={nameRef}
                  // onChange={() => (handleChange(nameRef.current.value))}
                />
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label={t('collection.create.description')}
                  margin="normal"
                  multiline
                  rows={4}
                  InputLabelProps={{ shrink: true }}
                  onChange={e =>
                    setCollecObject({
                      ...collecObject,
                      description: e.target.value,
                    })
                  }
                />
                <TextField
                  fullWidth
                  required
                  margin="normal"
                  id="outlined-required"
                  label={t('collection.create.symbol')}
                  onChange={e =>
                    setCollecObject({ ...collecObject, symbol: e.target.value })
                  }
                  InputLabelProps={{ shrink: true }}
                />

                <div className="collection_imgs">
                  <label>
                    <Box mb="1rem">
                      <Paper
                        variant="outlined"
                        sx={{
                          padding: '1rem',
                          display: 'flex',
                          alignBox: 'center',
                          justifyContent: 'space-between',
                          gap: '1rem',
                          color: 'rgba(0, 0, 0, 0.6)',
                        }}
                      >
                        {t('collection.create.image')}
                        <AddPhotoAlternateIcon
                          color={
                            avatar?.url != session?.avatar &&
                            avatar?.url != null
                              ? 'success'
                              : 'primary'
                          }
                        />
                      </Paper>
                      <input
                        type="file"
                        name="avatar"
                        id="avatar_input"
                        hidden
                        onChange={e => onFileChange(e, 'avatar')}
                      />
                    </Box>
                  </label>

                  <label>
                    <Box sx={{ marginBottom: '1rem' }}>
                      <Paper
                        variant="outlined"
                        sx={{
                          padding: '1rem',
                          display: 'flex',
                          alignBox: 'center',
                          justifyContent: 'space-between',
                          gap: '1rem',
                          color: 'rgba(0, 0, 0, 0.6)',
                        }}
                      >
                        {t('collection.create.cover')}
                        <AddPhotoAlternateIcon
                          color={
                            cover?.url != art && cover?.url != null
                              ? 'success'
                              : 'primary'
                          }
                        />
                      </Paper>
                      <input
                        type="file"
                        name="cover"
                        id="avatar_input"
                        hidden
                        onChange={e => onFileChange(e, 'cover')}
                      />
                    </Box>
                  </label>
                </div>
                <SelectNgo onClick={callback} />
              </Typography>
              <Alert sx={{ marginTop: '1rem' }} severity="info">
                Be sure to have at least 2 matics on your wallet.
              </Alert>
              <BlackButton
                content={t('collection.create.confirm')}
                onClick={createCollection}
              />
            </Box>
          </Box>
        </Grid>
        <Grid xs={6}>
          <Box>
            <Box p="3rem" sx={{ display: 'flex', flexDirection: 'column' }}>
              <h1>{t('collection.create.preview')}</h1>

              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea>
                  <Box height="200">
                    <div className="preview_avatar">
                      <img src={avatar.url} />
                    </div>
                    <CardMedia
                      sx={{
                        filter:
                          'brightness(67%) grayscale(10%) contrast(120%) sepia(36%)',
                      }}
                      component="img"
                      height="200"
                      image={cover.url}
                      alt="green iguana"
                    ></CardMedia>
                  </Box>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {collecObject.collection_name ??
                        t('collection.create.name')}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      component={'span'}
                    >
                      {collecObject.description ??
                        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </section>
  );
}
