import React, { useEffect, useState } from 'react';
import { CircularProgress, Tab, Tabs, Typography } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import { Box } from '@mui/system';
import { BasicTable } from 'utils/BasicTable/BasicTable';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { useSelector } from 'react-redux';
import Session from 'interfaces/session.interface';
import { Store } from 'data/store';
import { useTranslation } from 'react-i18next';

export default function General() {
  /** Contexts */
  const session: Session = useSelector((state: Store) => state.session);

  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  const [spinner, setSpinner] = useState(true);
  const [data, setData] = useState(null);

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {/* <Typography> */}
            {children}
            {/* </Typography> */}
          </Box>
        )}
      </div>
    );
  }

  function handleNav(e: any) {
    navigate('/user/edit/' + params.collection_name + '/create');
  }

  useEffect(() => {
    // setSpinner(true);
    if (process.env.REACT_APP_BACKEND_URL) {
      let abortController: any;
      (async () => {
        abortController = new AbortController();
        const signal = abortController.signal;
        await axios
          .get(
            process.env.REACT_APP_BACKEND_URL +
              `/gallery/collection/find/${params.collection_name}`,
            {
              signal: signal,
              params: {
                relations: [
                  'artist',
                  'ngo',
                  'artworks.listing',
                  'artworks.owner',
                ],
              },
            },
          )
          .then((res: any) => {
            setData(res.data);
            setSpinner(false);
          })
          .catch(err => {
            console.error(err);
            navigate('/404');
          });
      })();
      return () => abortController.abort();
    }
  }, []);

  if (session.username != data?.artist?.username) {
    navigate('/');
  }

  return (
    <>
      {spinner ? (
        <CircularProgress />
      ) : (
        <Box sx={{ bgcolor: 'background.paper', display: 'flex', gap: '3rem' }}>
          <Tabs
            orientation="vertical"
            value={0}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider', pt: '2rem' }}
          >
            <Tab
              sx={{
                padding: '2rem',
                textTransform: 'none',
                justifyContent: 'left',
              }}
              label={t('collection.general')}
              {...a11yProps(0)}
              icon={<RemoveRedEyeIcon />}
              iconPosition="start"
            />
            <Tab
              sx={{
                padding: '2rem',
                textTransform: 'none',
                justifyContent: 'left',
              }}
              label={t('artworks.create.title')}
              {...a11yProps(2)}
              icon={<CreateIcon />}
              iconPosition="start"
              onClick={handleNav}
            />
          </Tabs>
          <TabPanel value={0} index={0}>
            <Box m={3}>
              <Typography variant="h1">
                Collection {params.collection_name}{' '}
              </Typography>
            </Box>
            <BasicTable
              artworks={data?.artworks}
              collection={params.collection_name}
              artist={data?.artist.username}
            />
          </TabPanel>
        </Box>
      )}{' '}
    </>
  );
}
