import { Store } from 'data/store';
import unsetCurrentSession from 'helpers/auth/unset_current_session';
import Session from 'interfaces/session.interface';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { api } from 'api/reskueApi';
import './profile.scss';
import './avatar_update.scss';
import { useTranslation } from 'react-i18next';

export default function AvatarUpdate() {
  /** Global Data */
  const session: Session = useSelector((state: Store) => state.session);

  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const [log, setLog] = useState('');
  const [avatar, setAvatar] = useState<any>({
    url: session?.avatar,
    file: '',
  });

  const toastId = React.useRef(null);
  const notify = () =>
    (toastId.current = toast(t('user.edit.avatar.download'), {
      autoClose: false,
      position: toast.POSITION.BOTTOM_LEFT,
    }));
  const update = () =>
    toast.update(toastId.current, {
      render: t('user.edit.avatar.success'),
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });
  const error = () =>
    toast.update(toastId.current, {
      render: t('user.edit.avatar.errors.default'),
      type: toast.TYPE.ERROR,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

  function onFileChange(event: ChangeEvent<HTMLInputElement>) {
    notify();
    if (event.target.files === null || event.target.files.length <= 0) return;
    const imageType = /^image\//;

    if (!imageType.test(event.target.files[0].type)) {
      setLog(t('user.edit.avatar.errors.badImage'));
      return;
    }
    setLog('');
    setAvatar({
      url: window.URL.createObjectURL(event.target.files[0]),
      file: event.target.files[0],
    });
  }

  useEffect(() => {
    updateAvatar();
  }, [avatar]);

  async function updateAvatar() {
    if (avatar.file) {
      const data = new FormData();
      data.append('file', avatar.file);
      try {
        await api.post('/user/avatar', data);
        update();
        window.location.reload();
        return true;
      } catch (err: any) {
        setLog(err.response?.data?.message);
        error();
        if (err.status === 401) {
          unsetCurrentSession({ session, dispatch });
        }
      }
    }
    return false;
  }

  return (
    <div id="avatar_update">
      <div>{t('user.edit.avatar.update')}</div>
      <label className="file_upload">
        <img src={avatar.url} />
        <input
          type="file"
          name="avatar"
          id="avatar_input"
          onChange={onFileChange}
        />
      </label>
    </div>
  );
}
