import React, { useEffect, useState } from 'react';
import './ngo_card.scss';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateDonation } from '../../../redux/donation.slice';
import { useIsTouchScreen } from 'helpers/customHooks/useCustomQueries';
import { Store } from 'data/store';
import { GetNgoDto } from 'interfaces/GetNgoDto';
import { useTranslation } from 'react-i18next';
import { ImgLoad } from 'utils/ImgLoader/img_loader';

interface iProps {
  ngo: GetNgoDto;
  redirect_to_donation: boolean;
}

export default function NgoCard({ ngo, redirect_to_donation = false }: iProps) {
  /** Contexts */
  const donation = useSelector((state: Store) => state.donation);
  const dispatch = useDispatch();

  /** Variables */
  const [touchScreenNbEvent, setTouchScreenNbEvent] = useState(0);

  /** Short description */
  let tiny_descr = ngo.mission;
  if (tiny_descr && tiny_descr.length > 280)
    tiny_descr = tiny_descr.substring(0, 280) + '[...]';

  const navigate = useNavigate();

  function handleClick() {
    if (useIsTouchScreen() && touchScreenNbEvent == 0) {
      setTouchScreenNbEvent(nb => nb + 1);
      return;
    }
    if (redirect_to_donation == true) {
      dispatch(
        updateDonation({
          ...donation,
          ngo_id: ngo.id,
          ngo_name: ngo.ngo_name,
          ngo_image: ngo.logo?.url,
          ngo_mollie_account: ngo.mollie_account_id,
          cause_name: ngo.cause.cause_name,
        }),
      );
      navigate('/donation');
    } else {
      navigate('/ngos/' + ngo.ngo_name);
    }
  }

  return (
    <div className="ngo_card" onClick={handleClick}>
      <div className="front">
        <ImgLoad
          src={ngo.logo?.url_medium}
          alt={ngo.ngo_name}
          width={300}
          height={300}
          className="ngo_image"
        />
        <div className="card_content">
          <div className="ngo_descr">{tiny_descr}</div>
          <div className="cause_wrapper">
            <h3>{ngo.ngo_name}</h3>
            <img src={ngo.cause.image?.url} className="cause_icon" />
          </div>
          <p>{ngo.tagline}</p>
        </div>
      </div>
    </div>
  );
}
