import React from 'react';
import google from 'images/social_media/google_auth.png';
import facebook from 'images/social_media/facebook_auth.png';
import './oauth_button.scss';

export default function OauthButton() {
  return (
    <div className="oauth_wrapper">
      <a href={process.env.REACT_APP_BACKEND_URL + '/auth/user/google/login'}>
        <div className="oauth_but">
          <div>Connect</div>
          <img src={google} />
        </div>
      </a>
      <a href={process.env.REACT_APP_BACKEND_URL + '/auth/user/facebook/login'}>
        <div className="oauth_but">
          <div>Connect</div>
          <img src={facebook} />
        </div>
      </a>
    </div>
  );
}
