import { useMediaQuery } from 'react-responsive';
import SecondaryNavbar from './secondaryNavbar';
import PrimaryNavbar from './primaryNavbar';
import 'style/global.scss';
import './navbar.scss';
import React, { useEffect, useState } from 'react';
import {
  useIsDesktop,
  useIsMobile,
  useIsTablet,
} from 'helpers/customHooks/useCustomQueries';
import './desktop_navbar.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { logUserFromToken } from '../../services/tokens.service';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../redux/store';
import { setUser } from '../../services/data.service';
import Session from '../../shared/interfaces/session.interface';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import useSetCurrentSession from '../../shared/helpers/auth/set_current_session';

function Navbar() {
  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();
  const isMobile = useIsMobile();
  const { t } = useTranslation('common');

  const [hideBanner, setHideBanner] = useState<boolean>(false);

  useEffect(() => {
    if (sessionStorage.getItem('reskue_hide_navbar') !== null) {
      setHideBanner(true);
    } else {
      setHideBanner(false);
    }
  }, [hideBanner]);

  // TODO: remove  from navbar?
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const token = searchParams.get('oauth');
  const err = searchParams.get('err');
  const session: Session = useSelector((state: Store) => state.session);

  async function setSession() {
    await logUserFromToken(token);
    await setUser({ session, dispatch });
    navigate('/');
    //window.location.reload();
    toast.success(t('user.login.welcome2'), {
      position: toast.POSITION.BOTTOM_LEFT,
    });
  }

  useEffect(() => {
    if (err)
      toast.error(err, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    if (token) {
      setSession();
    }
  }, [err, token]);

  return (
    <header>
      {(isDesktop || isTablet) && <PrimaryNavbar />}
      {isMobile && <SecondaryNavbar />}
      {/* <div id="alpha_banner">🚀 This is a beta version, the official version will be released on October 2022 🚀</div> */}
    </header>
  );
}

export default Navbar;
