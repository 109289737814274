import { createSlice } from '@reduxjs/toolkit';
import IDonation from 'interfaces/donation.interface';

export interface IDonationSlice {
  donation: IDonation;
}

const initialState = {
  nft_artwork_id: '',
  nft_artwork_name: '',
  nft_artwork_img: '',
  nft_collection_name: '',

  ngo_id: '',
  ngo_name: '',
  ngo_image: '',
  ngo_mollie_account: '',

  cause_id: '',
  cause_name: '',

  amount_to_cause: 0,
  amount_total: 0,
  amount_to_reskue: 0,
  currency: '',

  user_wallet: '',
  user_email: '',

  stripe_secret_client: '',
  donation_intent_id: '',
};

interface IDonationAction {
  payload: IDonation;
  type: string;
}

interface IDonationEmailAction {
  payload: string;
  type: string;
}

interface IDonationWalletAction {
  payload: string;
  type: string;
}

interface IDonationAmountAction {
  payload: {
    amount_to_cause: number;
    amount_total: number;
    amount_to_reskue: number;
    currency: string;
  };
  type: string;
}

export const donationSlice = createSlice({
  name: 'donation',
  initialState,
  reducers: {
    updateDonation(state, action: IDonationAction) {
      void state;
      return action.payload;
    },
    updateDonationEmail(state, action: IDonationEmailAction) {
      state.user_email = action.payload;
    },
    updateDonationWallet(state, action: IDonationWalletAction) {
      state.user_wallet = action.payload;
    },
    updateDonationAmount(state, action: IDonationAmountAction) {
      state.amount_to_cause = action.payload.amount_to_cause;
      state.amount_total = action.payload.amount_total;
      state.amount_to_reskue = action.payload.amount_to_reskue;
      state.currency = action.payload.currency;
    },
  },
});

export const {
  updateDonation,
  updateDonationEmail,
  updateDonationAmount,
  updateDonationWallet,
} = donationSlice.actions;

export default donationSlice.reducer;
