import axios from 'axios';
import { AuthTokens } from 'interfaces/AuthTokens.interface';

/**
 * Saves JWT user's authentication tokens in the local storage
 * @param tokens - User's JWT tokens
 */
export default function saveTokens(tokens: AuthTokens) {
  localStorage.setItem('reskue_authentication', JSON.stringify(tokens));
}

/**
 * @returns User's JWT authentication tokens located in local storage if they exist,
 * or null if they don't
 */
export function getTokens(): AuthTokens | null {
  const unparsedTokens: string | null = localStorage.getItem(
    'reskue_authentication',
  );

  if (unparsedTokens === null) return null;

  const tokens: AuthTokens = JSON.parse(unparsedTokens);
  return tokens;
}

export async function logUserFromToken(token: string) {
  const res = await axios.post(
    process.env.REACT_APP_BACKEND_URL + '/auth/user/access',
    { token },
  );
  if (res.data.access_token && res.data.refresh_token) {
    saveTokens({ ...res.data });
  }
}

export function isConnected(): boolean {
  return getTokens() ? true : false;
}

/**
 * Deletes user's JWT tokens from local storage
 */
export function removeTokens() {
  localStorage.removeItem('reskue_authentication');
}
