import { grey } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

export const appTheme = createTheme({
  palette: {
    primary: {
      light: grey[300],
      main: grey[900],
      dark: grey[900],
    },
    secondary: grey,
  },

  typography: {
    h1: {
      fontFamily: 'Syne',
      fontWeight: 500,
      fontSize: 35,
    },
    h6: {
      fontSize: 10,
    },
    fontSize: 17,
    // body2 : {
    //   fontSize: 15,
    // },
    fontWeightRegular: 500,
    fontWeightMedium: 700,
    fontFamily: ['Satoshi'].join(','),
  },
});

appTheme.typography.body1 = {
  [appTheme.breakpoints.up('xs')]: {
    fontSize: '1.3rem',
  },
  [appTheme.breakpoints.up('md')]: {
    fontSize: '1.4rem',
  },
};

appTheme.typography.body2 = {
  [appTheme.breakpoints.up('xs')]: {
    fontSize: '1.3rem',
  },
  [appTheme.breakpoints.up('md')]: {
    fontSize: '1.4rem',
  },
};
