import { useSelector } from 'react-redux';
import React, {
  Routes,
  Route,
  BrowserRouter as Router,
} from 'react-router-dom';

import Page404 from '../error_pages/page_404';
import Home from '../home/home';
import EditProfile from '../user/edit_profile/edit_profile';
import Login from '../authentification/ReskueLogin/login';
import Profile from '../user/profile';
import UserConfirm from '../user/user_confirm';
import UserRegistration from '../user/user_registration';
import Footer from '../../components/footer/footer';
import Navbar from '../../components/navbar/navbar';
import NgoList from '../ngo_page/ngo_list';
import NgoPage from '../ngo_page/ngo_page';
import UserUpdate from '../user/user_update';
import Session from 'interfaces/session.interface';
import { Store } from 'data/store';
import NftPage from '../NFT_page/nft_page';
import ForgotPassword from '../user/password_forms/forgot_password';
import ResetPassword from '../user/password_forms/reset_password';
import Aci from '../business_page/aci';
import AciNft from '../business_page/aci_nft';
import FAQ from '../faq_page/faq';
import Leaderboard from 'modules/leaderboard/component/leaderboard';
import PublicUserProfile from '../public-user/publicUser';
import Collections from '../collection_page/collections';
import CollectionPage from '../collection_page/collection_page';
import Artwork from '../artwork_page/artwork';
import ScrollToTop from './ScrollToTop';
import TheModal from '../payment_flow/modal/last_modal';
import { ToastContainer } from 'react-toastify';
import WalletMenu from '../navbar/wallet_menu';
import CollectionCreation from '../collection_creation/collectionCreation';
import EditCollec from '../user/artist/EditCollec';
import General from '../user/artist/Subtabs/general';

function Routing() {
  // Redux tools
  const session: Session = useSelector((state: Store) => state.session);

  function isRestricted(
    path: string,
    condition: boolean,
    element: JSX.Element,
    redirect: JSX.Element,
  ) {
    if (condition) {
      return <Route path={path} element={element} />;
    }
    return <Route path={path} element={redirect} />;
  }

  return (
    <Router>
      <ScrollToTop />
      <TheModal />
      <WalletMenu />
      <Navbar />
      <ToastContainer />
      <div id="content">
        <Routes>
          <Route path="*" element={<Page404 />} />
          <Route path="/" element={<Home />} />
          <Route path="/ngos" element={<NgoList />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/ngos/:ngo_name" element={<NgoPage />} />
          <Route
            path="/collections/:collection_name"
            element={<CollectionPage />}
          />
          <Route path="/ngos" element={<NgoList />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/ngos/:ngo_name" element={<NgoPage />} />
          <Route path="/nft/:donation_id" element={<NftPage />} />
          <Route path="/business/aci_partners" element={<Aci />} />
          <Route path="/nft/aci_partners" element={<AciNft />} />
          <Route path="/collections" element={<Collections />} />
          <Route
            path="/collections/:collection_name/:artwork_name"
            element={<Artwork />}
          />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/user/password/reset" element={<ResetPassword />} />
          <Route path="/user/:username" element={<PublicUserProfile />} />

          {isRestricted('/user/login', session.exists, <Profile />, <Login />)}
          {isRestricted(
            '/user/register',
            session.exists,
            <Profile />,
            <UserRegistration />,
          )}
          {isRestricted(
            '/user/confirm',
            session.exists,
            <Profile />,
            <UserConfirm />,
          )}
          {isRestricted(
            '/user/update',
            session.exists,
            <Profile />,
            <UserUpdate />,
          )}
          {isRestricted(
            'user/password/forgot',
            session.exists,
            <Profile />,
            <ForgotPassword />,
          )}
          {isRestricted('/user', session.exists, <Profile />, <Login />)}
          {isRestricted(
            '/user/edit',
            session.exists,
            <EditProfile />,
            <Login />,
          )}

          {isRestricted(
            '/create',
            session?.roles?.includes('artist'),
            <CollectionCreation />,
            <Home />,
          )}
          {isRestricted(
            '/user/edit/:collection_name',
            session?.roles?.includes('artist'),
            <General />,
            <Login />,
          )}
          {/* {isRestricted("/user/edit/:collection_name/general", session?.roles?.includes('artist'), <General/>, <Login />)} */}
          {isRestricted(
            '/user/edit/:collection_name/create',
            session?.roles?.includes('artist'),
            <EditCollec />,
            <Login />,
          )}
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default Routing;
