import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './revenueSplit.scss';

export default function RevenueSplit(split: any) {
  const { t } = useTranslation('common');
  return (
    <figure>
      <div className="y-axis"></div>
      <div className="graphic">
        <h3>{t('artworks.splitting')}</h3>
        <div className="row">
          {/* <h6>Revenue Splitting</h6> */}
          <div className="chart">
            <span
              className="block"
              title="Nonprofit"
              style={{ width: split.ngo + '%' }}
            >
              <span className="value">{split.ngo}%</span>
            </span>
            <span
              className="block"
              title="Artist"
              style={{ width: split.artist + '%' }}
            >
              <span className="value">{split.artist}%</span>
            </span>
            {/* <span
              className="block"
              title="Reskue"
              style={{ width: split.reskue + "%" }}
            >
              <span className="value">{split.reskue}%</span>
            </span> */}
          </div>
        </div>
      </div>
      <div className="x-axis">
        <ul className="legend">
          <li>{t('artworks.nonProfit')}</li>
          <li>{t('artworks.artist')}</li>
          {/* <li>Reskue</li> */}
        </ul>
      </div>
    </figure>
  );
}
