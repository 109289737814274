import { MouseEvent, useState } from 'react';
import { FaCircleNotch } from 'react-icons/fa';
import React from 'react';

interface IProps {
  content: string;
  // onClick: () => Promise<boolean>;
  onClick: any;
  disabled?: boolean;
}

export default function BlackButton(props: IProps) {
  /** Variables */
  const [isLoading, setIsLoading] = useState<boolean>(false);

  async function handlButtonClick(event: MouseEvent<HTMLButtonElement>) {
    event.preventDefault();

    /** Already loading */
    if (isLoading === true) {
      return;
    }

    /** Set as loading */
    setIsLoading(true);

    /** Check if still waiting */
    const actionStatus = await props.onClick();
    if (actionStatus === false) {
      setIsLoading(false);
    }
  }

  if (isLoading) {
    return (
      <button
        onClick={e => e.preventDefault()}
        className="black_button black_button_loading"
      >
        <span>
          <FaCircleNotch />
        </span>
      </button>
    );
  }
  return (
    <button
      onClick={handlButtonClick}
      className="black_button"
      disabled={props.disabled == null ? false : props.disabled}
    >
      {props.content}
    </button>
  );
}
