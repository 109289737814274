import { EInputStatusEnum } from 'helpers/status';
import React, { ChangeEvent, Dispatch, SetStateAction, useState } from 'react';

/** Custom password Hook */

interface IPassword {
  content: string;
  error: string;
  status: EInputStatusEnum;
}

export function usePassword(): [
  IPassword,
  Dispatch<SetStateAction<IPassword>>,
] {
  const [password, setPassword] = useState<IPassword>({
    content: '',
    error: '',
    status: EInputStatusEnum.Empty,
  });

  return [password, setPassword];
}

/** Custom password element */

interface IProps {
  password: IPassword;
  setPassword: Dispatch<SetStateAction<IPassword>>;
  creationForm?: boolean;
  label?: string;
}

export default function FormPassword({
  password,
  setPassword,
  creationForm = false,
  label = 'Password',
}: IProps) {
  function onPasswordChange(event: ChangeEvent<HTMLInputElement>) {
    const content: string = event.target.value;
    let error = '';
    let status: EInputStatusEnum = EInputStatusEnum.Valid;

    if (creationForm) {
      const passwordRegex = new RegExp(
        /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/,
      );

      if (passwordRegex.test(content) === false || content.length < 10) {
        error =
          'Password is too weak. between 10 characters, at least 1 uppercase, 1 number, 1 lowercase';
        status = EInputStatusEnum.Invalid;
      } else if (content.length > 30) {
        error = 'Password is too long, max 30 characters';
        status = EInputStatusEnum.Invalid;
      }
    }

    setPassword({ content, error, status });
  }

  return (
    <div className="input_container">
      <label htmlFor="password">{label}</label>
      <input
        type="password"
        onChange={onPasswordChange}
        value={password.content}
        placeholder="password"
        name="password"
      />
      <p className="error_text">{password.error}</p>
    </div>
  );
}
