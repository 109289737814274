import { Dispatch, AnyAction } from '@reduxjs/toolkit';
import axios, { AxiosResponse } from 'axios';
import default_avatar from 'images/ui/default_avatar.png';
import Session from 'interfaces/session.interface';
import { api } from 'api/reskueApi';
import { updateSession } from '../../../redux/session.slice';
import unsetCurrentSession from './unset_current_session';

interface iProps {
  session: Session;
  dispatch: Dispatch<AnyAction>;
}

export default async function useSetCurrentSession({
  session,
  dispatch,
}: iProps) {
  let userResponse: AxiosResponse<any, any>;

  /* User already connected */
  if (session.exists) return;

  try {
    userResponse = await api.get('/user');

    let hasId = false;
    const ret = await api.get('/user/identity');
    if (ret.data) hasId = true;

    /* set global session object */
    const avatar = userResponse.data.avatar
      ? userResponse.data.avatar?.url_medium
      : default_avatar;

    dispatch(
      updateSession({
        ...session,
        username: userResponse.data.username,
        email: userResponse.data.mail,
        avatar: avatar,
        exists: true,
        cover: userResponse.data.cover,
        description: userResponse.data.description,
        hasIdentity: hasId,
      }),
    );
  } catch (err: any) {
    console.error(err);
    if (err.status === 401) {
      unsetCurrentSession({ session, dispatch });
    }
  }
}
