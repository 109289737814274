import 'style/anchor.scss';
import './page_404.scss';
import React from 'react';
import { Link } from 'react-router-dom';

export default function Page404() {
  return (
    <section id="page_404">
      <p className="error_name">Page not found</p>
      <p className="error_code">Error 404</p>
      <Link className="black_link" to="/">
        return home
      </Link>
    </section>
  );
}
