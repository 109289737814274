import { NftCard } from 'interfaces/gallery_card.interface';
import React, { useEffect, useRef, useState } from 'react';
import './NftCard.scss';
import { useNavigate } from 'react-router-dom';
import NftCardShareButtons from './NftCardShareButtons/NftCardShareButtons';
import NftCardImage from './NftCardImage/NftCardImage';
import NftCardInfos from './NftCardInfos/NftCardInfos';
import { useIsTouchScreen } from 'helpers/customHooks/useCustomQueries';
import { FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import './NftCardPhoneShareUi/NftCardPhoneShareUi.scss';

interface Props {
  card: NftCard;
  shareable?: boolean;
}

function useOutsideEvent(ref: any, setAction: any) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setAction('');
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

function GalleryCard({ card, shareable = false }: Props) {
  /** Tools */
  const navigate = useNavigate();
  const touchScreen = useIsTouchScreen();

  /** Variables */
  const [nftCardClasses, setNftCardClasses] = useState<string>('');
  const nftCardRef = useRef(null);
  useOutsideEvent(nftCardRef, setNftCardClasses);

  /** Constants */
  // const twitter =
  //     "https://twitter.com/intent/tweet?text=I%20am%20supporting%20"
  //     + card.cause
  //     + "%20on%20"
  //     + process.env.REACT_APP_FRONTEND_URL + "/nft/" + card.id
  //     + "%0AWith%20my%20artwork%20"
  //     + card.name
  //     + "%20💙%0AJoin%20the%20reskue%20community%20and%20impact%20the%20world%20🌊%0A&via=reskueNFT";

  // const linkedin = "alpha.reskue.art/nft/" + card.id
  // const facebook = "alpha.reskue.art/nft/" + card.id
  // const email = ["alpha.reskue.art/nft/" + card.id, "Discover reskue, a new way to support NGOs through art and web3 💙", "I am supporting " + card.ngo + " working to " + card.cause + " with my unique NFT artwork: " + card.name]

  function navigateToNft() {
    if (card.id !== '') {
      navigate('/nft/' + card.id);
    }
  }

  function handleMouseEnterAndLeave() {
    if (touchScreen) return;

    if (nftCardClasses === '') {
      setNftCardClasses('show_regular_share_buttons');
    } else {
      setNftCardClasses('');
    }
  }

  const handleClick = (event: any) => {
    if (touchScreen) {
      setNftCardClasses('show_phone_share_buttons');
    } else {
      navigateToNft();
    }
  };

  return (
    <>
      <div
        ref={nftCardRef}
        onMouseEnter={handleMouseEnterAndLeave}
        onMouseLeave={handleMouseEnterAndLeave}
        className={'gallery_card ' + nftCardClasses}
      >
        <div className="clickable_nft_section" onClick={handleClick}>
          <NftCardImage card={card} />
          <NftCardInfos card={card} />
        </div>
        {/* {
                    shareable &&
                    <>
                        <NftCardShareButtons
                            twitter={twitter}
                            linkedin={linkedin}
                            facebook={facebook}
                            email={email}
                        />

                    </>
                }
                <div className="nft_card_phone_share_ui">
                    {
                        shareable &&
                        <div className="nft_card_phone_share_ui_section">
                            <h2>Share your NFT</h2>
                            <div className="nft_card_phone_share_ui_share_buttons">
                                <a target="blank" href={linkedin}><FaLinkedinIn className="primary_icon" /></a>
                                <a target="blank" href={twitter}><FaTwitter className="primary_icon" /></a>
                            </div>
                        </div>
                    }
                    <div className="nft_card_phone_share_ui_section">
                        <Link className="white_link" to={"/nft/" + card.id}>view nft</Link>
                        <a className="white_link" target="_blank" rel="noreferrer" href={card.opensea}>Opensea</a>
                        <a className="white_link" target="_blank" rel="noreferrer" href={card.polygonScan}>PolygonScan</a>
                    </div>
                </div> */}
      </div>
    </>
  );
}

export default GalleryCard;
