import React, { useState, useEffect } from 'react';
import 'style/global.scss';
import './navbar.scss';
import './desktop_navbar.scss';
import ProfileNav from './elements/profile_nav';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'data/store';
import ListPages from './content';
import { useTranslation } from 'react-i18next';
import { languageToEnglish, languageToFrench } from 'data/language.slice';
import ReactFlagsSelect from 'react-flags-select';
import GetNgos from 'helpers/api_calls/getNgos';
import { MainSearchbar } from './mainSearchbar';
import { Link } from 'react-router-dom';
import { updateSession } from 'data/session.slice';
import logo from 'images/logo/RVB_BLACK.png';

function PrimaryNavbar() {
  /** Global data */
  const { session } = useSelector((state: Store) => state);

  /** Tools */
  const dispatch = useDispatch();

  /** Translation */
  const { t, i18n } = useTranslation('common');
  // const the_language = localStorage.getItem("reskue_language");

  const [selected, setSelected] = useState(
    localStorage.getItem('reskue_language') || '',
  );
  const onSelect = (code: string): void => setSelected(code);

  useEffect(() => {
    switch (selected) {
      case 'US':
        dispatch(languageToEnglish());
        i18n.changeLanguage('en');
        localStorage.setItem('reskue_language', 'US');
        break;
      case 'FR':
        dispatch(languageToFrench());
        /** Fetch NGOs data */
        GetNgos({ dispatch });
        i18n.changeLanguage('fr');
        localStorage.setItem('reskue_language', 'FR');
        break;
      default:
        i18n.changeLanguage('fr');
        localStorage.setItem('reskue_language', 'FR');
        setSelected('FR');
        break;
    }
    dispatch(
      updateSession({
        ...session,
        language: selected,
      }),
    );
  }, [selected]);

  return (
    <div id="top_nav">
      <a href="/" className="logo">
        <img src={logo} alt="Reskue logo" />
      </a>
      <ul id="nav_links">
        <ListPages />
      </ul>
      <div id="nav_searchbar" className="nav_searchbar">
        <MainSearchbar />
      </div>
      <div id="nav_auth">
        <ProfileNav />
        {session.exists === false && (
          <Link to="/user/login">
            <div className="nav_login">{t('header.navbar.login')}</div>
          </Link>
        )}
        {session.exists === false && (
          <Link to="/user/register">
            <div className="nav_register">{t('header.navbar.signup')}</div>
          </Link>
        )}
        <a>
          <ReactFlagsSelect
            selected={selected}
            onSelect={code => setSelected(code)}
            countries={['US', 'FR']}
            customLabels={{ US: 'EN', FR: 'FR' }}
            className="menu-flags"
          />
        </a>
      </div>
    </div>
  );
}

export default PrimaryNavbar;
