import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Store } from 'data/store';
import { useDispatch, useSelector } from 'react-redux';
import algoliasearch from 'algoliasearch';
import { Highlight, Hits, InstantSearch } from 'react-instantsearch-hooks-web';
import { ImgLoad } from 'utils/ImgLoader/img_loader';
import './ngo_selector.scss';
import BlackButton from 'helpers/elements/black_button';
import { updateModal } from 'data/modal.slice';
import { toast } from 'react-toastify';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Hit = ({ hit }) => {
  const dispatch = useDispatch();
  const elems = useSelector((state: Store) => state.modal);
  const ngos = useSelector((state: any) => state.ngoList);

  function onSelectNgo(ngo_id: any, the_name: any) {
    const ngo = ngos.find(({ ngo_name }: any) => ngo_name === the_name);
    dispatch(
      updateModal({
        ...elems,
        // ngo: {ngo_id, ngo_name }
        ngo: ngo,
      }),
    );
  }
  return (
    <div onClick={() => onSelectNgo(hit.objectID, hit.name)}>
      <article>
        <div tabIndex={0} className="ngo_item active">
          <ImgLoad
            src={hit.logo}
            width={100}
            height={100}
            alt={hit.name}
            className="theimg"
          />
          {/* <p>{hit.categories[0]}</p> */}
          <Tooltip title={hit.name}>
            <div className="Title">
              <Highlight attribute="name" hit={hit} />
            </div>
          </Tooltip>
          <div className="bottom_section">
            <div className="row">
              <div className="column1">
                {/* <ArtistButton type="card" name="Tom" /> */}
              </div>
              <div className="column2">
                {/* <PayOwnerButton
                price={"sold" === hit.sold ? "" : hit.price[0].value} 
                image={hit.image}
                name={hit.name}
                type={"Sold" === hit.sold ? "owner" : ""}
                user_name={""}
              /> */}
              </div>
            </div>
          </div>
        </div>
      </article>
    </div>
  );
};

Hit.propTypes = {
  hit: PropTypes.object,
  props: '',
};

export default function NgoSelector() {
  const dispatch = useDispatch();
  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_PUBLIC_KEY,
  );
  const [error, setError] = useState('');
  const elems = useSelector((state: Store) => state.modal);
  const { t } = useTranslation('common');

  function handleClick() {
    if (elems.ngo === null) {
      setError(t('listings.errors.noNonProfitSelected'));
      return false;
    } else {
      dispatch(
        updateModal({
          ...elems,
          ready: true,
        }),
      );

      toast.info(
        `${t('listings.infos.youHaveChosenToSupport')} ${
          elems.ngo.ngo_name
        } 💙`,
        {
          position: toast.POSITION.BOTTOM_LEFT,
        },
      );
      return true;
    }
  }
  return (
    <div>
      <div className="ngo_wrapper">
        <div className="ngo_container">
          <InstantSearch searchClient={searchClient} indexName="Ngos">
            <Hits hitComponent={Hit} />
          </InstantSearch>
        </div>
      </div>
      <div className="butt">
        <p className="login_error_text">{error}</p>
        <BlackButton content="Reskue" onClick={handleClick} />
      </div>
    </div>
  );
}
