import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.scss';
import { Provider } from 'react-redux';
import store from './redux/store';
import { HelmetProvider } from 'react-helmet-async';

if (!process.env.REACT_APP_BACKEND_URL) {
  throw new Error(`process.env.REACT_APP_BACKEND_URL is not set`);
}

const helmetContext = {};

ReactDOM.render(
  <HelmetProvider context={helmetContext}>
    <Provider store={store}>
      <App />
    </Provider>
  </HelmetProvider>,
  document.getElementById('root'),
);
