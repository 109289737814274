import React from 'react';
import { NftCard } from 'interfaces/gallery_card.interface';
import goldBadge from 'images/ui/badges/gold.png';
import blueBadge from 'images/ui/badges/blue.png';
import bronzeBadge from 'images/ui/badges/bronze.png';
import silverBadge from 'images/ui/badges/silver.png';
import './NftCardInfos.scss';

interface Props {
  card: NftCard;
}

export default function NftCardInfos({ card }: Props) {
  function getNftBadge(): string {
    if (card.donation >= 20 && card.donation < 50) {
      return bronzeBadge;
    }
    if (card.donation >= 50 && card.donation < 100) {
      return silverBadge;
    }
    if (card.donation >= 100 && card.donation < 500) {
      return goldBadge;
    }
    if (card.donation >= 500) {
      return blueBadge;
    }
    return '';
  }

  return (
    <>
      <div className={'gallery_card_infos'}>
        <p className="gallery_card_name">{card.name}</p>
        <p className="gallery_card_cause">{card.cause}</p>
        <p className="gallery_card_ngo">{card.ngo}</p>
        <p className="gallery_card_donation_amount">{card.donation} €</p>
        <div className="gallery_card_donation_addr">
          <p>{card.address === '' ? 'saved by Reskue' : card.address}</p>
          <img className="gallery_card_badge" src={getNftBadge()} alt="" />
        </div>
      </div>
    </>
  );
}
