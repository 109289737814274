import { AnyAction, Dispatch } from '@reduxjs/toolkit';
import axios from 'axios';
import { addNgo } from '../../../redux/ngoList.slice';

const the_language = localStorage.getItem('reskue_language');

interface IProps {
  dispatch: Dispatch<AnyAction>;
}

let URL = '/ngos/many?lang=fr';

export default function GetNgos({ dispatch }: IProps) {
  if (the_language == 'US') URL = '/ngos/many?lang=en';
  if (process.env.REACT_APP_BACKEND_URL) {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + URL)
      .then(res => {
        dispatch(addNgo(res.data.items));
      })
      .catch(error => {
        if (error.message) {
          console.error(error.message);
        }
        if (error.method) {
          console.error(error.method);
        }
        if (error.request) {
          console.error(error.request);
        }
      });
  }
}
