import axios from 'axios';
import { LoginResponse } from './interfaces/ApiResponse';
import LoginProps from './interfaces/login/LoginProps';
import RegisterProps from './interfaces/RegisterProps';
import saveTokens, { removeTokens } from './tokens.service';

const API_URL = process.env.REACT_APP_BACKEND_URL;

/**
 * Register a new user
 * @param username - User's username
 * @param email - User's email
 * @param password - User's password
 * @returns nothing, sends an confirmaction email
 */
export async function register({ username, email, password }: RegisterProps) {
  return await axios.post(API_URL + '/user', {
    username,
    email,
    password,
  });
}

/**
 * Login a user
 * @param username - User's name
 * @param password - User's password
 * @returns User's access and refresh JWT tokens
 */
export async function login({
  username,
  password,
}: LoginProps): Promise<LoginResponse> {
  try {
    const response = await axios.post(API_URL + '/auth/user/login', {
      username,
      password,
    });

    return { data: response.data, error: '' };
  } catch (error: any) {
    if (error.response.status === 401) {
      return { data: null, error: 'Invalid username or password' };
    } else {
      return { data: null, error: error.response.data.message };
    }
  }
}

/**
 * Logout a user and removes its JWT tokens from local storage
 */
export async function logout() {
  removeTokens();
  /** TODO : add api call to /auth/user/logout */
}

/**
 * Login user and save tokens in local storage
 * @param props.username User's name
 * @param props.password User's password
 */
export async function loginAndSaveTokens({
  username,
  password,
}: LoginProps): Promise<LoginResponse> {
  const response: LoginResponse = await login({ username, password });
  if (response.data) {
    saveTokens(response.data);
  }
  return response;
}
