import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';

import { FacebookShareButton, FacebookIcon } from 'react-share';

const shareButtonFacebook = props => {
  const { t } = useTranslation('common');

  const shareUrl = window.location.href;
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };
  return (
    <MenuItem onClick={handleClose}>
      <FacebookShareButton
        url={shareUrl}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        <FacebookIcon
          size={32}
          round
          bgStyle={{ fill: 'transparent' }}
          iconFillColor="#365899"
        />
        {t('shareButton.shareOnFacebook')}
      </FacebookShareButton>
    </MenuItem>
  );
};

export default shareButtonFacebook;
