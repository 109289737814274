import React, { useEffect, useState } from 'react';
import { modalActive } from 'data/modal.slice';
import BlackButton from 'helpers/elements/black_button';
import { useDispatch } from 'react-redux';
import Session from 'interfaces/session.interface';
import { Store } from 'data/store';
import './right_section.scss';
import { useSelector } from 'react-redux';
import { api } from 'api/reskueApi';
import { useTranslation } from 'react-i18next';
import EntityButton from '../buttons/entity_button';

export default function RightSection(props: any) {
  /** Contexts */
  const session: Session = useSelector((state: Store) => state.session);
  const { t } = useTranslation('common');

  const dispatch = useDispatch();
  const handleClick = async () => {
    dispatch(
      modalActive({
        ...props,
        price: props.listing.reserve,
        listing: props.listing,
        name: props.artwork_name,
        collection: props?.collection || props?.collection_name,
        artist: props?.collection.artist,
        ngo: props?.collection.ngo,
      }),
    );
    return false;
  };

  const [rate, setRate] = useState<number>();

  async function getRate() {
    try {
      const resp = await api.get('/money/exchangeRate', {
        params: {
          fiat: 'EUR',
        },
      });
      setRate(resp.data);
    } catch (err: any) {
      console.error(err.response?.data?.message);
    }
  }
  useEffect(() => {
    getRate();
  }, []);

  function handleDeal(event: any, type: string) {
    event.stopPropagation();
    dispatch(
      modalActive({
        ...props,
        type: type,
        listing: props.listing,
      }),
    );
  }

  if (props.owner?.username === session?.username) {
    if (props.listing.listingType === 'NONE') {
      return (
        <div className="right_comp">
          <div className="title">{t('artworks.notInSale')}</div>

          <button
            className="btn btn-gradient-border"
            onClick={(e: any) => handleDeal(e, 'sell')}
          >
            {t('artworks.sell')}
          </button>
        </div>
      );
    }

    if (props.listing.listingType === 'DIRECT') {
      return (
        <div className="right_comp">
          <div className="title">
            {t('artworks.inSaleAt')}
            {props.listing?.reserve} Matic.
          </div>

          <button
            className="btn btn-gradient-border"
            onClick={(e: any) => handleDeal(e, 'unlist')}
          >
            {t('artworks.cancel')}
          </button>
        </div>
      );
    }
  }

  if (props.listing.reserve != null) {
    return (
      <div className="right_comp">
        {/* <Modal isShowing={isShowing} hide={toggle} data={props} /> */}
        <div className="title">{t('artworks.collect')}</div>
        <div className="price">{props.listing.reserve}Matic</div>
        <div>
          ≈
          {Math.round(parseFloat(props.listing?.reserve) * rate * 10) / 10 || 0}
          €
        </div>
        <BlackButton content="Collect" onClick={handleClick} />
      </div>
    );
  } else {
    return (
      <div className="right_comp">
        {/* <div className="title">{t("artworks.owner")}</div> */}
        <div className="price">
          {props.owner?.username != props.collection.artist.username ? (
            <EntityButton
              title={t('artworks.owner')}
              url={props?.owner?.avatar?.url}
              username={props?.owner?.username}
            />
          ) : null}
        </div>
      </div>
    );
  }
}
