import axios from 'axios';
import React, { ChangeEvent, useEffect, useState } from 'react';
import './contact_section.scss';

enum _mailStatus {
  OnGoing,
  Loading,
  Fail,
  Success,
}

interface iProps {
  setMailStatus: (status: _mailStatus) => void;
}

function ContactForm({ setMailStatus }: iProps) {
  const [validForm, setValidForm] = useState(false);
  const [mailContent, setMailContent] = useState({
    sender: '',
    subject: '',
    body: '',
  });

  function handleTextChange(
    e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
  ) {
    const value = e.target.value;
    setMailContent({
      ...mailContent,
      [e.target.name]: value,
    });
  }

  useEffect(() => {
    if (
      mailContent.sender === '' ||
      mailContent.subject === '' ||
      mailContent.body === ''
    ) {
      setValidForm(false);
    } else {
      setValidForm(true);
    }
  }, [mailContent]);

  function handleContactSubmit(e: any) {
    e.preventDefault();

    setMailStatus(_mailStatus.Loading);
    if (process.env.REACT_APP_BACKEND_URL) {
      axios
        .put(process.env.REACT_APP_BACKEND_URL + '/contact', {
          sender: mailContent.sender,
          body: mailContent.body,
          subject: mailContent.subject,
        })
        .then(res => {
          setMailStatus(_mailStatus.Success);
        })
        .catch(err => {
          setMailStatus(_mailStatus.Fail);
        });
    }
  }

  return (
    <form onSubmit={handleContactSubmit}>
      <div className="input_container">
        <label htmlFor="contact_user_mail">Mail</label>
        <input
          type="text"
          id="contact_user_mail"
          name="sender"
          placeholder="mail@example.com"
          onChange={handleTextChange}
          value={mailContent.sender}
        />
        <div className="error_text"></div>
      </div>
      <div className="input_container">
        <label htmlFor="contact_message_object">Object</label>
        <input
          type="text"
          id="contact_message_object"
          name="subject"
          placeholder="Message object"
          onChange={handleTextChange}
          value={mailContent.subject}
        />
        <div className="error_text"></div>
      </div>
      <div className="textarea_container">
        <label htmlFor="contact_user_mail">Message</label>
        <textarea
          id="contact_message_content"
          name="body"
          placeholder="Message content"
          onChange={handleTextChange}
          value={mailContent.body}
        />
        <div className="error_text"></div>
      </div>
      <button className="black_button" disabled={!validForm}>
        Send
      </button>
    </form>
  );
}

export default ContactForm;
