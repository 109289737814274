import React from 'react';

export function MailSuccess() {
  return (
    <div className="form_status">
      <div className="status_text" style={{ backgroundColor: '#78b13f' }}>
        {' '}
        Message sent successfully
      </div>
    </div>
  );
}

export function MailFail() {
  return (
    <div className="form_status">
      <div className="status_text" style={{ backgroundColor: 'red' }}>
        {' '}
        Message failed to send
      </div>
    </div>
  );
}

export function MailLoading() {
  return (
    <div className="form_status">
      <div className="status_text" style={{ backgroundColor: 'blue' }}>
        Message is being sent
      </div>
    </div>
  );
}
