import axios from 'axios';
import { useIsMobile } from 'helpers/customHooks/useCustomQueries';
import { Icollection } from 'interfaces/Collection.interface';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CollectionCard from './collection_card';
import './collections.scss';
import { Helmet } from 'react-helmet-async';
import LazyLoad from 'react-lazyload';
import { api } from 'api/reskueApi';

export default function Collections() {
  /** Tools */
  const isMobile = useIsMobile();
  const navigate = useNavigate();

  const [collections, setCollection] = useState<Icollection[]>([]);
  const [rate, setRate] = useState<number>();

  async function getRate() {
    try {
      const resp = await api.get('/money/exchangeRate', {
        params: {
          fiat: 'EUR',
        },
      });
      setRate(resp.data);
    } catch (err: any) {
      console.error(err.response?.data?.message);
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_BACKEND_URL) {
      axios
        .get(process.env.REACT_APP_BACKEND_URL + `/gallery/collection/many`, {
          params: { relations: ['ngo', 'artist', 'artworks'] },
        })
        .then((res: any) => {
          setCollection(Array.from(res.data.items));
          // setCollection({...collections, size: res.data.items.artworks.length})
        })
        .catch(err => {
          console.error(err);
        });
      getRate();
    }
  }, []);

  return (
    <LazyLoad height={200}>
      <div id="collections_wrapper">
        <Helmet>
          <title>Reskue | Collections</title>
          <meta name="title" content="Collections available on Reskue" />
          <meta
            name="description"
            content="You can find here all the collections present on Reskue. They can be either generic, meaning they support all non-profit organizations, or specific for one of them"
          />
        </Helmet>
        <h1>Collections</h1>
        <div className="collections_container">
          {collections
            .filter(collection => collection.artworks.length >= 1)
            .map(collection => (
              <CollectionCard
                key={collection.id}
                height={600}
                image_url={collection.image?.url_medium}
                collection_name={collection.collection_name}
                description={collection.description}
                released={collection.released}
                size={collection.size}
                ngo={collection.ngo}
                artist={collection?.artist}
                rate={rate}

                // items={collection.artworks}
              />
            ))}
        </div>
      </div>
    </LazyLoad>
  );
}
