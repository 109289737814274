import React from 'react';
import './howItWork.scss';
import hiw_icon1 from 'images/design/hiw_icon1.png';
import hiw_icon2 from 'images/design/hiw_icon2.png';
import hiw_icon3 from 'images/design/hiw_icon3.png';
import hiw_icon4 from 'images/design/hiw_icon4.png';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const HowItWorks = () => {
  const { t } = useTranslation('common');

  return (
    <section id="how_it_works_section">
      <h2>{t('howItWorks.title')}</h2>
      <div id="how_it_works">
        <div className="hiw_step">
          <div className="hiw_icon">
            <img
              src={hiw_icon3}
              alt="list icon representing the list of the causes you can reskue"
            />
          </div>
          <p className="hiw_step_title">{t('howItWorks.1.title')}</p>
          <p className="hiw_step_text">{t('howItWorks.1.description')}</p>
        </div>
        <div className="hiw_step">
          <div className="hiw_icon">
            <img src={hiw_icon4} alt="image icon for artwork choice" />
          </div>
          <p className="hiw_step_title">{t('howItWorks.2.title')}</p>
          <p className="hiw_step_text">{t('howItWorks.2.description')}</p>
        </div>
        <div className="hiw_step">
          <div className="hiw_icon">
            <img src={hiw_icon2} alt="heart icon for donation" />
          </div>
          <p className="hiw_step_title">{t('howItWorks.3.title')}</p>
          <p className="hiw_step_text">{t('howItWorks.3.description')}</p>
        </div>
        <div className="hiw_step">
          <div className="hiw_icon">
            <img
              src={hiw_icon1}
              alt="tick icon to show validated process and nft reward after donation"
            />
          </div>
          <p className="hiw_step_title">{t('howItWorks.4.title')}</p>
          <p className="hiw_step_text">{t('howItWorks.4.description')}</p>
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
