import React, { useState } from 'react';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import { ShimmerCircularImage } from 'react-shimmer-effects';

interface IImgLoad {
  src: string;
  width?: number;
  height?: number;
  alt?: string;
  className?: string;
  onClick?: any;
  circle?: boolean;
}

export const ImgLoad = ({
  src,
  width,
  height,
  alt,
  className,
  onClick,
  circle,
}: IImgLoad) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div>
      {loaded ? null : circle ? (
        <ShimmerCircularImage size={width} />
      ) : (
        <ShimmerThumbnail
          width={width}
          height={height}
          className={className}
          rounded
        />
      )}
      <img
        className={className}
        style={loaded ? {} : { display: 'none' }}
        src={src}
        onLoad={() => setLoaded(true)}
        alt={alt}
        onClick={onClick}
      />
    </div>
  );
};
