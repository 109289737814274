import React from 'react';
import { Backdrop, CircularProgress, FormControl } from '@mui/material';
import { Box } from '@mui/system';
import { modalUnactive } from 'data/modal.slice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { api } from 'api/reskueApi';
import { useTranslation } from 'react-i18next';

export default function Unlist(props: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  // Toast
  const toastId = React.useRef(null);
  const notify = () =>
    (toastId.current = toast('Unlist starting...', {
      autoClose: false,
      position: toast.POSITION.BOTTOM_LEFT,
    }));
  const updateSucc = (props: any) =>
    toast.update(toastId.current, {
      render: props,
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });
  const updateFail = err =>
    toast.update(toastId.current, {
      render: err?.response?.data?.message,
      type: toast.TYPE.ERROR,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

  // loader
  const [open, setOpen] = React.useState(false);

  // const [password, setPassword] = useState<string>(null)

  const handleLeave = () => {
    dispatch(modalUnactive(null));
  };

  // TODO: if auction : check if a bid is in process

  async function handleUnlist() {
    // if (password === null) {
    //     toast.error(`Password missing!`, {
    //         position: toast.POSITION.BOTTOM_LEFT
    //     });
    //     return;
    // }
    setOpen(true);
    notify();
    try {
      await api.post(
        process.env.REACT_APP_BACKEND_URL + '/marketplace/cancel',
        {
          id: props.modal.listing.id,
        },
        {
          headers: {
            // "X-PASSWORD": password,
            'Access-Control-Allow-Origin': '*',
          },
        },
      );
      updateSucc(t('listings.infos.listingCanceled'));
      handleLeave();
      window.location.reload();
    } catch (err: any) {
      updateFail(err);
      console.error(err);
    }
    setOpen(false);
  }
  return (
    <div className="modal-overlay">
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="modal-wrapper">
        <div className="modal">
          <div className="modal-container">
            <div className="modal_row">
              <div className="col1">
                <div className="head_modal">
                  <h1>{t('listings.confirmCancelListing')}</h1>
                  <span className="modal-close" onClick={handleLeave}>
                    &#10005;
                  </span>
                </div>
                <div>
                  <div>
                    {props?.modal?.listing.listingType === 'DIRECT'
                      ? t('listings.listedAsDirectSale')
                      : t('listings.listedAsAuctionSale')}
                    {props.modal?.listing?.reserve} Matic
                  </div>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <FormControl fullWidth>
                      {/* <TextField
                                        margin="normal"
                                        id="outlined-password-input"
                                        label="Password"
                                        type="password"
                                        InputLabelProps={{ shrink: true }}
                                        autoComplete="current-password"
                                        onChange={e => setPassword(e.target.value)}
                                    /> */}
                      <button
                        className="btn btn-gradient-border"
                        onClick={handleUnlist}
                      >
                        {t('listings.unlist')}
                      </button>
                    </FormControl>
                  </Box>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
