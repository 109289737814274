import React, { useRef, useState } from 'react';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import './edit_profile.scss';
import BlackButton from 'helpers/elements/black_button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Session from 'interfaces/session.interface';
import { Store } from 'data/store';
import { api } from 'api/reskueApi';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Grid, TextField } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { styled } from '@mui/material/styles';
import AvatarUpdate from '../avatar_update';
import UserInfo from './UserInfo/user_info';
import { Link } from 'react-router-dom';
import CollectionList from '../artist/CollectionList';
import IdentityInfo from './identity_info';
import { useTranslation } from 'react-i18next';

export default function EditProfile() {
  /** Contexts */
  const session: Session = useSelector((state: Store) => state.session);

  /** Hooks */
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /** Variables */
  const [value, setValue] = React.useState(0);
  const [log, setLog] = useState('');

  const [openModal, setOpenModal] = useState<boolean>(false);

  const inputEm: any = useRef();
  const usernameRef: any = useRef();
  const { t } = useTranslation('common');

  /** MUI */
  const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
  }));

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  async function updateMail() {
    if (inputEm.current.value != '') {
      try {
        await api.patch('/user/mail', { mail: inputEm.current.value });
        setLog(t('user.edit.mail.checkMailBox'));
        return false;
      } catch (err: any) {
        setLog(err.response?.data?.message);
      }
    }
    return false;
  }

  return (
    <section id="edit_profile_section">
      <Box
        sx={{
          bgcolor: 'background.paper',
          display: 'flex',
          gap: { xs: '0.5rem', md: '3rem' },
        }}
      >
        <Tabs
          orientation="vertical"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderRight: 1, borderColor: 'divider', pt: '2rem' }}
        >
          <Tab
            sx={{
              fontSize: { xs: '12px', md: '16px', lg: '18px' },
              padding: { xs: '0.2rem', md: '2rem' },
              textTransform: 'none',
              justifyContent: 'left',
            }}
            label={t('user.edit.profile.title')}
            {...a11yProps(0)}
            icon={<AccountCircleIcon />}
            iconPosition="start"
          />

          {session.roles.includes('artist') === true ? (
            <Tab
              sx={{
                fontSize: { xs: '12px', md: '16px', lg: '18px' },
                padding: { xs: '0.2rem', md: '2rem' },
                textTransform: 'none',
                justifyContent: 'left',
              }}
              label={t('user.edit.collections.title')}
              {...a11yProps(1)}
              icon={<ColorLensIcon />}
              iconPosition="start"
            />
          ) : null}
          <Tab
            sx={{
              fontSize: { xs: '12px', md: '16px', lg: '18px' },
              padding: { xs: '0.2rem', md: '2rem' },
              textTransform: 'none',
              justifyContent: 'left',
            }}
            label={t('user.edit.settings.title')}
            {...a11yProps(2)}
            icon={<SettingsIcon />}
            iconPosition="start"
          />
          <Tab
            sx={{
              fontSize: { xs: '12px', md: '16px', lg: '18px' },
              padding: { xs: '0.2rem', md: '2rem' },
              textTransform: 'none',
              justifyContent: 'left',
            }}
            label={t('user.edit.identity.title')}
            {...a11yProps(3)}
            icon={<ReceiptLongIcon />}
            iconPosition="start"
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <h1>{t('user.edit.profile.title')}</h1>

          <div id="profile_update">
            <Grid container spacing={2}>
              <Grid xs={12} md={6}>
                <Item>
                  <UserInfo />
                </Item>
              </Grid>
              <Grid xs={12} md={6}>
                <Item>
                  <AvatarUpdate />
                  <Box sx={{ textAlign: 'center' }}>
                    {session.roles.includes('artist') === false ? (
                      <a
                        target="blank"
                        href="https://docs.google.com/forms/d/e/1FAIpQLScyAQ-RIBpq3S-XPS3wpVyCxZU9J6yV4hdiH2NLuQS3_BH_cg/viewform?usp=sf_link"
                      >
                        <button className="btn btn-gradient-border btn-glow">
                          {t('user.edit.profile.artistSubmit')}
                        </button>
                      </a>
                    ) : null}
                  </Box>
                </Item>
              </Grid>
            </Grid>
          </div>
        </TabPanel>
        {session.roles.includes('artist') === true ? (
          <TabPanel value={value} index={1}>
            <h1>{t('user.edit.collections.title')}</h1>

            <Link to={'/create'}>
              <button className="btn btn-gradient-border btn-glow">
                {t('user.edit.collections.create')}
              </button>
            </Link>
            <CollectionList username={session.username} dispatch={dispatch} />
          </TabPanel>
        ) : null}
        <TabPanel
          value={value}
          index={session.roles.includes('artist') === true ? 2 : 1}
        >
          <Box sx={{ width: { xs: '100%', sm: '50%' } }}>
            <h1>{t('user.edit.settings.title')}</h1>
            <TextField
              fullWidth
              margin="normal"
              id="outlined-required"
              label="Email"
              inputRef={inputEm}
              InputLabelProps={{ shrink: true }}
            />
            <BlackButton
              content={t('user.edit.mail.update')}
              onClick={updateMail}
            />
          </Box>
          <div className="error_text">{log}</div>
        </TabPanel>
        <TabPanel
          value={value}
          index={session.roles.includes('artist') === true ? 3 : 2}
        >
          <IdentityInfo />
        </TabPanel>
      </Box>
    </section>
  );
}
