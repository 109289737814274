import React, { useState } from 'react';
import './landing_section.scss';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import 'style/button.scss';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Caroussel from '../caroussel/caroussel';
import BlackButton from 'helpers/elements/black_button';
import { FaRandom, FaRedo } from 'react-icons/fa';
import { Box, Tooltip } from '@mui/material';
import Session from 'interfaces/session.interface';
import { useSelector } from 'react-redux';
import { Store } from 'data/store';

function LandingSection() {
  const { t } = useTranslation('common');
  const [setclick, setHandleClick] = useState(null);
  const navigate = useNavigate();

  /** Global Data */
  const session: Session = useSelector((state: Store) => state.session);

  function handleNGOs() {
    navigate('/ngos');
  }
  function handleCollec() {
    navigate('/collections');
  }

  return (
    <div className="section_wrapper">
      <div className="the_layer">
        <div className="section_col">
          <div>
            {session.language === 'US' && (
              <h1>
                {t('landingSection.title2')}
                <br />
                {t('landingSection.title')}
              </h1>
            )}
            {session.language === 'FR' && (
              <h1>
                {t('landingSection.title')} {t('landingSection.title2')}
              </h1>
            )}
          </div>
          <div className="subtitle">{t('landingSection.subtitle')}</div>
          {/* <div className="redo" onClick={setHandleClick}> */}
          {/* <Tooltip title="Shuffle artworks">
              <AutorenewIcon fontSize="large" />
            </Tooltip> */}
          {/* </div> */}
          <div className="section_but">
            <BlackButton
              content={t('landingSection.button1')}
              onClick={handleCollec}
            />
            <BlackButton
              content={t('landingSection.button2')}
              onClick={handleNGOs}
            />
          </div>
        </div>
        <div className="section_col2">
          <Caroussel setClick={setclick} />
        </div>
      </div>
    </div>
  );
}

export default LandingSection;
