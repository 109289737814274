import React, { useEffect, useState } from 'react';
import { updatemenu } from 'data/menu.slice';
import { Store } from 'data/store';
import { FaExchangeAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { api } from '../../app/api/reskueApi';
import './input_funds.scss';

export default function InputFunds(props: any) {
  // TODO: find a way to display € change when modal is called from purchase modal
  const [value, setValue] = useState<number>(props?.value ?? 0);
  const [equiv, setEquiv] = useState<any>(
    props?.value ? (props.value * props?.rate).toFixed(2) : null,
  );
  const [swich, setSwich] = useState<boolean>(false);
  const [rate, setRate] = useState<number>();
  const dispatch = useDispatch();

  async function getRate() {
    const resp = await api.get('/money/exchangeRate', {
      params: {
        fiat: 'EUR',
      },
    });
    setRate(resp.data);
  }

  useEffect(() => {
    getRate();
  }, []);

  useEffect(() => {
    let val;
    if (swich) {
      val = ((value * 1) / rate).toFixed(2);
      setEquiv(val);
    } else {
      setEquiv((value * rate).toFixed(2));
      val = value;
    }
    dispatch(
      updatemenu({
        value: val,
      }),
    );
  }, [props?.value, value, swich]);

  return (
    <div className="input_funds_wrapper">
      <div className="swicher" onClick={() => setSwich(!swich)}>
        <FaExchangeAlt />
      </div>
      <div className="line">
        <div className="currency">{swich ? '€' : 'MATIC'}</div>
        <input
          type="number"
          placeholder={'0'}
          id="quantity"
          name="quantity"
          onChange={event => setValue(parseFloat(event.target.value))}
          value={value}
          min="5"
        ></input>
      </div>
      <div className="input_equiv">
        ≈ {isNaN(equiv) ? '0' : equiv} {swich ? 'MATIC' : '€'}
      </div>
    </div>
  );
}
