// import React, { useEffect, useRef, useState } from 'react';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import 'style/global.scss';
import './navbar_mobile.scss';
import hamburger from 'images/ui/menu_bars_black.png';
import cross from 'images/ui/close_menu_black.png';
import { Link } from 'react-router-dom';
import Session from 'interfaces/session.interface';
import { Store } from 'data/store';
import ListPages from './content';
import { logout } from 'services/auth.service';
import ReactFlagsSelect from 'react-flags-select';
import { languageToEnglish, languageToFrench } from 'data/language.slice';
import { useDispatch, useSelector } from 'react-redux';
import GetNgos from 'helpers/api_calls/getNgos';
import { useTranslation } from 'react-i18next';
import { MainSearchbar } from './mainSearchbar';
import { menuActive } from 'data/menu.slice';
import { FaWallet } from 'react-icons/fa';
import logo from 'images/logo/RVB_BLACK.png';

function SecondaryNavbar() {
  const dispatch = useDispatch();

  /** Contexts */
  const session: Session = useSelector((state: Store) => state.session);

  const [isOpen, setIsOpen] = useState(false);
  const [retractableState, setRetractableState] = useState('closed');
  const [buttonContent, setButtonContent] = useState(
    <img src={hamburger} className="hamburger" alt="hamburger_icon" />,
  );

  const content = document.getElementById('content');
  const footer = document.querySelector('footer');

  const the_language = localStorage.getItem('reskue_language');
  const { t, i18n } = useTranslation('common');

  const [selected, setSelected] = useState(
    localStorage.getItem('reskue_language') || '',
  );
  const onSelect = (code: string): void => setSelected(code);

  const handleWalletMenu = async () => {
    dispatch(
      menuActive({
        menu: 'ok',
      }),
    );
  };

  useEffect(() => {
    switch (selected) {
      case 'US':
        dispatch(languageToEnglish());
        GetNgos({ dispatch });
        i18n.changeLanguage('en');
        localStorage.setItem('reskue_language', 'US');
        break;
      case 'FR':
        dispatch(languageToFrench());
        GetNgos({ dispatch });
        i18n.changeLanguage('fr');
        localStorage.setItem('reskue_language', 'FR');
        break;
      default:
        i18n.changeLanguage('fr');
        localStorage.setItem('reskue_language', 'FR');
        setSelected('FR');
        break;
    }
  }, [selected]);

  function handleMenu(event: any) {
    if (isOpen) {
      if (content) {
        content.style.display = 'block';
      }
      if (footer) {
        footer.style.display = 'flex';
      }
      setRetractableState('closed');
      setButtonContent(
        <img src={hamburger} className="hamburger" alt="hamburger menu icon" />,
      );
    } else {
      if (content) {
        content.style.display = 'none';
      }
      if (footer) {
        footer.style.display = 'none';
      }
      setRetractableState('');
      setButtonContent(
        <img src={cross} className="hamburger" alt="close menu icon" />,
      );
    }
    setIsOpen(!isOpen);
  }

  function handleLogout(event: MouseEvent<HTMLParagraphElement>) {
    event.preventDefault();
    logout();
    window.location.reload();
  }

  return (
    <div id="mobile_menu">
      <div id="mobile_top_nav">
        <a href="/" className="logo">
          <img src={logo} width={30} height={30} alt="Reskue logo" />
        </a>
        <div id="mobile_top_nav_button">
          {session.exists === true && (
            <div className="nav_wallet" onClick={handleWalletMenu}>
              <FaWallet />
            </div>
          )}
          <button id="mobile_nav_switch" onClick={handleMenu}>
            {buttonContent}
          </button>
        </div>
      </div>
      <div id="mobile_nav_retractable" className={retractableState}>
        <ul id="mobile_mid_nav">
          <li id="mobile_mid_nav_title">{t('header.title')}</li>
          <ListPages onItemClick={handleMenu} />
          <li>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://reskue.medium.com/"
            >
              News
            </a>
          </li>
          <li id="mobile_searchbar">
            <MainSearchbar detachedMediaQuery={''} onItemClick={handleMenu} />
          </li>
          {session.exists === true && (
            <>
              <li onClick={handleMenu} id="mobile_mid_nav_title">
                {t('header.menu.title')}
              </li>
              <li onClick={handleMenu}>
                <Link to="/user">{t('header.menu.myAccount')}</Link>
              </li>
              <li onClick={handleMenu}>
                <a href="/user/edit">{t('header.menu.myGallery')}</a>
              </li>
              <li onClick={handleMenu}>
                <Link to="/user/edit">{t('header.menu.settings')}</Link>
              </li>
            </>
          )}
          <li>
            <ReactFlagsSelect
              selected={selected}
              onSelect={code => setSelected(code)}
              countries={['US', 'FR']}
              customLabels={{ US: 'EN', FR: 'FR' }}
              className="menu-flags"
            />
          </li>
        </ul>
        <div id="mobile_bot_nav">
          {session.exists === true && <p onClick={handleLogout}>Logout</p>}
          {session.exists === false && (
            <a className="nav_login" href="/user/login">
              {t('header.menu.login')}
            </a>
          )}
          {session.exists === false && (
            <a className="nav_register" href="/user/register">
              {t('header.menu.signUp')}
            </a>
          )}
        </div>
      </div>
    </div>
    // </div>
  );
}

export default SecondaryNavbar;
