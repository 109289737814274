import React from 'react';
import DataObjectIcon from '@mui/icons-material/DataObject';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Box } from '@mui/system';
import { useParams } from 'react-router-dom';
import { BasicTable } from 'utils/BasicTable/BasicTable';
import img from 'images/art/art_2.webp';
import RevenueSplit from './revenueSplit';
import BlackButton from 'helpers/elements/black_button';
import { toast } from 'react-toastify';
import { EditTable } from 'utils/BasicTable/EditTable';
import { useTranslation } from 'react-i18next';

export default function ArtworkMultipleCreation(props: any) {
  const { t } = useTranslation('common');
  const params = useParams();

  const [files, setFiles] = useState<FileList>();
  const [artworks, setArtworks] = useState<any>([]);
  const [artworkObject, setArtworkObject] = useState<any>({
    saleType: 'Direct',
  });
  const [generate, setGenerate] = useState<boolean>(false);

  function onFileChange(event: ChangeEvent<HTMLInputElement>) {
    setFiles(event.target.files);
  }

  // Toast
  const toastId = React.useRef(null);
  const notify = () =>
    (toastId.current = toast(t('artworks.create.infos.upload'), {
      autoClose: false,
      position: toast.POSITION.BOTTOM_LEFT,
    }));
  const updateSucc = () =>
    toast.update(toastId.current, {
      render: t('artworks.create.array'),
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });
  // const update = () => toast.update(toastId.current, { render: "Collection created, uploading avatar and image!", position: toast.POSITION.BOTTOM_LEFT });
  const updateFail = err =>
    toast.update(toastId.current, {
      render: err?.response?.data?.message ?? err ?? null,
      type: toast.TYPE.ERROR,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

  const [split, setSplit] = useState<any>({
    ngo: '50',
    artist: '50',
    reskue: '0',
  });

  const [error, setError] = useState<any>({
    ngo: '',
    artist: '',
    reskue: '',
    global: '',
  });

  const [list, setList] = useState<boolean>(true);

  function checkSplit(e: any, type: string) {
    if (type === 'ngo') {
      if (parseFloat(e.target.value) < 50) {
        setError({
          ...error,
          ngo: `${t('artworks.create.errrors.cannotBe')} < 50%`,
        });
        setSplit({ ...split, ngo: '50' });
        return;
      }
      setError({ ...error, ngo: '' });
      setSplit({ ...split, ngo: e.target.value });
    }
    if (type === 'artist') {
      if (parseFloat(e.target.value) > 50) {
        setError({
          ...error,
          artist: `${t('artworks.create.errrors.cannotBe')} > 50%`,
        });
        setSplit({ ...split, artist: '50' });
        return;
      }
      setError({ ...error, artist: '' });
      setSplit({ ...split, artist: e.target.value });
    }
    if (type === 'reskue') {
      if (parseFloat(e.target.value) > 50) {
        setError({
          ...error,
          reskue: `${t('artworks.create.errrors.cannotBe')} > 50%`,
        });
        setSplit({ ...split, reskue: '50' });
        return;
      }
      setError({ ...error, reskue: '' });
      setSplit({ ...split, reskue: e.target.value });
    }
  }

  // TODO Check if artwork has the same name than another one on the collection
  function createArtworksFile() {
    if (!files) return;
    const newArtworks = [];
    for (let i = 0; i < files.length; i++) {
      const artwork = {
        artwork_name: '',
        collection_name: params.collection_name,
        image: {
          url: window.URL.createObjectURL(files[i]),
          file: files[i],
        },
        reserve: artworkObject.reserve,
        reskueCashback: split.reskue,
        artistCashback: split.artist,
        ngoCashback: split.ngo,
        attributes: '{}',
      };
      artwork.artwork_name = files[i].name.substring(
        0,
        files[i].name.lastIndexOf('.'),
      );
      newArtworks.push(artwork);
    }
    setArtworks(newArtworks);
  }

  useEffect(() => {
    createArtworksFile();
  }, [files]);

  function createArtworks() {
    notify();
    if (!files) {
      updateFail(t('artworks.create.errors.missingFiles'));
      return false;
    }
    if (
      (artworkObject.reserve === undefined && list) ||
      parseFloat(artworkObject.reserve) < 20
    ) {
      updateFail(t('artworks.create.errors.priceTooLow'));
      return false;
    }
    if (artworkObject.sellType === null && list) {
      updateFail(t('artworks.create.errors.noSellType'));
      return false;
    }
    if (
      parseFloat(split.ngo) +
        parseFloat(split.artist) +
        parseFloat(split.reskue) !=
      100
    ) {
      updateFail('Revenue splitting must be equal to 100%!');
      return false;
    }
    if (list) {
      for (let i = 0; i < files.length; i++) {
        artworks[i].reserve = artworkObject.reserve;
        artworks[i].saleType = artworkObject.saleType;
        artworks[i].reskueCashback = split.reskue;
        artworks[i].artistCashback = split.artist;
        artworks[i].ngoCashback = split.ngo;
      }
    }
    setGenerate(true);
    updateSucc();
    return false;
  }

  return (
    <div>
      <Box pt="1rem" sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography component={'span'}>
          <h1>{t('artworks.create.createMultiple')}</h1>
          <p>{t('artworks.create.infos.createMultiple')}</p>
        </Typography>

        <Box mb="1rem" sx={{ width: '50%' }}>
          <label style={{ width: '50%', cursor: 'pointer' }}>
            <Paper
              variant="outlined"
              sx={{
                padding: '1rem',
                display: 'flex',
                alignBox: 'center',
                justifyContent: 'space-between',
                gap: '1rem',
                color: 'rgba(0, 0, 0, 0.6)',
              }}
            >
              {t('artworks.create.uploadMultiple')}
              {files ? `(${files?.length} files uploaded)` : null}{' '}
              <AddPhotoAlternateIcon color={files ? 'success' : 'primary'} />
            </Paper>
            <input
              type="file"
              multiple={true}
              name="avatar"
              id="avatar_input"
              hidden
              onChange={onFileChange}
              style={{ width: '50%' }}
            />
          </label>
        </Box>
        {/* <Box mb="1rem" sx={{ width: "50%", }}>
                    <label style={{ width: "50%", cursor: "pointer" }} >
                        <Paper variant="outlined" sx={{ padding: "1rem", display: "flex", alignBox: "center", justifyContent: "space-between", gap: "1rem", color: "rgba(0, 0, 0, 0.6)" }} >
                            Upload JSON file  <DataObjectIcon />
                        </Paper>
                        <input type="file" multiple={true} name="avatar" id="avatar_input" hidden
                            style={{ width: "50%" }}
                        />
                    </label>
                </Box> */}

        <p>{t('artworks.create.defineGlobalDatas')}</p>
        <Box
          sx={{
            display: 'flex',
            gap: '1rem',
            flexWrap: 'wrap',
            width: '50%',
            paddingBottom: '1rem',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked
                value={list}
                onChange={e => setList(!list)}
              />
            }
            label={t('artworks.create.list')}
          />
          <TextField
            sx={{ width: '20%' }}
            InputLabelProps={{ shrink: true }}
            label={t('artworks.create.price')}
            type="number"
            InputProps={{
              inputProps: { min: 10 },
            }}
            disabled={!list}
            onChange={e =>
              setArtworkObject({ ...artworkObject, reserve: e.target.value })
            }
          />
          <Box sx={{ width: '20%' }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                {t('artworks.create.sellType')}
              </InputLabel>
              <Select
                disabled={true}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label={t('artworks.create.sellType')}
                defaultValue={'direct'}
                onChange={e =>
                  setArtworkObject({
                    ...artworkObject,
                    saleType: e.target.value,
                  })
                }
              >
                <MenuItem value={'direct'}>
                  {t('artworks.create.direct')}
                </MenuItem>
                <MenuItem value={'auction'}>
                  {t('artworks.create.auction')}
                </MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '50%' }}>
          <div>
            <TextField
              label={t('artworks.create.toNonProfit')}
              id="outlined-start-adornment"
              sx={{ m: 1, width: '30%' }}
              defaultValue={split.ngo}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              onChange={e => checkSplit(e, 'ngo')}
              error={error.ngo == '' ? false : true}
              helperText={error.ngo}
              type="number"
            />
            <TextField
              label={t('artworks.create.toArtist')}
              id="outlined-start-adornment"
              sx={{ m: 1, width: '30%' }}
              defaultValue={split.artist}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              onChange={e => checkSplit(e, 'artist')}
              error={error.artist == '' ? false : true}
              helperText={error.artist}
              type="number"
            />
            {/* <TextField
              label={t("artworks.create.toReskue")}
              id="outlined-start-adornment"
              sx={{ m: 1, width: "30%" }}
              defaultValue={split.reskue}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">%</InputAdornment>
                ),
              }}
              onChange={(e) => checkSplit(e, "reskue")}
              error={error.reskue == "" ? false : true}
              helperText={error.reskue}
              type="number"
            /> */}
          </div>
          <div className="error">{error?.global}</div>
        </Box>
        <Box sx={{ width: '50%' }}>
          <RevenueSplit {...split} />
        </Box>
      </Box>
      <Box sx={{ width: '50%' }}>
        <BlackButton
          content={`${t('artworks.create.generateList')} (1/2)`}
          onClick={createArtworks}
        />
      </Box>
      {generate ? <EditTable artworks={artworks} list={list} /> : null}
    </div>
  );
}
