import { useEffect, useState } from 'react';
import LeaderboardUserEntity from '../entity/leaderboarderUserEntity.entity';
import LeaderBoardService from '../leaderboard.service';
import React from 'react';
import default_profile from 'images/ui/default_avatar.png';
import './leaderboard.scss';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { ImgLoad } from 'utils/ImgLoader/img_loader';
import { useTranslation } from 'react-i18next';

export default function Leaderboard() {
  /** Tools */
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  /** Variables */
  const [leaderboard, setLeaderboard] = useState<any>([]);

  /** service */
  const leaderboardService = new LeaderBoardService();

  useEffect(() => {
    async function fetchLeaderboard() {
      const data = await leaderboardService.getLeaderboard();
      if (data) {
        setLeaderboard(data);
      }
    }
    fetchLeaderboard();
  }, []);

  const redirectToUserProfile = (username: string) => {
    navigate(`/user/${username}`);
  };

  if (!leaderboard) return <React.Fragment></React.Fragment>;
  return (
    <div id="leaderboard_container">
      <Helmet>
        <title>Leaderboard | Reskue</title>
        <meta
          name="title"
          content="Leaderboard - The best Reskuers"
          data-react-helmet="true"
        />
        <meta
          name="description"
          content="The leaderboard provides the ranking of the best Reskuers."
          data-react-helmet="true"
        />

        {/* Open Graph / Facebook */}
        <meta property="og:title" content="Leaderboard - The best Reskuers" />
        <meta property="og:type" content="text" />
        <meta
          property="og:description"
          content="The leaderboard provides the ranking of the best Reskuers."
        />
        <meta property="og:url" content="https://reskue.art/leaderboard" />

        {/* TWITTER */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Leaderboard - The best Reskuers" />
        <meta
          name="twitter:description"
          content="The leaderboard provides the ranking of the best Reskuers."
        />
        <meta property="twitter:url" content="https://reskue.art/leaderbaord" />
      </Helmet>
      <div id="leaderboard">
        <h1>{t('leaderboard.title')}</h1>
        <table>
          <tbody>
            {leaderboard.map((leader: any, index: number) => (
              <tr
                key={index}
                className="leader"
                onClick={() => redirectToUserProfile(leader.username as string)}
              >
                <td className="leaderboard_index">#{index + 1}</td>
                <td className="leaderboard_user">
                  <ImgLoad
                    width={50}
                    circle={true}
                    src={
                      !leader.avatar
                        ? default_profile
                        : leader.avatar?.url_small
                    }
                    alt={leader.username + "'s username"}
                  />
                  {leader.username}
                </td>
                <td className="leaderboard_total_given">
                  {leader.reputation ?? '0'} RSK
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
