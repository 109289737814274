import React from 'react';
import './press.scss';
import mint from 'images/logo/20mint.webp';
import nft_morning from 'images/logo/NFT_morning_good.webp';
import hello from 'images/logo/hellotoken.png';
import coina from 'images/logo/coinalist.jpg';
import tatum from 'images/logo/tatum.png';

export default function Press() {
  return (
    <section className="press_container">
      <h2>PRESS & PODCASTS</h2>
      <ul className="logogrid">
        <li className="logogrid__item">
          <a
            href="https://www.youtube.com/watch?v=5rZklwwEefQ&ab_channel=20MinutesFrance"
            rel="noreferrer"
            target="_blank"
          >
            {' '}
            <img src={mint} className="logogrid__img" alt="20 Mintutes" />
          </a>
        </li>

        <li className="logogrid__item">
          <a
            href="https://www.nftmorning.com/p/284-special-projets-nft-for-good#details"
            rel="noreferrer"
            target="_blank"
          >
            <img
              src={nft_morning}
              className="logogrid__img"
              alt="NFT Morning"
            />
          </a>
        </li>

        <li className="logogrid__item">
          <a
            href="https://hellotoken.io/reskue-lancement-version-officielle/"
            rel="noreferrer"
            target="_blank"
          >
            <img src={hello} className="logogrid__img" alt="hellotoken" />
          </a>
        </li>

        <li className="logogrid__item">
          <a
            href="https://www.coinalist.io/reskue-met-lart-et-la-technologie-blockchain-au-service-de-projets-associatifs-via-lapi-de-starton/"
            rel="noreferrer"
            target="_blank"
          >
            <img src={coina} className="logogrid__img" alt="coinalist" />
          </a>
        </li>

        <li className="logogrid__item">
          <a
            href="https://blog.tatum.io/tatum-and-reskue-combine-efforts-in-utilizing-blockchain-technology-to-collect-digital-art-and-247dd3a8b177"
            rel="noreferrer"
            target="_blank"
          >
            <img src={tatum} className="logogrid__img" alt="Tatum" />
          </a>
        </li>
      </ul>
    </section>
  );
}
