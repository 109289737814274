import React, { useEffect, useState } from 'react';
import './caroussel.scss';
import ArtworkCard from '../../cards/artwork_card/artwork_card';
import axios from 'axios';
import { api } from 'api/reskueApi';

export default function Caroussel(props: any) {
  const [data, setData] = useState([]);
  const [rate, setRate] = useState<number>();

  async function getRate() {
    try {
      const resp = await api.get('/money/exchangeRate', {
        params: {
          fiat: 'EUR',
        },
      });
      setRate(resp.data);
    } catch (err: any) {
      console.error(err.response?.data?.message);
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_BACKEND_URL) {
      axios
        .get(
          process.env.REACT_APP_BACKEND_URL + '/gallery/artwork/many/random',
          {
            params: {
              limit: 3,
              relations: [
                'collection',
                'collection.ngo',
                'collection.artist',
                'listing',
              ],
              collection: '',
            },
          },
        )
        .then((res: any) => {
          setData(Array.from(res.data));
        })
        .catch(err => {
          console.error(err);
        });
    }
    getRate();
  }, [props.setClick]);

  return (
    <div className="caroussel_container">
      {data.map(data => (
        <div className="thecard" key={data.id}>
          <ArtworkCard
            key={data.id}
            height={600}
            image={data.image?.url_medium}
            data_name={data.artwork_name.replace}
            name={data.artwork_name}
            released={data.released}
            size={data.size}
            listing={data.listing}
            type={data.sold}
            ngo={data?.collection?.ngo}
            cashbacks={data?.cashbacks}
            collection={data?.collection}
            collection_name={data?.collection.collection_name}
            artist={data.collection.artist}
            id={data?.id}
            rate={rate}
          />
        </div>
      ))}
    </div>
  );
}
