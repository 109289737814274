import React from 'react';
import {
  Backdrop,
  CircularProgress,
  FormControl,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import { modalUnactive } from 'data/modal.slice';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { api } from 'api/reskueApi';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import GavelIcon from '@mui/icons-material/Gavel';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import { useTranslation } from 'react-i18next';

export default function Sell(props: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  // Toast
  const toastId = React.useRef(null);
  const notify = () =>
    (toastId.current = toast(t('listings.infos.listingInProgress'), {
      autoClose: false,
      position: toast.POSITION.BOTTOM_LEFT,
    }));
  const updateSucc = (props: any) =>
    toast.update(toastId.current, {
      render: props,
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });
  const updateFail = err =>
    toast.update(toastId.current, {
      render: err?.response?.data?.message,
      type: toast.TYPE.ERROR,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

  // loader
  const [open, setOpen] = React.useState(false);

  const [reserve, setReserve] = React.useState<number>(20);

  // const [password, setPassword] = useState<string>(null)

  const handleLeave = () => {
    dispatch(modalUnactive(null));
  };

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  async function handleList() {
    // if (password === null) {
    //     toast.error(`Password missing!`, {
    //         position: toast.POSITION.BOTTOM_LEFT
    //     });
    //     return;
    // }
    if (reserve === null) {
      toast.error(t('listings.errors.missingPrice'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    } else if (reserve < 20) {
      toast.error(t('listings.errors.priceTooLow'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }
    setOpen(true);
    notify();
    try {
      await api.post(
        process.env.REACT_APP_BACKEND_URL + '/marketplace',
        {
          id: props.modal.listing.id,
          reserve: reserve,
        },
        {
          headers: {
            // "X-PASSWORD": password,
            'Access-Control-Allow-Origin': '*',
          },
        },
      );
      updateSucc(t('listings.successListing'));
      handleLeave();
      window.location.reload();
    } catch (err: any) {
      updateFail(err);
      console.error(err);
    }
    setOpen(false);
  }

  return (
    <div className="modal-overlay">
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="modal-wrapper">
        <div className="modal">
          <div className="modal-container">
            <div className="modal_row">
              <div className="col1">
                <div className="head_modal">
                  <h1>{t('listings.defineSellType')}</h1>
                  <span className="modal-close" onClick={handleLeave}>
                    &#10005;
                  </span>
                </div>
                {/* <div>
                                    <div>Artwork was listed as a{props?.modal?.listing.listingType === "DIRECT" ? " direct sale." : "n auction."}</div>
                                    <Box sx={{display: "flex", flexDirection:"column"}}>
                         
                                    </Box>

                                </div> */}
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="fullWidth"
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="icon label tabs example"
                >
                  <Tab
                    icon={<GpsFixedIcon />}
                    label={t('listings.directSale')}
                  />
                  <Tab
                    icon={<GavelIcon />}
                    disabled
                    label={t('listings.auctionSale')}
                  />
                </Tabs>
                <Box p={1} sx={{ display: 'flex', flexDirection: 'column' }}>
                  {value === 0 ? (
                    <Box>
                      <div>{t('listings.defineSellPrice')}</div>
                      <TextField
                        margin="normal"
                        sx={{ width: '30%' }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                          inputProps: { min: 20 },
                        }}
                        label={t('listings.price')}
                        type="number"
                        defaultValue={20}
                        onChange={e => setReserve(parseFloat(e.target.value))}
                      />
                    </Box>
                  ) : (
                    <Box>
                      <div>{t('listings.defineReservePrice')}</div>
                      <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <TextField
                          margin="normal"
                          sx={{ width: '30%' }}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            inputProps: { min: 20 },
                          }}
                          label="Price (€)"
                          type="number"
                          onChange={e => setReserve(parseFloat(e.target.value))}
                        />
                        <TextField
                          margin="normal"
                          sx={{ width: '30%' }}
                          InputLabelProps={{ shrink: true }}
                          InputProps={{
                            inputProps: { min: 0.2 },
                          }}
                          label="Days"
                          type="number"
                          onChange={e => setReserve(parseFloat(e.target.value))}
                        />
                      </Box>
                    </Box>
                  )}

                  <FormControl fullWidth>
                    {/* <TextField
                                        margin="normal"
                                        id="outlined-password-input"
                                        label="Password"
                                        type="password"
                                        InputLabelProps={{ shrink: true }}
                                        autoComplete="current-password"
                                        onChange={e => setPassword(e.target.value)}
                                    /> */}
                    <button
                      className="btn btn-gradient-border"
                      onClick={handleList}
                    >
                      {t('listings.list')}
                    </button>
                  </FormControl>
                </Box>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
