import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import img from 'images/design/background2.svg';
import algoliasearch from 'algoliasearch/lite';
import {
  InstantSearch,
  SearchBox,
  Hits,
  RefinementList,
  Pagination,
} from 'react-instantsearch-hooks-web';
import './collection_page.scss';
import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import LazyLoad from 'react-lazyload';
import { ImgLoad } from 'utils/ImgLoader/img_loader';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import '../cards/artwork_card/artwork_cards.scss';
import { SortBy } from 'react-instantsearch-hooks-web';
import { CustomHits, Hit } from '../cards/algolia_card/hit';
import NgoButton from '../buttons/ngo_button';
import { api } from 'api/reskueApi';

export default function CollectionPage() {
  const [rate, setRate] = useState<number>();

  async function getRate() {
    try {
      const resp = await api.get('/money/exchangeRate', {
        params: {
          fiat: 'EUR',
        },
      });
      setRate(resp.data);
    } catch (err: any) {
      console.error(err.response?.data?.message);
    }
  }

  /** Tools */
  const params = useParams();
  const navigate = useNavigate();

  /** Handlers */
  function handleFilter() {
    const ft = Array.from(
      document.getElementsByClassName(
        'filters',
      ) as HTMLCollectionOf<HTMLElement>,
    );
    ft[0].style.display = 'none';
    const ft2 = Array.from(
      document.getElementsByClassName(
        'filter_moved',
      ) as HTMLCollectionOf<HTMLElement>,
    );
    ft2[0].style.display = 'block';
  }
  function handleFilter2() {
    const ft = Array.from(
      document.getElementsByClassName(
        'filters',
      ) as HTMLCollectionOf<HTMLElement>,
    );
    ft[0].style.display = 'block';
    const ft2 = Array.from(
      document.getElementsByClassName(
        'filter_moved',
      ) as HTMLCollectionOf<HTMLElement>,
    );
    ft2[0].style.display = 'none';
  }
  // const dispatch = useDispatch();
  const searchClient = algoliasearch(
    process.env.REACT_APP_ALGOLIA_APP_ID,
    process.env.REACT_APP_ALGOLIA_PUBLIC_KEY,
  );

  const [postState, setPostState] = useState({
    data: {
      collection_name: '',
      description: '',
      size: 0,
      image: {
        url: '',
        url_medium: '',
        url_small: '',
      },
      cover: {
        url: '',
      },
      artist: {
        username: '',
        avatar: {
          url: '',
        },
      },
      ngo: {
        ngo_name: '',
        logo: {
          url: '',
        },
      },
    },
    loading: false,
    error: false,
  });
  const URL = `/gallery/collection/find/${params.collection_name}`;

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_BACKEND_URL + URL, {
        params: {
          relations: ['ngo', 'artist'],
          artworks: false,
        },
      })
      .then(res => {
        if (res.data) {
          setPostState(s => ({ ...s, data: res.data }));
        } else {
          setPostState(s => ({ ...s, loading: true }));
        }
      })
      .catch(error => {
        setPostState(s => ({ ...s, error: true }));
      });
    getRate();
  }, []);

  const { data, loading, error } = postState;

  if (error === true) navigate('/404');
  if (loading === true) return <div>LOADING...</div>;
  return (
    <LazyLoad height={200}>
      <div className="collection_wrapper">
        <Helmet>
          <title>Collection {data.collection_name} | Reskue</title>
          <meta name="title" content="Collection" />
          <meta name="description" content={data.description} />
          <meta
            property="og:title"
            content={`Collection ${data.collection_name} | Reskue`}
          />
          <meta property="og:url" content="https://reskue.art/collections" />
          <meta property="og:description" content="Collections on Reskue." />
          <meta property="og:image" content={`${data?.image?.url}`} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:title"
            content={`Collection ${data.collection_name} | Reskue`}
          />
          <meta name="twitter:site" content="@reskueNFT" />
          <meta name="twitter:url" content="https://reskue.art/collections" />
          <meta
            name="twitter:image"
            content={`Collection ${data.collection_name} | Reskue`}
          />
          <meta property="og:type" content="website" />
        </Helmet>

        <div className="collection_header">
          <ImgLoad
            className="collection_cover"
            src={data.cover?.url ? data.cover.url : img}
            height={600}
            alt={data.collection_name}
          />
          <h1>{data.collection_name}</h1>
          <ImgLoad
            src={data?.image?.url_medium}
            width={150}
            height={150}
            alt={data.collection_name}
            className="collection_img"
          />
        </div>
        <div className="collection_box">
          <div className="row">
            <div className="column">
              <div>Description</div>
              <div className="descr">{data?.description}</div>
            </div>
            <div className="column">
              <div>Supporting</div>
              <div className="elem">
                <NgoButton ngo={data?.ngo} />
              </div>
            </div>
            <div className="column">
              <div>Collection of</div>
              <div className="size">{data.size}</div>
            </div>
          </div>
        </div>
        <div className="collection_list">
          <InstantSearch
            searchClient={searchClient}
            indexName="Artworks"
            initialUiState={{
              ['Artworks']: {
                // query: 'phone',
                // page: 5,
                refinementList: {
                  collection: [params.collection_name],
                },
              },
            }}
          >
            <SortBy
              items={[
                { label: 'Featured', value: 'Artworks' },
                { label: 'Name Ascendant', value: 'R2' },
                { label: 'Name Descendant', value: 'R1' },
                { label: 'Price low to high', value: 'R3' },
                { label: 'Prince high to low', value: 'R4' },
              ]}
            />
            <div className="item_layer">
              <div className="filter_moved" onClick={handleFilter2}>
                <FaArrowCircleRight size="1.5em" className="arrow" />
              </div>
              <div className="filters">
                <div className="head_filters">
                  <div className="f_title">Search</div>
                  <div className="filter_move" onClick={handleFilter}>
                    <FaArrowCircleLeft size="1.5em" className="arrow" />
                  </div>
                </div>
                <SearchBox />
                <RefinementList
                  attribute="collection"
                  classNames={{
                    root: 'collection_filter',
                  }}
                />
                <div className="f_title">Type</div>
                <RefinementList attribute="listing.listingType" />

                <div className="f_title">MARKET</div>
                <RefinementList attribute="listing.saleType" />
                {/* <div className="f_title">Style</div>
                <RefinementList
                  limit={5}
                  showMore={true}
                  attribute="style"
                /> */}
                {/* <div className="f_title">Price</div>
                <RefinementList
                  attribute="price.value"
                /> */}
              </div>
              <div className="col2">
                {/* <CustomHits data="lol"/> */}
                {/* <Hits hitComponent={Hit} /> */}
                <CustomHits {...Hit} collection={data} rate={rate} />
              </div>
            </div>
            <Pagination />
          </InstantSearch>
        </div>
      </div>
    </LazyLoad>
  );
}
