import { useNavigate } from 'react-router-dom';
import React, { MouseEvent, useEffect, useRef, useState } from 'react';
import './profile_nav.scss';
import Session from 'interfaces/session.interface';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from 'data/store';
import { logout } from 'services/auth.service';
import { FaWallet } from 'react-icons/fa';
import { menuActive } from 'data/menu.slice';
import { ImgLoad } from 'utils/ImgLoader/img_loader';
import { useTranslation } from 'react-i18next';

function useOutsideEvent(ref: any, setToggleMenu: any) {
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setToggleMenu('');
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

export default function ProfileNav() {
  /** Contexts */
  const session: Session = useSelector((state: Store) => state.session);
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  const navigate = useNavigate();
  const openProfilMenu = useRef(null);
  const [toggleMenu, setToggleMenu] = useState('');
  useOutsideEvent(openProfilMenu, setToggleMenu);

  function handleIconMenu() {
    if (toggleMenu === '') {
      setToggleMenu('open_profile_menu');
    } else {
      setToggleMenu('');
    }
  }

  const handleWalletMenu = async () => {
    dispatch(
      menuActive({
        menu: 'ok',
      }),
    );
  };

  function redirect(path: string) {
    navigate(path);
    setToggleMenu('');
  }

  function handleLogout(event: MouseEvent<HTMLLIElement>) {
    event.preventDefault();
    logout();
    window.location.reload();
  }

  return (
    <>
      <div id="profile_nav" ref={openProfilMenu} onMouseLeave={handleIconMenu}>
        {session.exists === true && (
          <div className="nav_icon" onMouseEnter={handleIconMenu}>
            <ImgLoad src={session.avatar} width={40} circle={true} />
          </div>
        )}
        <ul id="profile_menu" className={toggleMenu}>
          <div className="profile_nav_triangle"></div>
          <li onClick={() => redirect('/user')}>
            {t('header.menu.myAccount')}
          </li>
          <li onClick={() => redirect('/user#collection')}>
            {t('header.menu.myGallery')}
          </li>
          <li onClick={() => redirect('/user/edit')}>
            {t('header.menu.settings')}
          </li>
          <li onClick={handleLogout}>{t('header.menu.logout')}</li>
        </ul>
      </div>
      {session.exists === true && (
        <div className="nav_wallet" onClick={handleWalletMenu}>
          <FaWallet />
        </div>
      )}
    </>
  );
}
