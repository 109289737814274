import React, { useState } from 'react';
import { modalUnactive } from 'data/modal.slice';
import { Store } from 'data/store';
import BlackButton from 'helpers/elements/black_button';
import Session from 'interfaces/session.interface';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { loginAndSaveTokens } from 'services/auth.service';
import { setUser } from 'services/data.service';
import { LoginResponse } from 'services/interfaces/ApiResponse';
import ReskueTextInput from 'utils/ReskueTextInput/ReskueTextInput';
import './login.scss';
import OauthButton from './oauth_button';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export default function Login(props: any) {
  /** Contexts */
  const session: Session = useSelector((state: Store) => state.session);

  /** Hooks */
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  /** Variables */
  const [loginError, setLogginError] = useState(' ');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  async function loginUser() {
    const ret: LoginResponse = await loginAndSaveTokens({ username, password });
    if (ret.error !== '') {
      setLogginError(ret.error);
      toast.error(`${t('login.error')}`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    } else {
      setUser({ session, dispatch });
      toast.success(
        `${t('user.login.welcome')} ${username}, ${t(
          'user.login.youAreConnected',
        )}`,
        {
          position: toast.POSITION.BOTTOM_LEFT,
        },
      );
      if (props?.modal != true) navigate('/user');
      return true;
    }
    return false;
  }

  return (
    <section id="login_container">
      <div id="login">
        <div className="head_modal">
          {props?.modal === true && (
            <>
              <h3 id="login_title">{t('login.sign_in')}</h3>
              <span
                className="modal-close"
                onClick={() => {
                  dispatch(modalUnactive(null));
                }}
              >
                &#10005;
              </span>
            </>
          )}
          {props?.modal != true && <h1>{t('login.sign_in')}</h1>}
        </div>
        <OauthButton />
        <div>{t('login.or_cont')}</div>
        <form>
          <ReskueTextInput
            type="text"
            id="login_username"
            text={username}
            setText={setUsername}
            placeholder={t('login.username')}
          />
          <ReskueTextInput
            type="password"
            id="login_password"
            text={password}
            setText={setPassword}
            placeholder={t('login.password')}
          />
          <Link
            id="login_forgot_password"
            className="black_anchor"
            to="/user/password/forgot"
            onClick={() => {
              dispatch(modalUnactive(null));
            }}
          >
            {t('login.forgot_pass')}
          </Link>
          <BlackButton onClick={loginUser} content={t('user.login.connect')} />
          <p className="login_error_text">{loginError}</p>
        </form>
        <Link
          className="black_anchor"
          to="/user/register"
          onClick={() => {
            dispatch(modalUnactive(null));
          }}
        >
          {t('login.or_create')}
        </Link>
      </div>
    </section>
  );
}
