interface iProps {
  event: React.ChangeEvent<HTMLInputElement>;
  setter: React.Dispatch<React.SetStateAction<string>>;
}

function textOnChange({ event, setter }: iProps) {
  const text: string = event.target.value;
  setter(text);
}

export default textOnChange;
