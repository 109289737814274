import { createSlice } from '@reduxjs/toolkit';

export interface LanguageSlice {
  selected: string;
}

const initialState: LanguageSlice = {
  selected: 'fr',
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    languageToEnglish(state) {
      state.selected = 'en';
    },
    languageToFrench(state) {
      state.selected = 'fr';
    },
    languageToUkrainian(state) {
      state.selected = 'ua';
    },
  },
});

export const { languageToEnglish, languageToFrench, languageToUkrainian } =
  languageSlice.actions;

export default languageSlice.reducer;
