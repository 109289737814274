import React from 'react';
import { useNavigate } from 'react-router-dom';

// Needs whereIs, name, img
export default function NgoInfoBubble(props: any) {
  const navigate = useNavigate();
  function handleNav(e: any) {
    navigate('/ngos/' + props?.ngo?.ngo_name);
    e.stopPropagation();
  }
  const mystyle = {
    bottom: `${props.whereIs === 'above' ? '-180px' : '50px'}`,
  };

  return (
    <div className="info" style={mystyle} onClick={handleNav}>
      <img src={props?.ngo?.logo?.url} />
      <div className="info_name">{props?.ngo?.ngo_name}</div>
      <div className="info_box">
        <div className="info_col">
          <div className="info_subtitle"> Mission </div>{' '}
          <div className="tagline">{props?.ngo?.tagline}</div>
        </div>
      </div>
    </div>
  );
}
