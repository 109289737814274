import React from 'react';
import { useNavigate } from 'react-router-dom';
import UserEntity from '../../shared/interfaces/UserEntity.interface';

// Needs whereIs, name, img
export default function UserInfoBubble(props: {
  user: UserEntity;
  whereIs: string;
}) {
  const navigate = useNavigate();
  function handleNav(e: any) {
    navigate('/user/' + props.user?.username);
    e.stopPropagation();
  }
  const mystyle = {
    bottom: `${props.whereIs === 'above' ? '-180px' : '50px'}`,
  };
  return (
    <div className="info" style={mystyle} onClick={handleNav}>
      <img src={props?.user?.avatar?.url} />
      <div className="info_name">@{props.user.username}</div>
      <div className="info_box">
        <div className="info_col">
          <div className="info_name"></div>
        </div>
        <div className="info_col2">
          {' '}
          RSKU <div className="info_name"> {props.user.reputation} </div>
        </div>
      </div>
    </div>
  );
}
