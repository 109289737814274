import React, { useEffect, useState } from 'react';
import MaterialTable from 'material-table';
import tableIcons from './TableIcons';
import { Box, TextField } from '@mui/material';
import BlackButton from 'helpers/elements/black_button';
import { toast } from 'react-toastify';
import { apiForm } from 'api/reskueApi';
import { api } from 'api/reskueApi';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

export const EditTable = (props: any) => {
  // TODO: store collection in redux
  // const collections: any = useSelector((store: Store) => store.artistCollections)

  const [artworks, setArtworks] = useState(props?.artworks);
  const [rate, setRate] = useState<number>();
  const { t } = useTranslation('common');

  const params = useParams();
  const navigate = useNavigate();

  // const editable = props.map(o => ({ ...o }));

  useEffect(() => {
    setArtworks(props.artworks);
  }, [props]);

  // const [password, setPassword] = useState<string>(null)
  const columns: any = [
    { title: t('artworks.create.name'), field: 'artwork_name' },
    {
      title: t('artworks.create.image'),
      field: 'image',
      editable: 'never',
      render: (rowData: any) => (
        <img
          src={rowData?.image?.url}
          style={{ width: 40, borderRadius: '5px' }}
        />
      ),
    },
    { title: t('artworks.create.price'), field: 'reserve', type: 'numeric' },
    // { title: "Type", field: "sold" },
    {
      title: t('artworks.create.sellType'),
      editable: 'never',
      field: 'saleType',
      lookup: { Direct: 'Direct', Auction: 'Auction' },
    },
    {
      title: t('artworks.create.description'),
      field: 'description',
      type: 'string',
    },
  ];
  // Toast
  const toastId = React.useRef(null);
  const notify = (props: any) =>
    (toastId.current = toast(props, {
      autoClose: false,
      position: toast.POSITION.BOTTOM_LEFT,
    }));
  const update = (props: any) =>
    toast.update(toastId.current, {
      render: t('artworks.create.success'),
      position: toast.POSITION.BOTTOM_LEFT,
    });
  const updateFail = (err: any) =>
    toast.update(toastId.current, {
      render: err,
      type: toast.TYPE.ERROR,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });
  const updateSucc = (props: any) =>
    toast.update(toastId.current, {
      render: props,
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

  async function createArtwork() {
    // if (props.list && password === null) {
    //     toast.error(`Password missing!`, {
    //         position: toast.POSITION.BOTTOM_LEFT
    //     });
    //     return false;
    // }

    for (let i = 0; i < props?.artworks.length; i++) {
      try {
        // TODO: CHECK IF AUCTION OR DIRECT SALE
        notify(
          t('artworks.create.infos.startMint') + ' ' + artworks[i].artwork_name,
        );
        const data = new FormData();
        data.append('collection_name', artworks[i].collection_name);
        data.append('artwork_name', artworks[i].artwork_name);
        if (artworks[i].description != null)
          data.append('description', artworks[i].description);
        // data.append("sellType", artworks[i].saleType);
        data.append('reskueCashback', props.artworks[i].reskueCashback);
        data.append('artistCashback', props.artworks[i].artistCashback);
        data.append('ngoCashback', props.artworks[i].ngoCashback);
        data.append('media', props.artworks[i].image.file);
        // TODO: add attributes
        // data.append("attributes", '{"name":"John", "age":30}')
        data.append('attributes', '{}');
        const response = await apiForm.post(
          process.env.REACT_APP_BACKEND_URL + '/gallery/artwork',
          data,
        );
        // await api.post(`/gallery/collection/image/${ret.data.id}`, data);
        // data.append("reserve", props?.artworks[i].reserve);

        // !LISTING
        if (props.list) {
          update(
            t('artworks.create.infos.selling') +
              ' ' +
              props?.artworks[i].artwork_name,
          );
          const rate = await api.get('/money/exchangeRate', {
            params: {
              fiat: 'EUR',
            },
          });
          setRate(rate.data);
          const resp = await api.post(
            process.env.REACT_APP_BACKEND_URL + '/marketplace',
            {
              reserve: parseInt(artworks[i].reserve),
              id: response.data.listing.id,
            },
            {
              headers: {
                // "X-PASSWORD": password,
                'Access-Control-Allow-Origin': '*',
              },
            },
          );
        }
        updateSucc(`${t('artworks.create.successList')} 🎨`);
      } catch (err: any) {
        updateFail(
          'error: ' + err.response.data.message ??
            t('artworks.create.errors.default'),
        );
        console.error(err.response.data.message);
        return false;
      }
    }
    navigate('/user/edit/' + params.collection_name);
    return false;
  }

  return (
    <Box>
      <MaterialTable
        title=""
        icons={tableIcons}
        columns={columns}
        data={artworks}
        options={{
          exportButton: true,
        }}
        editable={{
          onRowUpdate: (newData, oldData: any) =>
            new Promise((resolve: any, reject) => {
              setTimeout(() => {
                const dataUpdate = [...artworks];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                setArtworks([...dataUpdate]);

                resolve();
              }, 1000);
            }),
          onRowDelete: (oldData: any) =>
            new Promise((resolve: any, reject) => {
              setTimeout(() => {
                const dataDelete = [...artworks];
                const index = oldData.tableData.id;
                dataDelete.splice(index, 1);
                setArtworks([...dataDelete]);

                resolve();
              }, 1000);

              {
                /* {props.list ? (
            <TextField
                margin="normal"
                id="outlined-password-input"
                label="Password*"
                type="password"
                InputLabelProps={{ shrink: true }}
                autoComplete="current-password"
                onChange={e => setPassword(e.target.value)}
            />) : null
        } */
              }
            }),
        }}
      />
      <BlackButton
        content={`${t('artworks.create.confirmMultipleCreation')} (2/2)`}
        onClick={createArtwork}
      />
    </Box>
  );
};
