import axios from 'axios';
import useMail from 'helpers/customHooks/useMail';
import BlackButton from 'helpers/elements/black_button';
import FormMail from 'helpers/elements/formMail';
import { EInputStatusEnum } from 'helpers/status';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { api } from '../../../app/api/reskueApi';
import './password_forms.scss';

export default function ForgotPassword() {
  const [mail, setMail] = useMail();
  const [formSubmited, setFormSubmited] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>('');
  const { t } = useTranslation('common');

  async function submit() {
    if (mail.status === EInputStatusEnum.Valid) {
      try {
        await api.post('/user/recover', {
          mail: mail.address,
        });
        setFormSubmited(true);
        return true;
      } catch (error: any) {
        setFormError(t('user.recover.errors.notFound'));
      }
    }

    return false;
  }

  if (formSubmited) {
    return (
      <div id="forgot_password">
        <h1>
          Email sent !<br />
          Check your mailbox
        </h1>
      </div>
    );
  }
  return (
    <div id="forgot_password">
      <h1>Forgot password</h1>
      <form>
        <FormMail email={mail} setEmail={setMail} autofill={false} />
        <BlackButton content="Continue" onClick={submit} />
        <p id="password_form_error">{formError}</p>
      </form>
    </div>
  );
}
