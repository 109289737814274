import { EInputStatusEnum } from 'helpers/status';
import { IEmailInput } from 'interfaces/emailInput.interface';
import React, { useState } from 'react';

export default function useMail(
  address = '',
): [IEmailInput, React.Dispatch<React.SetStateAction<IEmailInput>>] {
  const [mail, setMail] = useState<IEmailInput>({
    address: address,
    error: '',
    status: EInputStatusEnum.Empty,
  });

  return [mail, setMail];
}
