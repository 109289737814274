import React from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import './CollectionStamp.scss';
import img from 'images/design/background2.svg';
import { useNavigate } from 'react-router-dom';
import Session from 'interfaces/session.interface';
import { Store } from 'data/store';
import { useSelector } from 'react-redux';

export default function CollectionStamp(props: any) {
  /** Contexts */
  const session: Session = useSelector((state: Store) => state.session);
  const navigate = useNavigate();

  return (
    <Card
      sx={{ width: 345 }}
      onClick={() =>
        session.username != props.artist.username
          ? navigate('/collections/' + props.collection_name)
          : navigate(`/user/edit/${props.collection_name}`)
      }
    >
      <CardActionArea>
        <Box height="200">
          <div className="preview_avatar">
            <img src={props.image?.url} />
          </div>
          <CardMedia
            sx={{
              filter:
                'brightness(67%) grayscale(10%) contrast(120%) sepia(36%)',
            }}
            component="img"
            height="200"
            image={props?.cover?.url ?? img}
            // alt="green iguana"
          ></CardMedia>
        </Box>
        <CardContent>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
              fontSize: '20px',
            }}
            gutterBottom
            variant="h5"
            component="div"
          >
            {props.collection_name}
          </Typography>
          <Typography component={'span'} variant="body2" color="text.secondary">
            {/* {collecObject.description ?? "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."} */}
            {props?.description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
