import React, { useEffect, useState } from 'react';
import matic from 'images/logo/matic.png';
import { modalUnactive } from 'data/modal.slice';
import { Store } from 'data/store';
import BlackButton from 'helpers/elements/black_button';
import { useDispatch, useSelector } from 'react-redux';
import './last_modal.scss';
import ArtworkCard from '../../cards/artwork_card/artwork_card';
import Session from 'interfaces/session.interface';
import ReskueFailed from 'utils/ReskueFailed/ReskueFailed';
import { api } from 'api/reskueApi';
import Login from '../../../components/authentification/ReskueLogin/login';
import NgoSelector from '../../../components/cards/ngo_card/ngo_selector';
import { toast } from 'react-toastify';
import unsetCurrentSession from 'helpers/auth/unset_current_session';
import Unlist from './unlist';
import Sell from './sell';
import { Alert, Backdrop, Box, CircularProgress } from '@mui/material';
import { menuActive } from 'data/menu.slice';
import { useTranslation } from 'react-i18next';
import WertModule from '@wert-io/module-react-component';

export default function TheModal(data: any) {
  /** Global Data */
  const { modal, ngo, ready } = useSelector((state: Store) => state.modal);
  const session: Session = useSelector((state: Store) => state.session);
  const language = useSelector((state: Store) => state.language.selected);
  const dispatch = useDispatch();
  const [reskueMargin, setReskueMargin] = useState<number>(0);
  const [generateIntentError, setGenerateIntentError] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [balance, setBalance] = useState<string>(null);
  const [equiv, setEquiv] = useState<any>('');
  const { t } = useTranslation('common');

  // Toast
  const toastId = React.useRef(null);
  const notify = () =>
    (toastId.current = toast(t('listings.txInitiated'), {
      autoClose: false,
      position: toast.POSITION.BOTTOM_LEFT,
    }));
  const updateSucc = (props: any) =>
    toast.update(toastId.current, {
      render: props,
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });
  const update = (props: any) =>
    toast.update(toastId.current, {
      render: props,
      position: toast.POSITION.BOTTOM_LEFT,
    });
  const updateFail = err =>
    toast.update(toastId.current, {
      render: err?.response?.data?.message,
      type: toast.TYPE.ERROR,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

  const [rate, setRate] = useState<number>(0);
  const [checked, setChecked] = useState(true);
  const [useCard, setUseCard] = useState<boolean>(false);

  async function getRate() {
    try {
      const resp = await api.get('/money/exchangeRate', {
        params: {
          fiat: 'EUR',
        },
      });
      setRate(resp.data);
    } catch (err: any) {
      console.error(err.response?.data?.message);
    }
  }

  useEffect(() => {
    if (!session?.exists && modal) {
      toast.info(t('listings.infos.signUpBeforeContinue'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
    }
  }, [modal]);

  const handleLeave = () => {
    dispatch(modalUnactive(null));
    setChecked(true);
    if (useCard) setUseCard(false);
  };

  async function handlePay() {
    setOpen(true);
    try {
      notify();
      const resp = await api.post(
        process.env.REACT_APP_BACKEND_URL + '/marketplace/buy',
        {
          id: modal.listing.id,
          ngoId: modal?.collection?.ngo?.id || ngo?.ngo_id || ngo?.id,
        },
      );
      if (reskueMargin > 0.0) {
        await api.post(
          process.env.REACT_APP_BACKEND_URL + '/user/donation/reskue',
          {
            amount: reskueMargin,
          },
        );
      }
      updateSucc(t('listings.successBuy') + modal?.name);
      setOpen(false);
      window.location.reload();
      return false;
    } catch (err: any) {
      updateFail(err);
      setOpen(false);
      console.error(err);
      return false;
    }
  }

  function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  useEffect(() => {
    if (checked === true)
      setReskueMargin(Math.round(modal?.price * 0.1 * 10) / 10);
    if (checked === false) setReskueMargin(0);
  }, [checked, modal?.price]);

  useEffect(() => {
    getBalance();
    getRate();
  }, [modal, ngo]);

  async function getBalance() {
    if (!session.exists) return;
    try {
      const balance = await api.get('/user/wallet/balance');
      setBalance(balance.data.toFixed(2));
      setEquiv(Math.round(parseFloat(balance.data) * rate * 10) / 10);
    } catch (err: any) {
      console.error(err.response?.data?.message);
      if (err.status === 401) {
        unsetCurrentSession({ session, dispatch });
      }
    }
  }

  if (!modal) {
    return null;
  }
  if (!session.exists) {
    return (
      <div className="modal-overlay">
        <div className="modal-wrapper">
          <div className="modal">
            <div className="modal-container">
              <Login modal={true} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (modal.type === 'sell') {
    return <Sell modal={modal} />;
  }
  if (modal.type === 'unlist') {
    return <Unlist modal={modal} />;
  }
  if (
    !modal.listing?.ngo &&
    !!modal.collection?.ngo?.id === false &&
    !!modal.ngo?.id === false &&
    ready === false
  ) {
    return (
      <div className="modal-overlay">
        <div className="modal-wrapper">
          <div className="modal">
            <div className="modal-container">
              <div className="modal_row">
                <div className="col1">
                  <div className="head_modal">
                    <h1>{t('listings.chooseNonProfit')}</h1>
                    <span className="modal-close" onClick={handleLeave}>
                      &#10005;
                    </span>
                  </div>
                  <NgoSelector />
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="modal-overlay">
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className="modal-wrapper">
        <div className="modal">
          <div className="modal-container">
            <div className="modal_row">
              <div className="col1">
                <div className="ngo_flag"></div>
                <div className="head_modal">
                  <h1>{t('listings.collectArtwork')}</h1>
                  <span className="modal-close" onClick={handleLeave}>
                    &#10005;
                  </span>
                </div>
                {useCard ? (
                  <Box
                    sx={{
                      width: '100%',
                      height: '580px',
                    }}
                  >
                    <WertModule
                      options={{
                        partner_id: process.env.REACT_APP_WERT_PARTNER_ID_PROD,
                        container_id: 'wert-widget',
                        theme: 'white',
                        commodity: 'MATIC',
                        commodities: 'MATIC',
                        network: 'polygon',
                        lang: language,
                        height: 580,
                        address: session.wallet,
                        commodity_amount:
                          parseFloat(modal?.price) + reskueMargin,
                        listeners: {
                          'payment-status': async (data: {
                            status: string;
                          }) => {
                            if (data.status === 'success') {
                              await sleep(7000);
                              await getBalance();
                              handlePay();
                            }
                          },
                        },
                      }}
                    />
                  </Box>
                ) : (
                  <>
                    <div>
                      <table>
                        <tbody>
                          <tr>
                            <td>
                              {t('listings.artwork')}:{' '}
                              {modal?.name || data?.artwork_name}
                            </td>
                            <td align="right">
                              {modal?.price}
                              <img src={matic} className="matic" />
                            </td>
                          </tr>
                          <tr>
                            <td className="to_np">
                              - {t('listings.goingToNonProfit')}
                            </td>
                            <td align="right" className="am_np">
                              {(parseInt(modal?.price) *
                                parseInt(
                                  modal?.cashbacks?.ngo?.percentage ||
                                    ngo?.cashbacks?.percentage,
                                )) /
                                100}
                              <img src={matic} className="matic" />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              {t('listings.supportReskue')}
                              <input
                                type="checkbox"
                                checked={checked}
                                onChange={() => setChecked(!checked)}
                              />
                            </td>
                            <td align="right">
                              {reskueMargin}
                              <img src={matic} className="matic" />
                            </td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr>
                            <td>{t('listings.total')}</td>
                            <td align="right">
                              {parseFloat(modal?.price) + reskueMargin}
                              <img src={matic} className="matic" />
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                    <div>
                      {t('listings.youHave')} {balance}
                      <img src={matic} className="matic" /> (≈ {equiv}€){' '}
                      {t('listings.inYourWallet')}
                    </div>
                    {modal?.ngo?.issueTaxReceipts === true ||
                    (ngo?.issueTaxReceipts === true &&
                      modal?.listing?.saleType === 'PRIMARY') ? (
                      <>
                        {!session.hasIdentity && (
                          <Alert
                            sx={{
                              marginTop: '1rem',
                              marginBottom: '1rem',
                              fontWeight: '500',
                              textAlign: 'justify',
                            }}
                            severity="info"
                          >
                            {t('listings.generateFiscalReceipts')}
                          </Alert>
                        )}
                        {session.hasIdentity && (
                          <Alert
                            sx={{ marginTop: '1rem', marginBottom: '1rem' }}
                            severity="success"
                          >
                            {t('listings.identitySet')}
                          </Alert>
                        )}
                      </>
                    ) : null}
                    <div className="form">
                      {parseFloat(balance) <
                      parseFloat(modal?.price) + reskueMargin ? (
                        <BlackButton
                          content="Par carte"
                          onClick={() => setUseCard(true)}
                        />
                      ) : (
                        <div>
                          <BlackButton
                            content={t('listings.collect')}
                            onClick={handlePay}
                          />
                        </div>
                      )}
                      {generateIntentError && (
                        <>
                          <ReskueFailed
                            text={generateIntentError}
                            crossLength={8}
                          />
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
              <div className="col2">
                {data.name}
                <ArtworkCard
                  collection={modal?.collection || data?.collection}
                  // todo: fix undefined when click from algolia collection page
                  collection_name={
                    modal?.collection?.collection_name ||
                    data?.collection?.collection_name ||
                    modal?.collection_name
                  }
                  name={modal?.name || data?.artwork_name}
                  image={modal?.image?.url_medium || modal?.image}
                  listing={modal?.listing}
                  type="owner"
                  username={session.username}
                  avatar={session.avatar}
                  artist={modal?.artist}
                  rate={rate}
                  noclickable={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
