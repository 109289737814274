import React, { useState } from 'react';
import metamask_logo from 'images/ui/metamaskLogo.png';
import detectEthereumProvider from '@metamask/detect-provider';
import { useSelector } from 'react-redux';
import { Store } from 'data/store';
import Session from 'interfaces/session.interface';
import ReskueLoader from 'utils/ReskueLoader/ReskueLoader';
import './metamask_pay.scss';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const enum STATUS {
  FAILED = 'FAILED',
  PROCESSING = 'PROCESSING',
  SUCCESSFUL = 'SUCCESSFUL',
  DEFAULT = 'DEFAULT',
}

export default function MetamaskPay() {
  /** Hooks */
  const [status, setStatus] = useState<string>(STATUS.DEFAULT);
  const [log, setLog] = useState<string>('');

  /** Global Data */
  const { value } = useSelector((state: Store) => state.menu);
  const session: Session = useSelector((state: Store) => state.session);

  const { t } = useTranslation('common');

  async function handleMeta() {
    if (parseFloat(value) < 0.1 || isNaN(value)) {
      toast.warn(`${t('wallet.metamask.minimumDeposit')} 10 MATICS`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return;
    }

    setStatus(STATUS.PROCESSING);
    const provider = await detectEthereumProvider();
    if (provider) {
      // From now on, this should always be true:
      // provider === window.ethereum
      const accounts = await window.ethereum.request({
        method: 'eth_requestAccounts',
      });
      const account = accounts[0];
      if (provider !== window.ethereum) {
        console.error(t('wallet.metamask.errors.multipleWallets'));
      }
      if (
        window.ethereum.networkVersion != '137' &&
        window.ethereum.networkVersion != '80001'
      ) {
        // 137 Polygon
        // 80001 Mumbai
        try {
          toast.info(t('wallet.metamask.infos.switchOnPolygon'), {
            position: toast.POSITION.BOTTOM_LEFT,
          });
          // check if the chain to connect to is installed
          await window.ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: '0X89' }], // chainId must be in hexadecimal numbers
          });
        } catch (error) {
          // This error code indicates that the chain has not been added to MetaMask
          // if it is not, then install it into the user MetaMask
          if (error.code === 4902) {
            try {
              toast.info(`${t('wallet.metamask.errors.switchOnPolygon')} 🦊`, {
                position: toast.POSITION.BOTTOM_LEFT,
              });
              await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                  {
                    chainId: '0x89',
                    rpcUrls: ['https://polygon-rpc.com'],
                    chainName: 'Polygon Mainnet',
                    nativeCurrency: {
                      name: 'MATIC',
                      symbol: 'MATIC',
                      decimals: 18,
                    },
                    // currencySymbol: 'MATIC',
                    blockExplorerUrls: ['https://polygonscan.com/'],
                  },
                ],
              });
            } catch (addError) {
              setStatus(STATUS.FAILED);
              setLog(addError.message);
              console.error(addError);
              return;
            }
          }
          console.error(error);
        }
      }

      const good_val = parseFloat(value) * Math.pow(10, 18);
      const transactionParameters = {
        // nonce: '0x00', // ignored by MetaMask
        // gasPrice: '0x09184e72a000', // customizable by user during MetaMask confirmation.
        // gas: '0x2710', // customizable by user during MetaMask confirmation.
        to: session.wallet, // Required except during contract publications.
        from: account, // must match user's active address.
        // '0x' + (50000000000000000).toString(16)
        value: '0x' + good_val.toString(16),
        // value: '0xb1a2bc2ec50000', // Only required to send ether to the recipient from the initiating external account.
        // data:'0x7f7465737432000000000000000000000000000000000000000000000000000000600057', // Optional, but used for defining smart contract creation and interaction.
        // chainId: '0x3', // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
      };

      toast.info(t('wallet.metamask.infos.initiatedTx'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      await window.ethereum
        .request({
          method: 'eth_sendTransaction',
          params: [transactionParameters],
        })
        .then(
          // txHash is a hex string
          txHash => {
            setStatus(STATUS.SUCCESSFUL);
            toast.success(t('wallet.metamask.success'), {
              position: toast.POSITION.BOTTOM_LEFT,
            });
          },
        )
        .catch(error => {
          console.error;
          setStatus(STATUS.FAILED);
          if (error.code === 4001)
            // setLog("Transaction annulée")
            toast.error(t('wallet.metamask.infos.canceledTx'), {
              position: toast.POSITION.BOTTOM_LEFT,
            });
        });
    } else {
      setStatus(STATUS.FAILED);
      setLog(t('wallet.metamask.errors.addExtension'));
    }
  }

  if (status === STATUS.DEFAULT) {
    return (
      <div className="wallet_ic selected" onClick={handleMeta}>
        {t('wallet.metamask.deposit')}{' '}
        <img src={metamask_logo} alt="the Metamask logo" />
      </div>
    );
  }
  if (status === STATUS.PROCESSING) {
    return <ReskueLoader text={t('wallet.metamask.infos.processing')} />;
  }
  if (status === STATUS.FAILED) {
    return (
      <>
        <div className="wallet_ic selected" onClick={handleMeta}>
          {t('wallet.metamask.deposit')} <img src={metamask_logo} alt="" />
        </div>
        <div className="errors">{log}</div>
      </>
    );
  }
  if (status === STATUS.SUCCESSFUL) {
    return <></>;
  }
}
