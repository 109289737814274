import React from 'react';
import './ngo_list.scss';
import NgoCard from '../cards/ngo_card/ngo_card';
import { GetNgoDto } from 'interfaces/GetNgoDto';
import { useSelector } from 'react-redux';
import { Store } from 'data/store';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';

export default function NgoList() {
  const ngos: GetNgoDto[] = useSelector((store: Store) => store.ngoList);
  const { t } = useTranslation('common');

  return (
    <section className="ngo_list">
      {/* <Helmet>
            <title>Non-profit organizations | Reskue</title>
            <meta name="title" content="Non-Profit Organizations | Reskue"/>
            <meta name="description" content="The list of the non-profit organizations referenced on Reskue."/>
        </Helmet> */}
      <h2>{t('supportCausePage')}</h2>
      <div className="ngo_list_grid">
        {ngos.map(ngo => (
          <NgoCard key={ngo.id} ngo={ngo} redirect_to_donation={false} />
        ))}
      </div>
    </section>
  );
}
