import { Store } from 'data/store';
import mailOnChange from 'helpers/input_helpers/mailOnChange';
import { EInputStatusEnum } from 'helpers/status';
import { IEmailInput } from 'interfaces/emailInput.interface';
import Session from 'interfaces/session.interface';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import { FormControl, FormHelperText, Input, InputLabel } from '@mui/material';

interface iProps {
  email: IEmailInput;
  setEmail: React.Dispatch<React.SetStateAction<IEmailInput>>;
  autofill: boolean;
  label?: string;
}

export default function FormMail({
  email,
  setEmail,
  autofill,
  label = 'Email',
}: iProps) {
  const { t } = useTranslation('common');
  /** Global data */
  const session: Session = useSelector((state: Store) => state.session);

  useEffect(() => {
    if (session.exists && autofill) {
      setEmail({
        ...email,
        address: session.email,
        status: EInputStatusEnum.Valid,
      });
    }
  }, []);

  if (session.exists && autofill) {
    return (
      <div className="input_container">
        <p>
          {t('donationPage.connectedAs')} {session.username}
        </p>
      </div>
    );
  }
  return (
    <FormControl>
      <InputLabel htmlFor="my-input">{label}</InputLabel>
      <Input
        id="my-input"
        aria-describedby="my-helper-text"
        onChange={(event: any) => mailOnChange({ event, setEmail })}
        value={email.address}
      />
      <FormHelperText id="my-helper-text">
        We will never share your email.
      </FormHelperText>
      <div className="error_text">{email.error}</div>
    </FormControl>
    // <div className='input_container'>
    //     <label htmlFor="email">{label}</label>
    //     <input type="email" name="email" placeholder="mail@example.com" onChange={(event) => mailOnChange({ event, setEmail })} value={email.address} />
    //     <div className='error_text'>{email.error}</div>
    // </div>
  );
}
