import React from 'react';
import HowItWorks from './how_it_works/howItWork';
import LandingSection from './landing_section/landing_section';
import NgoList from '../ngo_page/ngo_list';
import Press from './press/Press';
import CookieConsent from 'react-cookie-consent';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import logo from '../../shared/images/logo/RVB_BLACK.png';

function Home() {
  const { t } = useTranslation('common');
  return (
    <>
      <Helmet>
        <title> Home | Reskue</title>
        <meta
          property="og:title"
          content="Reskue - Philanthropic art marketplace"
          data-react-helmet="true"
        />
        <meta
          name="description"
          content="Support nonprofits organizations by collecting unique NFT art"
          data-react-helmet="true"
        />
        {/* <meta property="og:description" content="Support nonprofits organizations by collecting unique NFT art" /> */}
        <meta property="og:image" content={logo} />
        <meta
          name="twitter:description"
          content="Support nonprofits organizations by collecting unique NFT art"
        />
        <meta name="twitter:image" content={logo} />
      </Helmet>
      <LandingSection />
      <NgoList />
      <HowItWorks />
      <Press />
      <CookieConsent
        disableStyles={true}
        location="bottom"
        containerClasses="cookie_cont"
        buttonClasses="btn btn-gradient-border"
        cookieName="myAwesomeCookieName3"
        expires={999}
        overlay
        buttonText={t('cookie.button')}
      >
        {t('cookie.description')}
      </CookieConsent>
    </>
  );
}

export default Home;
