import React from 'react';
import { createSlice } from '@reduxjs/toolkit';
import IModalInfo from 'interfaces/ModalInfo.interface';

const modalslice = createSlice({
  name: 'modal',
  initialState: {
    modal: null,
    name: '',
    ngo: '',
    ready: false,
  },
  reducers: {
    modalActive(state, action: IModalInfo) {
      state.modal = action.payload;
    },
    modalUnactive(state, action) {
      state.modal = null;
      state.ready = false;
      state.ngo = null;
    },
    updateModal(state, action: IModalInfo) {
      void state;
      return action.payload;
    },
  },
});
export default modalslice.reducer;

export const { modalActive, modalUnactive, updateModal } = modalslice.actions;
