import React, { useEffect } from 'react';
import './profile.scss';
import GalleryCard from '../cards/gallery_card/NftCard';
import Session from 'interfaces/session.interface';
import { useSelector } from 'react-redux';
import { Store } from 'data/store';
import { useNavigate } from 'react-router-dom';
import aciNft from 'images/art/aci_partners.webp';
import aciLogo from 'images/ui/aci_partners_logo.png';

export default function Aci() {
  /** Global Data */
  const session: Session = useSelector((state: Store) => state.session);

  /** Tools */
  const navigate = useNavigate();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <>
      <section id="aci_profile_section">
        <div id="profile">
          <img crossOrigin="" src={aciLogo} alt="" />
          <div id="user_score">
            <p>
              <span>500</span> RSK
            </p>
            <p>
              <span>1</span> Proof of reskue
            </p>
          </div>
          <p>
            Accelerate collective intelligence with{' '}
            <a href="https://acipartners.fr">ACI Partners</a>
          </p>
        </div>
        <div id="donation_grid_container">
          <div className="grid" onClick={() => navigate('/nft/aci_partners')}>
            <GalleryCard
              card={{
                id: '',
                name: 'Child dreaming of a peaceful sunset city',
                cause: 'Childhood protection',
                collection: 'Reskue Brands',
                imgUrl: aciNft,
                address: '0x6aB5668E30ED5b36a7F0177ed1426Df3e0879eEb',
                donation: 500,
                polygonScan:
                  'https://polygonscan.com/tx/0x16b58ac0ec0ec3b36059f009d37320dee8f94f6687bef450aafa92152bba6709',
                opensea:
                  'https://opensea.io/assets/matic/0x75E4D272947Bb49Ed32C41EE604A731a82C845B9/0',
                ngo: 'Action Enfance',
              }}
            />
          </div>
        </div>
      </section>
    </>
  );
}
