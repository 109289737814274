import axios, { AxiosRequestConfig } from 'axios';
import { AuthTokens } from 'interfaces/AuthTokens.interface';
import saveTokens, { getTokens } from 'services/tokens.service';

/**
 * Reskue API: with automatic authentication bearer token header
 * Reskue raw API: without authentication
 */
const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const rawApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const apiForm = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
  },
});

api.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (!process.env.REACT_APP_BACKEND_URL) return;

    const tokens: AuthTokens | null = getTokens();

    if (tokens !== null && config.headers) {
      config.headers['Authorization'] = 'Bearer ' + tokens.access_token;
    }

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

apiForm.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    if (!process.env.REACT_APP_BACKEND_URL) return;

    const tokens: AuthTokens | null = getTokens();

    if (tokens !== null && config.headers) {
      config.headers['Authorization'] = 'Bearer ' + tokens.access_token;
    }

    return config;
  },
  (error: any) => {
    return Promise.reject(error);
  },
);

const interceptor = apiForm.interceptors.response.use(
  response => {
    return response;
  },

  async (error: any) => {
    const originalConfig = error.config;
    if (originalConfig.url !== '/auth/user/login' && error.response) {
      if (error.response.status === 401) {
        apiForm.interceptors.response.eject(interceptor);

        const tokens: AuthTokens | null = getTokens();
        if (tokens === null) return;

        try {
          const response = await axios.get(
            process.env.REACT_APP_BACKEND_URL + '/auth/user/refresh',
            { headers: { Authorization: 'Bearer ' + tokens.refresh_token } },
          );
          const newTokens: AuthTokens = response.data;
          saveTokens(newTokens);
          return apiForm(originalConfig);
        } catch (newError: any) {
          return Promise.reject(newError);
        }
      }
    }
    return Promise.reject(error);
  },
);

export { api, rawApi, apiForm };

/*
const interceptor2 = api.interceptors.response.use(
  (response) => {
    return response;
  },

  async (error: any) => {
    const originalConfig = error.config;
    if (originalConfig.url !== "/auth/user/login" && error.response) {
      if (error.response.status === 401) {
        api.interceptors.response.eject(interceptor2);

        const tokens: AuthTokens | null = getTokens();
        if (tokens === null) return;

        try {
          const response = await axios.get(
            process.env.REACT_APP_BACKEND_URL + "/auth/user/refresh",
            { headers: { Authorization: "Bearer " + tokens.refresh_token } }
          );
          const newTokens: AuthTokens = response.data;
          saveTokens(newTokens);
          return api(originalConfig);
        } catch (newError: any) {
          return Promise.reject(newError);
        }
      }
    }
    return Promise.reject(error);
  }
);
*/
