import React, { useEffect, useRef, useState } from 'react';
import LanguageIcon from '@mui/icons-material/Language';
import { Backdrop, TextField } from '@mui/material';
import VerifiedIcon from '@mui/icons-material/Verified';
import { Box } from '@mui/system';
import { Store } from 'data/store';
import Session from 'interfaces/session.interface';
import UserEntity from 'interfaces/UserEntity.interface';
import { useDispatch, useSelector } from 'react-redux';
import getPublicUser from 'services/user.service';
import { useNavigate } from 'react-router-dom';
import { api } from 'api/reskueApi';
import { toast } from 'react-toastify';
import { updateSession } from 'data/session.slice';
import { useTranslation } from 'react-i18next';
import BlackButton from 'helpers/elements/black_button';

export default function UserInfo() {
  /** Contexts */
  const session: Session = useSelector((state: Store) => state.session);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  // TOAST
  const toastId = React.useRef(null);
  const notify = () =>
    (toastId.current = toast('Update in progress...', {
      autoClose: false,
      position: toast.POSITION.BOTTOM_LEFT,
    }));
  const updateSucc = () =>
    toast.update(toastId.current, {
      render: 'Update done!',
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });
  const update = (props: any) =>
    toast.update(toastId.current, {
      render: props,
      position: toast.POSITION.BOTTOM_LEFT,
    });
  const updateFail = (err: any) =>
    toast.update(toastId.current, {
      render: err?.response?.data?.message,
      type: toast.TYPE.ERROR,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

  /** Form references */
  const descrRef: any = useRef();
  const usernameRef: any = useRef();
  const twitterRef: any = useRef();
  const instaRef: any = useRef();
  const fbRef: any = useRef();
  const webRef: any = useRef();

  const userModif: any = {};

  /** Hooks */
  const [user, setUser] = useState<UserEntity | null>(null);
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      const ret = await getPublicUser(session.username, []);
      if (ret === undefined) navigate('/404');
      setUser(ret);
    };
    fetchUser();
  }, []);

  async function handleUpdate() {
    if (user.username != usernameRef.current.value)
      userModif.username = usernameRef.current.value;
    if (user.description != descrRef.current.value)
      userModif.description = descrRef.current.value;
    // if (twitterRef != "" && user.twitter != twitterRef.current.value)
    //   userModif.twitter = twitterRef.current.value;
    // if (instaRef != "" && user.instagram != instaRef.current.value)
    //   userModif.instagram = instaRef.current.value;
    // if (fbRef != "" && user.facebook != fbRef.current.value)
    //   userModif.facebook = fbRef.current.value;
    if (webRef != '' && user.website != webRef.current.value)
      userModif.website = webRef.current.value;
    Object.keys(userModif).forEach(key => {
      if (userModif[key] === '') {
        delete userModif[key];
      }
    });
    if (Object.keys(userModif).length != 0) {
      setOpen(true);
      notify();
      try {
        await api.patch('/user', userModif);
        dispatch(
          updateSession({
            ...session,
            ...userModif,
          }),
        );

        updateSucc();
        setOpen(false);
        return false;
      } catch (err: any) {
        updateFail(err);
        console.error('ERROR');
        setOpen(false);
        return false;
      }
    }
    return false;
  }

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={open}
      ></Backdrop>
      {session.roles.includes('artist') === true ? (
        <Box
          sx={{
            width: '30%',
            display: 'flex',
            float: 'left',
            alignItems: 'flex-start',
            gap: '0.2rem',
            WebkitAlignItems: 'center',
            justifyContent: 'center',
          }}
        >
          Role :
          <button id="artist_btn" className="btn btn-gradient btn-glow">
            Artist
            <VerifiedIcon sx={{ fontSize: 15 }} />
          </button>
        </Box>
      ) : null}
      <TextField
        margin="normal"
        id="outlined-required"
        label={t('user.edit.profile.username')}
        defaultValue={session.username}
        inputRef={usernameRef}
      />

      <TextField
        id="outlined-multiline-static"
        label="Bio"
        margin="normal"
        multiline
        rows={4}
        defaultValue={user?.description === null ? '' : user?.description}
        inputRef={descrRef}
        InputLabelProps={{ shrink: true }}
      />
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <LanguageIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
        <TextField
          id="outlined-required"
          label={t('user.edit.profile.website')}
          variant="standard"
          inputRef={webRef}
          defaultValue={session?.website ?? null}
        />
      </Box>
      <div className="social_networks">Social Networks (soon)</div>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        {/* <TwitterIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} /> */}
        <TextField
          id="outlined-required"
          label="Twitter"
          variant="standard"
          inputRef={twitterRef}
          defaultValue={session?.twitter ?? null}
          disabled={true}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        {/* <InstagramIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} /> */}
        <TextField
          id="outlined-required"
          label="Instagram"
          variant="standard"
          inputRef={instaRef}
          defaultValue={session?.instagram ?? null}
          disabled={true}
        />
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
        {/* <FacebookIcon sx={{ color: "action.active", mr: 1, my: 0.5 }} /> */}
        <TextField
          disabled={true}
          id="outlined-required"
          label="Facebook"
          variant="standard"
          inputRef={fbRef}
          defaultValue={session?.facebook ?? null}
        />
      </Box>

      <Box sx={{ width: '50%' }}>
        <BlackButton content="update" onClick={handleUpdate} />
      </Box>
    </>
  );
}
