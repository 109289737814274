import { createSlice } from '@reduxjs/toolkit';
import { GetNgoDto } from 'interfaces/GetNgoDto';

const initialState = [] as GetNgoDto[];

export const NgoListSlice = createSlice({
  name: 'ngoList',
  initialState,
  reducers: {
    addNgo(state, action) {
      void state;
      return action.payload;
    },
  },
});

export const { addNgo } = NgoListSlice.actions;
export default NgoListSlice.reducer;
