import { Dispatch } from '@reduxjs/toolkit';
import LeaderboardController from './leaderboard.controller';

export default class LeaderBoardService {
  // constructor(
  //     private readonly dispatch: Dispatch,
  // ) { }

  private readonly leaderboardController = new LeaderboardController();

  async getLeaderboard() {
    const { data, error } =
      await this.leaderboardController.getLeaderBoardUsers();
    if (!data) {
      console.error(error);
      return;
    }
    return data.items;
  }
}
