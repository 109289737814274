import GalleryCard from '../cards/gallery_card/NftCard';
import './nft_page.scss';
import aciNft from 'images/art/aci_partners.webp';
import React from 'react';

interface INftArtwork {
  artwork_name: string;
  created_at: string;
  id: string;
  image: {
    id: string;
    key: string;
    url: string;
  };
}

interface INft {
  artwork: INftArtwork;
  contribution: string;
  giver: {
    id: string;
    username: string;
  };
  id: string;
  ngo: {
    ngo_name: string;
    website_url: string;
  };
  value: string;
  wallet: string;
}

export default function AciNft() {
  return (
    <div id="nft_page">
      <div id="nft_page_artwork">
        <GalleryCard
          card={{
            id: '',
            name: 'Child dreaming of a peaceful sunset city',
            cause: 'Childhood protection',
            collection: 'Reskue Brands',
            imgUrl: aciNft,
            address: '0x6aB5668E30ED5b36a7F0177ed1426Df3e0879eEb',
            donation: 500,
            polygonScan: '',
            opensea: '',
            ngo: 'Action Enfance',
          }}
        />
      </div>
      <div id="nft_page_infos">
        <h2>Child dreaming of a peaceful sunset city</h2>
        <div id="nft_page_infos_donation">
          <p>
            Value: <strong>500 €</strong>
          </p>
          <p>
            Contribution : <strong>0 €</strong>
          </p>
          <p>
            Owner : <strong>ACI Partners</strong>
          </p>
          <p>
            Wallet : <strong>0x6aB5668E30ED5b36a7F0177ed1426Df3e0879eEb</strong>
          </p>
          <p>
            NGO : <strong>Action Enfance</strong>
          </p>
        </div>
        <div id="ngo_page_infos_links">
          <a
            className="black_link"
            target="_blank"
            rel="noreferrer"
            href="https://www.actionenfance.org/"
          >
            NGO website
          </a>
          <a
            className="black_link"
            target="_blank"
            rel="noreferrer"
            href="https://opensea.io/assets/matic/0x75E4D272947Bb49Ed32C41EE604A731a82C845B9/0"
          >
            OpenSea
          </a>
          <a
            className="black_link"
            target="_blank"
            rel="noreferrer"
            href="https://polygonscan.com/tx/0x16b58ac0ec0ec3b36059f009d37320dee8f94f6687bef450aafa92152bba6709"
          >
            PolygonScan
          </a>
        </div>
      </div>
    </div>
  );
}
