import React, { useState } from 'react';
import { toast } from 'react-toastify';
import MaterialTable from 'material-table';
import tableIcons from './TableIcons';
import BlackButton from 'helpers/elements/black_button';
import { Box } from '@mui/system';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import { api } from 'api/reskueApi';
import LaunchIcon from '@mui/icons-material/Launch';
import Session from 'interfaces/session.interface';
import { Store } from 'data/store';
import { useSelector } from 'react-redux';

// const data = [
//     {
//         name: "Artwork2",
//         reserve: "1",
//         saleType: "fixed price",
//         imageUrl: img,
//     },
// ];

const columns: any = [
  { title: 'Name', field: 'artwork_name' },
  {
    title: 'Art',
    field: 'image',
    render: (rowData: any) => (
      <img
        src={rowData?.image?.url}
        style={{ width: 40, borderRadius: '5px' }}
      />
    ),
  },
  { title: 'Price', field: 'listing.reserve' },
  // { title: "Type", field: "sold" },
  { title: 'Listed', field: 'listing.listId' },
  { title: 'Sale Type', field: 'listing.listingType' },
];

export const BasicTable = (props: any) => {
  // Toast
  const toastId = React.useRef(null);
  const notify = (props: any) =>
    (toastId.current = toast(props, {
      autoClose: false,
      position: toast.POSITION.BOTTOM_LEFT,
    }));
  const updateSucc = () =>
    toast.update(toastId.current, {
      render: 'Upload done!',
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });
  // const update = () => toast.update(toastId.current, { render: "Collection created, uploading avatar and image!", position: toast.POSITION.BOTTOM_LEFT });
  const updateFail = err =>
    toast.update(toastId.current, {
      render: err?.response?.message ?? err ?? 'Something went wrong..',
      type: toast.TYPE.ERROR,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

  const [list, setList] = useState<any>({
    reserve: null,
    saleType: 'direct',
    // password: null,
  });

  async function listArtwork() {
    const newArray = props.artworks.filter(function (el: any) {
      return el.listing.listingType != 'NONE';
    });
    if (newArray.length === props.artworks.length) {
      toast.info(`All artworks are listed!`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return false;
    }

    for (let i = 0; i < props.artworks.length; i++) {
      if (props.artworks[i].listing.listingType != 'NONE') {
        toast.info(
          `Artwork ${props.artworks[i].artwork_name} already listed, going to next`,
          {
            position: toast.POSITION.BOTTOM_LEFT,
          },
        );
        continue;
      }
      if (props.artworks[i].owner.username != props?.artist) {
        toast.info(
          `Artwork ${props.artworks[i].artwork_name} is owned by someone else, going to next`,
          {
            position: toast.POSITION.BOTTOM_LEFT,
          },
        );
        continue;
      }
      notify('Starting listing of ' + props?.artworks[i].artwork_name);
      try {
        const resp = await api.post(
          process.env.REACT_APP_BACKEND_URL + '/marketplace',
          {
            reserve: parseInt(list.reserve),
            id: props.artworks[i].listing.id,
          },
          {
            headers: {
              // "X-PASSWORD": list.password,
              'Access-Control-Allow-Origin': '*',
            },
          },
        );
        updateSucc();
      } catch (err: any) {
        console.error(err);
        updateFail(err);
        return false;
      }
    }
  }

  function handleSale() {
    if (
      list.reserve === null
      // || list.password === null
    ) {
      toast.error(`Price missing!`, {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return false;
    }
    listArtwork();
    return false;
  }

  return (
    <>
      <MaterialTable
        actions={[
          {
            icon: () => <LaunchIcon />,
            tooltip: 'See artwork',
            onClick: (event, rowData: any) =>
              window.open(
                '/collections/' + props.collection + '/' + rowData.artwork_name,
              ),
          },
        ]}
        // actions={[
        // {
        //   icon: tableIcons.Delete,
        //   tooltip: "Delete User",
        //   onClick: (event, rowData:any) => alert("You want to delete " + rowData.name),
        // },
        // {
        //   icon: tableIcons.Add,
        //   tooltip: "Add User",
        //   If the action is for the whole table, pass isFreeAAction = true into the table.
        //   isFreeAction: true,
        //   onClick: (event) => alert("You want to add a new row"),
        // },
        // ]}

        title="Artworks"
        icons={tableIcons}
        columns={columns}
        data={props.artworks}
        options={{
          exportButton: true,
        }}
      />
      <Box sx={{ width: '30%' }}>
        <TextField
          fullWidth
          margin="normal"
          InputLabelProps={{ shrink: true }}
          label="Price (€)"
          type="number"
          onChange={e => setList({ ...list, reserve: e.target.value })}
        />
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Type</InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            label="Type"
            value={list.saleType}
            onChange={e => setList({ ...list, saleType: e.target.value })}
          >
            <MenuItem value={'direct'}>Direct</MenuItem>
            <MenuItem value={'auction'}>Auction</MenuItem>
          </Select>
          {/* <TextField
            margin="normal"
            id="outlined-password-input"
            label="Password"
            type="password"
            InputLabelProps={{ shrink: true }}
            autoComplete="current-password"
            onChange={e => setList({ ...list, password: e.target.value })}
          /> */}
          {/* <Alert severity="error">If artist is not owner, he cannot list it</Alert> */}
          <BlackButton content="Put artworks in sale" onClick={handleSale} />
        </FormControl>
      </Box>
    </>
  );
};
