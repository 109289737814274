import axios from 'axios';
import { ArtworkMintStatusEnum } from 'enums/ArtworkMintStatusEnum';
import { GetDonationDto } from 'interfaces/GetDonationDto';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GalleryCard from '../cards/gallery_card/NftCard';
import './nft_page.scss';

interface INftArtwork {
  artwork_name: string;
  created_at: string;
  id: string;
  image: {
    id: string;
    key: string;
    url: string;
  };
}

interface INft {
  artwork: INftArtwork;
  contribution: string;
  giver: {
    id: string;
    username: string;
  };
  id: string;
  ngo: {
    ngo_name: string;
    website_url: string;
  };
  value: string;
  wallet: string;
}

export default function NftPage() {
  /** Tools */
  const { donation_id } = useParams();

  /** Variables */
  const [nft, setNft] = useState<GetDonationDto | null>(null);

  useEffect(() => {
    async function fecthDonation() {
      if (donation_id) {
        try {
          const response = await axios.get(
            process.env.REACT_APP_BACKEND_URL + '/donation/find/' + donation_id,
            {
              params: {
                relations: ['giver', 'ngo', 'artwork'],
              },
            },
          );
          setNft(response.data);
        } catch (error) {
          console.error(error);
        }
      }
    }

    fecthDonation();
  }, []);

  if (nft) {
    return (
      <div id="nft_page">
        <div id="nft_page_artwork">
          <GalleryCard
            card={{
              id: nft.id,
              name: '',
              // name: nft.artwork.artwork_name,
              cause: nft.ngo.cause.cause_name,
              collection: nft.artwork.artwork_name,
              imgUrl: nft.artwork.image.url,
              address: nft.wallet,
              donation: nft.value,
              opensea: nft.artwork?.mint?.opensea,
              polygonScan: nft.artwork?.mint?.polygonscan,
              ngo: nft.ngo.ngo_name,
            }}
          />
        </div>
        <div id="nft_page_infos">
          {/* <h2>NFT {nft.artwork.artwork_name}</h2> */}
          <div id="nft_page_infos_donation">
            <p>
              Value: <strong>{nft.value} €</strong>
            </p>
            <p>
              Contribution : <strong>{nft.contribution} €</strong>
            </p>
            <p>
              Owner : <strong>{nft.giver.username}</strong>
            </p>
            <p>
              Wallet : <strong>{nft.wallet}</strong>
            </p>
            <p>
              NGO : <strong>{nft.ngo.ngo_name}</strong>
            </p>
          </div>
          <div id="ngo_page_infos_links">
            <a
              className="black_link"
              target="_blank"
              rel="noreferrer"
              href={nft.ngo.website_url}
            >
              NGO website
            </a>
            {nft.artwork.minted ===
              (ArtworkMintStatusEnum.MINTED as ArtworkMintStatusEnum) && (
              <>
                <a
                  className="black_link"
                  target="_blank"
                  rel="noreferrer"
                  href={nft?.artwork?.mint?.opensea}
                >
                  OpenSea
                </a>
                <a
                  className="black_link"
                  target="_blank"
                  rel="noreferrer"
                  href={nft?.artwork?.mint?.polygonscan}
                >
                  PolygonScan
                </a>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
  return <></>;
}
