import React, { ChangeEvent, useState } from 'react';
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { Box } from '@mui/system';
import { Store } from 'data/store';
import Session from 'interfaces/session.interface';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import './collectionCreation.scss';
import BlackButton from 'helpers/elements/black_button';
import Typography from '@mui/material/Typography';
import { toast } from 'react-toastify';
import img from 'images/design/background2.svg';
import ArtworkCard from '../cards/artwork_card/artwork_card';
import RevenueSplit from './revenueSplit';
import { api, apiForm } from 'api/reskueApi';
import { useTranslation } from 'react-i18next';

export default function ArtworkCreation(props: any) {
  /** Contexts */
  const session: Session = useSelector((state: Store) => state.session);
  const params = useParams();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  /** Hooks */
  const dispatch = useDispatch();
  const { t } = useTranslation('common');

  // Toast
  const toastId = React.useRef(null);
  const notify = () =>
    (toastId.current = toast(t('artworks.create.infos.upload'), {
      autoClose: false,
      position: toast.POSITION.BOTTOM_LEFT,
    }));
  const updateSucc = () =>
    toast.update(toastId.current, {
      render: t('artworks.create.successList'),
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });
  const update = (props: any) =>
    toast.update(toastId.current, {
      render: props,
      position: toast.POSITION.BOTTOM_LEFT,
    });
  const updateFail = (err: any) =>
    toast.update(toastId.current, {
      render: err,
      type: toast.TYPE.ERROR,
      autoClose: 5000,
      position: toast.POSITION.BOTTOM_LEFT,
    });

  const [log, setLog] = useState('');
  const [split, setSplit] = useState<any>({
    ngo: '50',
    artist: '50',
    reskue: '0',
  });
  const [error, setError] = useState<any>({
    ngo: '',
    artist: '',
    reskue: '',
    global: '',
  });

  // const [password, setPassword] = useState<string>(null)
  const [list, setList] = useState<boolean>(true);
  const [media, setMedia] = useState<any>({
    url: img,
    file: '',
  });

  /** Collection Object */
  const [artworkObject, setArtworkObject] = useState<any>({
    // collection_name: null,
    artwork_name: null,
    description: null,
    reserve: null,
    // TODO: add type
    sellType: 'direct',
    reskueCashback: split.reskue,
    artistCashback: split.artist,
    ngoCashback: split.ngo,
    media: {
      url: media.url,
      file: media.file,
    },
    attributes: {
      // style: "style",
      // background: "bg",
    },
  });

  async function createArtwork() {
    const sum = parseFloat(split.ngo) + parseFloat(split.artist);
    if (sum != 100) {
      setError({ ...error, global: t('artworks.create.errors.badCashbacks') });
      toast.error(t('artworks.create.errors.badCashbacks'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return false;
    } else {
      setError({ ...error, global: '' });
    }
    if (error.ngo != '' || error.artist != '' || error.reskue != '') {
      toast.error(t('artworks.create.errors.default'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return false;
    }
    if (
      artworkObject.artwork_name === null ||
      artworkObject.artwork_name === '' ||
      (artworkObject.reserve === '' && list) ||
      (artworkObject.reserve === null && list) ||
      media.url === img
    ) {
      toast.error(t('artworks.create.errors.missingInfos'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return false;
    }
    setOpen(true);
    notify();
    try {
      const data = new FormData();
      data.append('collection_name', params.collection_name);
      data.append('artwork_name', artworkObject.artwork_name);
      if (artworkObject.description != '' && artworkObject.description != null)
        data.append('description', artworkObject.description);
      data.append('reskueCashback', split.reskue);
      data.append('artistCashback', split.artist);
      data.append('ngoCashback', split.ngo);
      data.append('media', media.file);
      // data.append("attributes", '{"name":"John", "age":30}')
      // TODO: add attributes
      // data.append("attributes", '{}')
      const response = await apiForm.post(
        process.env.REACT_APP_BACKEND_URL + '/gallery/artwork',
        data,
      );

      // !LISTING
      if (list) {
        update(t('artworks.create.infos.selling') + artworkObject.artwork_name);
        const resp = await api.post(
          process.env.REACT_APP_BACKEND_URL + '/marketplace',
          {
            reserve: parseInt(artworkObject.reserve),
            id: response.data.listing.id,
          },
          {
            headers: {
              // "X-PASSWORD": password,
              'Access-Control-Allow-Origin': '*',
            },
          },
        );
      }
      updateSucc();
      setOpen(false);
      navigate('/user/edit/' + params.collection_name);
    } catch (err: any) {
      setOpen(false);
      updateFail('error: ' + err.response?.data?.message);
      return false;
    }
    return false;
  }

  function onFileChange(event: ChangeEvent<HTMLInputElement>, str: string) {
    if (event.target.files === null || event.target.files.length <= 0) return;
    const imageType = /^image\//;

    if (!imageType.test(event.target.files[0].type)) {
      setLog(t('artworks.create.errors.badImage'));
      return;
    }
    setLog('');
    if (str === 'avatar') {
      setMedia({
        url: window.URL.createObjectURL(event.target.files[0]),
        file: event.target.files[0],
      });
    }
  }

  const artist = {
    username: session.username,
    avatar: {
      url: session?.avatar,
    },
  };

  function checkSplit(e: any, type: string) {
    if (type === 'ngo') {
      if (parseFloat(e.target.value) < 50) {
        setError({
          ...error,
          ngo: `${t('artworks.create.errrors.cannotBe')} < 50%`,
        });
        setSplit({ ...split, ngo: '50' });
        return;
      }
      setError({ ...error, ngo: '' });
      setSplit({ ...split, ngo: e.target.value });
    }
    if (type === 'artist') {
      if (parseFloat(e.target.value) > 50) {
        setError({
          ...error,
          artist: `${t('artworks.create.errrors.cannotBe')} > 50%`,
        });
        setSplit({ ...split, artist: '50' });
        return;
      }
      setError({ ...error, artist: '' });
      setSplit({ ...split, artist: e.target.value });
    }
    // if (type === "reskue") {
    //   if (parseFloat(e.target.value) > 5) {
    //     setError({
    //       ...error,
    //       reskue: `${t("artworks.create.errrors.cannotBe")} > 5%`,
    //     });
    //     setSplit({ ...split, reskue: "5" });
    //     return;
    //   }
    //   setError({ ...error, reskue: "" });
    //   setSplit({ ...split, reskue: e.target.value });
    // }
  }

  if (session.roles.includes('artist') === false) navigate('/');
  return (
    <section className="create">
      <Backdrop
        sx={{ color: '#fff', zIndex: theme => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={2} ml={2} mr={2}>
        <Grid xs={12} sm={12} md={6}>
          {/* <input id="input" type="text" value={input} onChange={e => setInput(e.target.value)} /> */}
          <Box>
            <Box pt="1rem" sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography component={'span'}>
                <h1>{t('artworks.create.title')}</h1>
                <TextField
                  fullWidth
                  margin="normal"
                  id="outlined-required"
                  label={t('artworks.create.name')}
                  onChange={e =>
                    setArtworkObject({
                      ...artworkObject,
                      artwork_name: e.target.value,
                    })
                  }
                  InputLabelProps={{ shrink: true }}
                  required
                >
                  {artworkObject.artwork_name ?? 'Artwork name'}
                </TextField>
                <TextField
                  fullWidth
                  id="outlined-multiline-static"
                  label={t('artworks.create.description')}
                  margin="normal"
                  multiline
                  rows={2}
                  InputLabelProps={{ shrink: true }}
                  onChange={e =>
                    setArtworkObject({
                      ...artworkObject,
                      description: e.target.value,
                    })
                  }
                >
                  {artworkObject.description ?? 'Artwork description'}
                </TextField>
                <div className="collection_imgs">
                  <label>
                    <Box mb="1rem">
                      <Paper
                        variant="outlined"
                        sx={{
                          padding: '1rem',
                          display: 'flex',
                          alignBox: 'center',
                          justifyContent: 'space-between',
                          gap: '1rem',
                          color: 'rgba(0, 0, 0, 0.6)',
                        }}
                      >
                        {t('artworks.create.image')}
                        <AddPhotoAlternateIcon
                          color={media.url != img ? 'success' : 'primary'}
                        />
                      </Paper>
                      <input
                        type="file"
                        name="avatar"
                        id="avatar_input"
                        hidden
                        onChange={e => onFileChange(e, 'avatar')}
                      />
                    </Box>
                  </label>
                </div>
                <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                  <div>
                    <TextField
                      label={t('artworks.create.toNonProfit')}
                      id="outlined-start-adornment"
                      sx={{ m: 1, width: '30%' }}
                      defaultValue={split.ngo}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                      onChange={e => checkSplit(e, 'ngo')}
                      error={error.ngo == '' ? false : true}
                      helperText={error.ngo}
                      type="number"
                    />
                    <TextField
                      label={t('artworks.create.toArtist')}
                      id="outlined-start-adornment"
                      sx={{ m: 1, width: '30%' }}
                      defaultValue={split.artist}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                      onChange={e => checkSplit(e, 'artist')}
                      error={error.artist == '' ? false : true}
                      helperText={error.artist}
                      type="number"
                    />
                    {/* <TextField
                      label={t("artworks.create.toReskue")}
                      id="outlined-start-adornment"
                      sx={{ m: 1, width: "30%" }}
                      defaultValue={split.reskue}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">%</InputAdornment>
                        ),
                      }}
                      onChange={(e) => checkSplit(e, "reskue")}
                      error={error.reskue == "" ? false : true}
                      helperText={error.reskue}
                      type="number"
                    /> */}
                  </div>
                  <div className="error">{error?.global}</div>
                </Box>
                <RevenueSplit {...split} />
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  gap: '1rem',
                  flexWrap: 'wrap',
                  paddingBottom: '1rem',
                  justifyContent: 'space-around',
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      value={list}
                      onChange={e => setList(!list)}
                    />
                  }
                  label={t('artworks.create.list')}
                />
                <TextField
                  sx={{ width: '30%' }}
                  InputLabelProps={{ shrink: true }}
                  InputProps={{
                    inputProps: { min: 20 },
                  }}
                  label={t('artworks.create.price')}
                  type="number"
                  disabled={!list}
                  onChange={e =>
                    setArtworkObject({
                      ...artworkObject,
                      reserve: e.target.value,
                    })
                  }
                />

                <Box sx={{ width: '30%' }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      {t('artworks.create.sellType')}
                    </InputLabel>
                    <Select
                      disabled={true}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label={t('artworks.create.sellType')}
                      value={artworkObject.saleType ?? 'direct'}
                      onChange={e =>
                        setArtworkObject({
                          ...artworkObject,
                          saleType: e.target.value,
                        })
                      }
                    >
                      <MenuItem value={'direct'}>
                        {t('artworks.create.direct')}
                      </MenuItem>
                      <MenuItem value={'auction'}>
                        {t('artworks.create.auction')}
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <BlackButton
                content={t('artworks.create.confirm')}
                onClick={createArtwork}
              />
            </Box>
          </Box>
        </Grid>
        <Grid xs={12} sm={12} md={6}>
          <Box>
            <Box p="3rem" sx={{ display: 'flex', flexDirection: 'column' }}>
              <h1>{t('artworks.create.preview')}</h1>
              <div>
                <ArtworkCard
                  rate={props?.rate}
                  noclickable={true}
                  listing={artworkObject}
                  image={media?.url}
                  collection={params?.collection_name}
                  name={artworkObject.artwork_name ?? t('artworks.create.name')}
                  type={''}
                  user={session}
                  username={session?.username}
                  avatar={session?.avatar}
                  artist={artist}
                  description={artworkObject?.description}
                />
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </section>
  );
}
