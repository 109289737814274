import * as React from 'react';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';

import { TwitterShareButton, TwitterIcon } from 'react-share';

const shareButtonTwitter = props => {
  const { t } = useTranslation('common');

  const shareUrl = window.location.href;
  const shareTitle = `${t('shareTitleTweeter.Checkout')} ${
    props.artworkName
  } ${t('shareTitleTweeter.by')} ${props.artisteName} ${t(
    'shareTitleTweeter.onReskueNFT',
  )}${props.percentageToNGO} ${t('shareTitleTweeter.of')} ${props.ngoName} 💙`;
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [open, setOpen] = React.useState(false);

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };
  return (
    <MenuItem onClick={handleClose}>
      <TwitterShareButton
        url={shareUrl}
        style={{ display: 'flex', alignItems: 'center' }}
        title={shareTitle}
      >
        <TwitterIcon
          size={32}
          round
          bgStyle={{ fill: 'transparent' }}
          iconFillColor="#1d9bf0"
        />
        {t('shareButton.shareOnTwitter')}
      </TwitterShareButton>
    </MenuItem>
  );
};

export default shareButtonTwitter;
