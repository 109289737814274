import React, { useEffect, useState } from 'react';
import CreateIcon from '@mui/icons-material/Create';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { Box } from '@mui/system';
import {
  Card,
  CardActionArea,
  CardContent,
  Stack,
  styled,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import ArtworkCreation from '../../../components/collection_creation/artworkCreation';
import ArtworkMultipleCreation from '../../../components/collection_creation/artworkMultipleCreation';
import { CircularProgress } from '@mui/material';
import { api } from 'api/reskueApi';
import { useTranslation } from 'react-i18next';

export default function EditCollec() {
  const params = useParams();
  const navigate = useNavigate();

  /** Variables */
  const [value, setValue] = React.useState(1);
  const { t } = useTranslation('common');

  // const [data, setData] = useState(null);
  // const [artworks, setArtworks] = useState([]);
  const [mode, setMode] = useState('onebyone');
  const [spinner, setSpinner] = useState(false);

  // useEffect(() => {
  //         setSpinner(true);
  //     if (process.env.REACT_APP_BACKEND_URL) {
  //         let abortController: any;
  //         (async () => {
  //             abortController = new AbortController();
  //             const signal = abortController.signal;
  //             await axios
  //                 .get(
  //                     process.env.REACT_APP_BACKEND_URL +
  //                     `/gallery/collection/find/${params.collection_name}`
  //                     ,
  //                     {
  //                         signal: signal,
  //                         params:
  //                         {
  //                             relations: ['ngo', 'artworks'],
  //                         }
  //                     })
  //             .then((res: any) => {
  //                 setData(res.data);
  //                 setArtworks(Array.from(res.data.artworks));
  //                 setSpinner(false);
  //             })
  //             .catch((err) => {
  //                 console.error(err);
  //             });
  //         })();
  //         return () => abortController.abort();
  //     }}, [value]);

  const [rate, setRate] = useState<number>();

  async function getRate() {
    const resp = await api.get('/money/exchangeRate', {
      params: {
        fiat: 'EUR',
      },
    });
    setRate(resp.data);
  }

  useEffect(() => {
    getRate();
  }, []);

  /** MUI */
  const Item = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'column',
    color: theme.palette.text.secondary,
  }));

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {/* <Typography> */}
            {children}
            {/* </Typography> */}
          </Box>
        )}
      </div>
    );
  }

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  function handleNav(e: any) {
    navigate('/user/edit/' + params.collection_name);
  }

  return (
    <>
      {spinner ? (
        <Box sx={{ margin: '1rem' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ bgcolor: 'background.paper', display: 'flex', gap: '3rem' }}>
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{ borderRight: 1, borderColor: 'divider', pt: '2rem' }}
          >
            <Tab
              sx={{
                padding: '2rem',
                textTransform: 'none',
                justifyContent: 'left',
              }}
              label={t('collection.general')}
              {...a11yProps(0)}
              icon={<RemoveRedEyeIcon />}
              iconPosition="start"
              onClick={handleNav}
            />
            <Tab
              sx={{
                padding: '2rem',
                textTransform: 'none',
                justifyContent: 'left',
              }}
              label={t('artworks.create.title')}
              {...a11yProps(2)}
              icon={<CreateIcon />}
              iconPosition="start"
            />
            {/* <Tab sx={{ padding: "2rem", textTransform: "none", justifyContent: "left" }} label="List" {...a11yProps(1)} icon={<FormatListBulletedIcon />} iconPosition="start" /> */}
          </Tabs>
          <TabPanel value={value} index={0}>
            <Box m={3}>
              <Typography component={'h5'} sx={{ marginBottom: '2rem' }}>
                Collection {params.collection_name}{' '}
              </Typography>
            </Box>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <Stack direction="row" spacing={2} sx={{ marginTop: '1rem' }}>
              <Card
                sx={{ width: '30%', height: '30%', textAlign: 'center' }}
                onClick={() => setMode('onebyone')}
                className={mode === 'onebyone' ? 'btn-gradient-border' : null}
              >
                <CardActionArea>
                  <CardContent>
                    <Typography component={'span'} variant="body2">
                      {t('artworks.create.oneByOne')}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>

              <Card
                sx={{ width: '30%', height: '30%', textAlign: 'center' }}
                onClick={() => setMode('multiple')}
                className={mode === 'multiple' ? 'btn-gradient-border' : null}
              >
                <CardActionArea>
                  <CardContent>
                    <Typography
                      component={'span'}
                      variant="body2"
                      onClick={() => setMode('multiple')}
                    >
                      {t('artworks.create.multiple')}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Stack>
            {mode === 'onebyone' ? (
              <ArtworkCreation rate={rate} />
            ) : (
              <ArtworkMultipleCreation rate={rate} />
            )}
          </TabPanel>
        </Box>
      )}
    </>
  );
}
