import React from 'react';
import { ImgLoad } from 'utils/ImgLoader/img_loader';
import default_profile from 'images/ui/default_avatar.png';

export default function EntityButton(props: any) {
  return (
    <div
      className="but_container"
      // onMouseEnter={(e) => (handleClick(e))}
      // onMouseLeave={() => setIsShown(false)}
      // onClick={handleNav}
    >
      <div className="avatar">
        <ImgLoad
          width={50}
          height={50}
          src={props?.url || default_profile}
          circle={true}
          className={'avatar_img'}
        />
      </div>
      <div className="wrapper">
        <div className="title">{props.title}</div>
        <div className="artist_name">{props.username} </div>
      </div>
    </div>
  );
}
