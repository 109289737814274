import React, { useEffect, useState } from 'react';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { useNavigate, useParams } from 'react-router-dom';
import './ngo_page.scss';
import { useDispatch, useSelector } from 'react-redux';
import IDonation from 'interfaces/donation.interface';
import { Store } from 'data/store';
import { GetNgoDto } from 'interfaces/GetNgoDto';
import { Helmet } from 'react-helmet-async';
import { ImgLoad } from 'utils/ImgLoader/img_loader';
import default_profile from 'images/ui/default_avatar.png';
import default_cover from 'images/ui/default_cover.webp';
import { Icollection } from 'interfaces/Collection.interface';
import axios from 'axios';
import CollectionCard from '../collection_page/collection_card';
import LazyLoad from 'react-lazyload';
import CopyToClipboard from 'utils/copy_clipboard';
import { Box, Grid, Tooltip } from '@mui/material';
import { api } from 'api/reskueApi';
import { useTranslation } from 'react-i18next';
import polygon_wallet from 'images/ui/poly_wallet.png';
import { TbWorld } from 'react-icons/tb';

export default function NgoPage() {
  /** Tools */
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation('common');

  /** Variables */
  const ngos = useSelector((state: any) => state.ngoList);
  const donation: IDonation = useSelector((state: Store) => state.donation);
  const ngo: GetNgoDto = ngos.find(
    ({ ngo_name }: any) => ngo_name === params['ngo_name'],
  );
  // KEEP FOR TEST PURPOSE PREVIOUS DONATION PROCESS
  // function redirectToForm() {
  //   dispatch(
  //     updateDonation({
  //       ...donation,
  //       ngo_id: ngo.id,
  //       ngo_name: ngo.ngo_name,
  //       ngo_image: ngo.logo?.url as string,
  //       ngo_mollie_account: ngo.mollie_account_id as string,
  //       cause_name: ngo.cause.cause_name,
  //     })
  //   );
  //   navigate("/donation");
  // }

  const [collections, setCollection] = useState<Icollection[]>([]);
  const [rate, setRate] = useState<number>();

  async function getRate() {
    try {
      const resp = await api.get('/money/exchangeRate', {
        params: {
          fiat: 'EUR',
        },
      });
      setRate(resp.data);
    } catch (err: any) {
      console.error(err.response?.data?.message);
    }
  }

  useEffect(() => {
    if (process.env.REACT_APP_BACKEND_URL) {
      axios
        .get(process.env.REACT_APP_BACKEND_URL + `/gallery/collection/many`, {
          params: { relations: ['ngo', 'artist', 'artworks'] },
        })
        .then((res: any) => {
          const arr: Array<any> = Array.from(res.data.items);
          const filtered_arr: Array<any> = arr.filter(arr => {
            return arr.ngo === null || arr.ngo.ngo_name === params.ngo_name;
          });
          setCollection(filtered_arr);
        })
        .catch(err => {
          console.error(err);
        });
    }
    getRate();
  }, []);

  if (ngo) {
    return (
      <LazyLoad height={200}>
        <section id="ngo_section">
          <Helmet>
            <title>{ngo.ngo_name} | Reskue</title>
            <meta name="name" content={ngo.ngo_name} />
            <meta name="description" content={ngo.mission} />
            <meta name="title" content={`${ngo.ngo_name} | Reskue`} />
            {/* test  */}
            <meta property="og:image:height" content="600" />
            <meta property="og:image:width" content="900" />
            <meta
              property="og:image"
              content="https://kultt.fr/wp-content/uploads/2022/08/fortnite-dbz-goku-uai-900x506.jpeg"
            />
            <meta property="og:title" content={`Hasni Test`} />
            {/* <meta property="og:title" content={`${ngo.ngo_name} | reskue`} /> */}
            {/* <meta property="og:url" content="https://reskue.art/collections" /> */}
            <meta
              property="og:description"
              content={`${ngo.ngo_name} | reskue`}
            />
            {/* <meta
              property="og:image"
              content={ngo.logo ? ngo.logo.url : default_profile}
            /> */}
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content={`${ngo.ngo_name} | reskue`} />
            <meta name="twitter:site" content="@reskueNFT" />
            {/* <meta name="twitter:url" content="https://reskue.art/collections" /> */}
            <meta
              name="twitter:image"
              content={ngo.logo ? ngo.logo.url : default_profile}
            />
            <meta property="og:type" content="website" />
          </Helmet>
          <div className="ngo_header">
            <ImgLoad
              className="ngo_cover"
              src={ngo?.cover?.url ?? default_cover}
              // src={data.cover?.url}
              height={600}
              // alt={data.collection_name}
            />
            <h1>{ngo.ngo_name}</h1>
          </div>
          <Box sx={{ marginTop: '-9rem' }}>
            <Grid
              container
              spacing={2}
              justifyContent="center"
              //    alignItems="center"
            >
              <Grid
                item
                xs={12}
                sm={6}
                md={3}
                sx={{ padding: '1rem', textAlign: 'center', zIndex: '2' }}
                alignItems="center"
                justifyContent="center"
              >
                <ImgLoad
                  src={ngo.logo ? ngo.logo.url : default_profile}
                  width={150}
                  height={150}
                  alt={ngo.ngo_name}
                  className="ngo_img"
                />
                <CopyToClipboard>
                  {({ copy }) => (
                    <div className="wallet_info">
                      <div
                        className="wallet_address"
                        onClick={() => copy(ngo?.wallet.address)}
                      >
                        <img src={polygon_wallet} width="20" height="20" />
                        Wallet :{' '}
                        {ngo?.wallet?.address.substring(0, 6) +
                          '...' +
                          ngo?.wallet?.address?.substring(38)}
                      </div>
                      {ngo.website_url && (
                        <div>
                          <TbWorld className="icon-website" size={20} />
                          <a href={ngo.website_url} target="blank">
                            {ngo.website_url}
                          </a>
                        </div>
                      )}
                    </div>
                  )}
                </CopyToClipboard>

                {/* <div className="social_media_icons">
                                    <a target="blank" href="https://twitter.com/ReskueNFT"><FaTwitter className="primary_icon" /></a>
                                    <a target="blank" href="https://www.instagram.com/reskue.art/"><FaInstagram className="primary_icon" /></a>
                                    <a target="blank" href="https://www.linkedin.com/company/reskue/"><FaLinkedinIn className="primary_icon" /></a>
                                </div> */}
                {ngo.issueTaxReceipts ? (
                  <Box
                    sx={{
                      display: 'flex',
                      gap: '1rem',
                      alignItem: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <ReceiptLongIcon fontSize="small" />{' '}
                    <div>{t('ngo.receiptAvailable')}</div>
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <div className="ngo_container">
                  <div className="row">
                    <div className="column">
                      <div>{t('ngo.mission')}</div>
                      <div className="elem">{ngo.tagline}</div>
                    </div>
                    <div className="column">
                      <div>{t('ngo.description')}</div>
                      <div className="elem">{ngo.mission}</div>
                    </div>
                  </div>
                </div>
              </Grid>
              <Grid item xs={3} sx={{ zIndex: '3' }}>
                <Tooltip title={ngo.cause.description}>
                  <Box
                    sx={{ width: '50px', height: 'auto', cursor: 'pointer' }}
                  >
                    <img src={ngo.cause.image?.url} className="cause_logo" />
                  </Box>
                </Tooltip>
              </Grid>
            </Grid>
          </Box>

          <div className="ngo_info">
            <h1>
              {t('ngo.relatedCollections')} {ngo.ngo_name}
            </h1>
          </div>

          <div className="collections_container">
            {collections
              .filter(collection => collection.artworks.length >= 3)
              .map(collection => (
                <CollectionCard
                  key={collection.id}
                  height={600}
                  image_url={collection.image?.url_medium}
                  collection_name={collection.collection_name}
                  description={collection.description}
                  released={collection.released}
                  size={collection.size}
                  ngo={collection?.ngo}
                  artist={collection.artist}
                  rate={rate}
                />
              ))}
          </div>
        </section>
      </LazyLoad>
    );
  }
  return <></>;
}
