import React from 'react';
import { useNavigate } from 'react-router-dom';
import ArtistButton from '../../buttons/artist_button';
import './artwork_cards.scss';
import { ImgLoad } from 'utils/ImgLoader/img_loader';
import PayOwnerButton from '../../buttons/pay_owner_button';
import { useDispatch } from 'react-redux';
import { modalUnactive } from 'data/modal.slice';
import { useTranslation } from 'react-i18next';

export default function ArtworkCard(props: any) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClick = (event: any) => {
    navigate('../collections/' + props.collection_name + '/' + props.name);
    dispatch(modalUnactive({}));
    event.stopPropagation();
  };
  const { t } = useTranslation('common');

  return (
    <div onClick={props.noclickable ? null : handleClick}>
      <div className="card_container">
        <div className="img_container">
          <ImgLoad
            src={props.image}
            width={320}
            height={300}
            alt={props.name}
            className="theimg"
          />
        </div>
        <div className="Title">
          {props.name}
          <div className="subt">{props.description ?? null}</div>
        </div>
        <div className="bottom_section">
          <div className="row" id="row1">
            <div className="t1">{props.name}</div>
            <div className="t2">{t('artworks.edition')} 1/1</div>
          </div>
          <div className="row">
            <div className="column1">
              <ArtistButton type="card" artist={props.artist} />
            </div>
            <div className="column2">
              <PayOwnerButton
                artist={props.artist}
                noclickable={props?.noclickable}
                image={props?.image}
                collection={props.collection}
                name={props.name}
                type={props.type === 'Sold' ? 'owner' : ''}
                user={props?.user}
                username={props?.username}
                avatar={props?.avatar}
                id={props?.id}
                ngo={props?.ngo}
                cashbacks={props?.cashbacks}
                listing={props?.listing}
                owner={props?.owner}
                ownerAvatar={props?.ownerAvatar}
                rate={props?.rate}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
