import React, { useEffect, useRef, useState } from 'react';
import { Box } from '@mui/system';
import BlackButton from 'helpers/elements/black_button';
import { Alert, CircularProgress, TextField } from '@mui/material';
import CountrySelect from 'helpers/elements/country_select';
import { api } from 'api/reskueApi';
import { toast } from 'react-toastify';
import { ISO31661Alpha2ToCountryName } from 'helpers/elements/ISO3166';
import { useTranslation } from 'react-i18next';

export default function IdentityInfo() {
  const [id, isId] = useState<boolean>(false);
  const [spinner, setSpinner] = useState(false);
  const [info, setInfo] = useState<any>({
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    country: '',
    zipCode: '',
  });
  const { t } = useTranslation('common');

  async function handleDelete() {
    setSpinner(true);
    try {
      await api.delete(process.env.REACT_APP_BACKEND_URL + '/user/identity');
      toast.success('Identity deleted!', {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      window.location.reload();
    } catch (err) {
      console.error(err);
    }
    setSpinner(false);
    return false;
  }

  async function handleUpdate() {
    Object.keys(info).forEach(key => {
      if (info[key] === '') {
        delete info[key];
      }
    });
    if (Object.keys(info).length < 6) {
      toast.warning(t('user.edit.identity.errors.missingInfos'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return false;
    }
    try {
      await api.post(process.env.REACT_APP_BACKEND_URL + '/user/identity', {
        firstName: info.firstName,
        lastName: info.lastName,
        address: info.address,
        city: info.city,
        country: info.country,
        zipCode: info.zipCode,
      });

      toast.success(t('user.edit.identity.success'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      isId(true);
      setSpinner(false);
      return false;
    } catch (err: any) {
      toast.error(t('user.edit.identity.errors.default'), {
        position: toast.POSITION.BOTTOM_LEFT,
      });
      return false;
      setSpinner(false);
    }
  }
  async function getId() {
    try {
      const newId = await api.get('/user/identity');
      const indexOf = Object.values(ISO31661Alpha2ToCountryName).indexOf(
        newId.data.country as unknown as ISO31661Alpha2ToCountryName,
      );
      const key = Object.keys(ISO31661Alpha2ToCountryName)[indexOf];
      newId.data.country = key;
      setInfo({ ...newId.data } as const);
      isId(true);
    } catch (err) {
      console.error(err);
    }
    setSpinner(false);
  }

  useEffect(() => {
    setSpinner(true);
    getId();
  }, []);

  if (spinner) {
    return <CircularProgress />;
  }

  return (
    <>
      <h1>{t('user.edit.identity.title')}</h1>
      {id === false ? (
        <Alert sx={{ marginTop: '1rem', marginBottom: '1rem' }} severity="info">
          {t('user.edit.identity.notSet')}
        </Alert>
      ) : (
        <Alert
          sx={{ marginTop: '1rem', marginBottom: '1rem' }}
          severity="success"
        >
          {t('user.edit.identity.set')}
        </Alert>
      )}

      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <TextField
          margin="normal"
          id="outlined-required"
          label={t('user.edit.identity.firstName')}
          value={info.firstName}
          onChange={e => setInfo({ ...info, firstName: e.target.value })}
        />
        <TextField
          margin="normal"
          id="outlined-required"
          label={t('user.edit.identity.lastName')}
          value={info.lastName}
          onChange={e => setInfo({ ...info, lastName: e.target.value })}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <TextField
          margin="normal"
          id="outlined-required"
          label={t('user.edit.identity.address')}
          value={info.address}
          onChange={e => setInfo({ ...info, address: e.target.value })}
          // defaultValue={session.username}
          // inputRef={usernameRef}
        />

        <TextField
          margin="normal"
          id="outlined-required"
          label="City"
          value={info.city}
          onChange={e => setInfo({ ...info, city: e.target.value })}
          // defaultValue={session.username}
          // inputRef={usernameRef}
        />
        <TextField
          margin="normal"
          id="outlined-required"
          label="Zipcode"
          onChange={e => setInfo({ ...info, zipCode: e.target.value })}
          value={info.zipCode}
          // defaultValue={session.username}
          // inputRef={usernameRef}
        />
      </Box>
      <Box sx={{ display: 'flex', gap: '1rem' }}>
        <CountrySelect
          country={info.country}
          setCountry={e => setInfo({ ...info, country: e })}
        />
      </Box>
      <Box sx={{ width: '50%' }}>
        <BlackButton content="update" onClick={handleUpdate} />
        {id === true && (
          <BlackButton content="delete id" onClick={handleDelete} />
        )}
      </Box>
    </>
  );
}
