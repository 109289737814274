import { api } from '../app/api/reskueApi';

const getPublicUser = async (username: string, relations: Array<string>) => {
  try {
    const ret = await api.get(`/user/find/${username}`, {
      params: {
        relations: relations,
      },
    });
    return ret.data;
  } catch (error: any) {
    console.error(error.response);
  }
};

export default getPublicUser;
