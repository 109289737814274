import React from 'react';
import { createSlice } from '@reduxjs/toolkit';

const menuslice = createSlice({
  name: 'menu',
  initialState: {
    menu: null,
    value: 0,
  },
  reducers: {
    menuActive(state, action: any) {
      state.menu = action.payload;
    },
    menuUnactive(state, action) {
      state.menu = null;
    },
    updatemenu(state, action: any) {
      void state;
      return action.payload;
    },
  },
});
export default menuslice.reducer;

export const { menuActive, menuUnactive, updatemenu } = menuslice.actions;
